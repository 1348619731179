import React, { useContext, useEffect, useRef, useState } from "react";
import NavSub from "../components/NavSub";
import "../Styles/QualityControl.css";
import "../Styles/common.css";
import { AuthContext } from "../context/AuthContext";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Delete, Edit, Update } from "@mui/icons-material";
import Textarea from "@mui/joy/Textarea";
import { XCircle } from "@phosphor-icons/react";
import { date } from "yup";
import { useNavigate } from "react-router-dom";
import { PencilSimple, PencilSimpleLine, Trash } from "phosphor-react";
import { CircularProgress } from "@mui/material";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import ToastComponent from "../components/ToastComponent";
const QualityControl = () => {
  const navigate = useNavigate();
  const [qcPoints, setQcPoints] = useState([]);
  const [qcPoints2, setQcPoints2] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Select Status");
  const [selectedStatus2, setSelectedStatus2] = useState("Select Status");
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  
  const [statuses, setStatuses] = useState({});
  const [statuses2, setStatuses2] = useState({});
  const [layout, setLayout] = useState("view");
  const [qcPointsEmployee, setQcPointsEmployee] = useState([]);
  const [qcPointsEmployee2, setQcPointsEmployee2] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString());


  const { userData, logout } = useContext(AuthContext);
  const user = userData?.[0];

  useEffect(() => {
    if (userData?.[0]?.DR_Name == "Admin") {
      setIsAdmin(true);
    }
  }, [userData]);
  const handleChange = (event) => {
    if (event.target.value == "All") {
      setSelectedStatus("All");
      setQcPoints2(qcPoints);
      
    }else{

      setSelectedStatus(event.target.value);
    }
  };
  const handleChange2 = (event) => {
    console.log(event);
    if (event.target.value == "Select Status") {
      setSelectedStatus2("Select Status");
      setQcPointsEmployee2(qcPointsEmployee);
    } else {
      setSelectedStatus2(event.target.value);
    }
  };

    const handleChange3 = (event) => {
      console.log(event)
      if(event.target.value=="Select Role"){
        setSelectedStatus2("Select Role")
        setQcPointsEmployee2(qcPointsEmployee);
      }else{
  
        setSelectedStatus2(event.target.value);
      }  };
  

  useEffect(()=>{
    //console.log(selectedStatus)
    if (selectedStatus == "All") return;
    setQcPoints2(
      qcPoints?.filter((item) => item.controlResponsible == selectedStatus)
    );
  }, [selectedStatus]);
  useEffect(() => {
    //console.log(selectedStatus)
    if (selectedStatus2 == "Select Status") return;
    console.log(qcPointsEmployee);
    setQcPointsEmployee2(
      qcPointsEmployee?.filter((item) => item.Status == selectedStatus2)
    );
  }, [selectedStatus2]);

  const handleStatusChange = async (item, id, event) => {
    //console.log(item)
    // setQC(item);
    const newStatus = event.target.value;
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));

    // Call the update API here with the item's id and the new status
    await editQcStatus(item, id, newStatus);
  };
  const handleStatusChange2 = async (item, id, event) => {
    console.log(event);
    //console.log(item)
    // setQC(item);
    const newStatus = event.target.value;
    setStatuses2((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));

    // Call the update API here with the item's id and the new status
    await editQcStatus2(item, id, newStatus);
  };



  const { token } = useContext(AuthContext);


  function handleMonthChange(monthString) {
    setSelectedMonth(monthString);
  }

  const fetchQcPoints = async () => {
    if (!token) {
      return;
    }
    const response = await fetch(
      `${taskApiUrl}/GetControlPointByStatus?status=all`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.status === 401) {
      await logout()
      toastRef.current.showToast('Session Timed Out . Please Login ','error')
      navigate("/");
    }
    const data = await response.json();
    //console.log(data);
    if(data){

    
    setQcPoints(data);
    setQcPoints2(
      selectedStatus != "Select Status"
        ? data?.filter((item) => item?.controlResponsible == selectedStatus)
        : data
    );
    const initialStatuses = data.reduce((acc, item) => {
      acc[item.id] = item.status;
      return acc;
    }, {});
    // console.log(initialStatuses)
    setStatuses(initialStatuses);
    // toastRef.current.showToast('Session Timed Out . Please Login ','success')

  }
  else{
    toastRef.current.showToast('Someting went wrong!','error')

  }
  };
  
  const fetchQcPointsEmployee = async (monthString) => {
    if (!token) {
      return;
    }
    const [year, month] = monthString.split("-");
    const lastDay = new Date(year, month, 0).getDate();
    const firstDay = "01";

    const formattedDateRange1 = `${year}-${month}-${firstDay}`;
    const formattedDateRange2 = `${year}-${month}-${lastDay
      .toString()
      .padStart(2, "0")}`;

    const response = await fetch(
      `${taskApiUrl}/GetControlPointMapping?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&Emids='${
        userData?.[0]?.EM_ID
      }'
      `, 
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.status === 401) {
      await logout()
      toastRef.current.showToast('Session Timed Out . Please Login ','error')
      navigate("/");
    }
    const data = await response.json();
    if(data){

    
    setQcPointsEmployee(data);
    setQcPointsEmployee2(data);
    const initialStatuses = data.reduce((acc, item) => {
      acc[item.id] = item.status;
      return acc;
    }, {});
  }else{
    toastRef.current.showToast('Someting went wrong!','error')
  }
    // setStatuses(initialStatuses);
  };



  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // const hours = String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");
    // const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const addQc = async () => {
    // if (selectedStatus === "Select Status") {
    //   return;
    // }
    const response = await fetch(`${taskApiUrl}/CreateControlPoint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...qc,
        // ControlResponsible: userData?.[0]?.DR_Name,
      }),
    });
    if (response?.status === 401) {
      await logout()
      toastRef.current.showToast('Session Timed Out . Please Login ','error')

      navigate("/");
    }
    const data = await response.json();
    //console.log(data);
    if (data) {
      handleClose();
      fetchQcPoints();
      toastRef.current.showToast('Control Point Added Successfully!','success')

    }
  };
  const editQc = async () => {
    // if (selectedStatus === "Select Status") {
    //   return;
    // }

    const response = await fetch(`${taskApiUrl}/UpdatecontrolPoint`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...qc, ID: selectedId }),
    });
    if (response?.status === 401) {
      await logout()
      toastRef.current.showToast('Session Timed Out . Please Login ','error')

      navigate("/");
    }
    const data = await response.json();
    //console.log(data);
    if (data) {
      handleClose();
      setQC({
        controlPoint: "",
        keycontrolsfor: "",
        controlDescription: "",
        controlResponsible: "",
        tat: "",
        accuracy: "",
        frequency: "",
        criticality: "",
      });
      
      fetchQcPoints();
      toastRef.current.showToast('Control Point Saved Successfully!','success')

    }
  };
  const editQcStatus = async (item, id, status) => {
    // if (selectedStatus === "Select Status") {
    //   return;
    // }
    //console.log(item, status);
    const currStatus =
      status === "P"
        ? "P"
        : status === "C"
        ? "C"
        : status === "NA"
        ? "NA"
        : "D";
    const response = await fetch(`${taskApiUrl}/UpdateControlPointMapping`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Id: item?.Id,
        EmpployeeId: item?.EmId,
        ControlPointId: item?.ControlPointId,
        Status: currStatus,
      }),
    });
    if (response?.status === 401) {
      navigate("/");
      toastRef.current.showToast('Session Timed Out . Please Login ','error')
    }
    const data = await response.json();
    //console.log(data);
    if (data) {
      fetchQcPointsEmployee(selectedMonth);
      toastRef.current.showToast('Control Point Saved Successfully!','success')
    }
    else{
      toastRef.current.showToast('Something went wrong! Please try again','error')

    }
  };
  const editQcStatus2 = async (item, id, status) => {
    // if (selectedStatus === "Select Status") {
    //   return;
    // }
    //console.log(item, status);
    const currStatus = status === "P" ? "P" : status === "C" ? "C" : "NA";
    const response = await fetch(`${taskApiUrl}/UpdateControlPoint`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...item,
        status: currStatus,
      }),
    });
    if (response?.status === 401) {
      await logout()
      toastRef.current.showToast('Session Timed Out . Please Login ','error')

      navigate("/");
    }
    const data = await response.json();
    //console.log(data);
    if (data) {
      fetchQcPoints();
      toastRef.current.showToast('Control Point Saved Successfully!','success')
    }
  };

  useEffect(() => {
    fetchQcPointsEmployee(selectedMonth);
  }, [token, selectedMonth]);

  useEffect(() => {
    fetchQcPoints();
  }, [token]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (op) => {
    setOpen(true);
    if(op==="Add"){
      setQC({
        controlPoint: "",
        keycontrolsfor: "",
        controlDescription: "",
        controlResponsible: "",
        tat: "",
        accuracy: "",
        frequency: "",
        criticality: "",
      });
    }
  };
  const handleClose = () => setOpen(false);

  const [operation, setOperation] = useState("Add");
  const [selectedId, setSelectedId] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 1000,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 12,
    p: 4,
  };

  const initialQC = {
    controlPoint: "",
    keycontrolsfor: "",
    controlDescription: "",
    tat: "",
    accuracy: "",
    frequency: "",
    criticality: "",
    controlResponsible: "",
  };
  const [qc, setQC] = useState(initialQC);

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setQC({ ...qc, [name]: value });
  };

  const handleDropdownChange = (e, field) => {
    const { value } = e.target;
    setQC({ ...qc, [field]: value });
  };

  function isAllowed() {
    if (isAdmin) return true;
    return false;
  }

  return (
    <div>
      <NavSub />
      <ToastComponent ref={toastRef} timeout={4000} />

      {layout === "view" && (
        <div>
          <div className="qc-status-bar">
            <Box sx={{ width: 150 }}>
              <FormControl fullWidth>
                <Select value={selectedStatus2} onChange={handleChange2}>
                  <MenuItem value={"Select Status"}>All</MenuItem>
                  <MenuItem value={"P"}>Pending</MenuItem>
                  <MenuItem value={"C"}>Completed</MenuItem>
                  <MenuItem value={"NA"}>Not Applicable</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <input
            type="month"
            value={selectedMonth.slice(0, 7)}
            onChange={(e) => handleMonthChange(e.target.value)}
            // className="calstyle-for-dashboard"
            className="calender_quality"
          />
            {isAllowed() && (
              <button
                className="common_btn"
                onClick={() => {
                  // setOperation("Add");
                  // handleOpen();
                  setLayout("add");
                }}
              >
                Manage Control Points
              </button>
            )}
          </div>

          {qcPointsEmployee2?.map((item) => {
            // //console.log(userData?.[0])
            //console.log(item)
            return (
              <div
                className="qc-status-card"
                // key={item?.Id}
                style={{
                  borderLeft: `5px solid ${
                    item?.Status === "P"
                      ? "rgba(239, 160, 41, 1)"
                      : item?.Status === "C"
                      ? "green"
                      : item?.Status === "NA"
                      ? "red"
                      : item?.Status === "D"
                      ? "gray"
                      : "black"
                  }`,
                }}
              >
                <div className="qc-card-desc">
                  <div className="qc-card-control-points">
                    <div className="qc-card-control-point">
                      <div className="qc-card-bold-head">Control Points</div>
                      <div>{item?.ControlPoint}</div>
                    </div>
                    <div>
                      <div className="qc-card-bold-head">Key Control</div>
                      <div>{item?.Keycontrolsfor}</div>
                    </div>
                  </div>
                  <div className="qc-card-control-description">
                    <div className="qc-card-bold-head">Control Description</div>
                    <div>{item?.ControlDescription}</div>
                  </div>
                  <div className="qc-card-control-stats">
                    <div className="qc-card-control-stat">
                      <div className="qc-card-bold-head">TAT :</div>
                      <div>{item?.Tat}</div>
                    </div>
                    <div className="qc-card-control-stat">
                      <div className="qc-card-bold-head">Accuracy :</div>
                      <div>{item?.Accuracy}</div>
                    </div>
                    <div className="qc-card-control-stat">
                      <div className="qc-card-bold-head">Frequency :</div>
                      <div>{item?.Frequency}</div>
                    </div>
                    <div className="qc-card-control-stat">
                      <div className="qc-card-bold-head">Criticality :</div>
                      <div>{item?.Criticality}</div>
                    </div>
                  </div>
                </div>
                {!(item?.Status === "deleted") && (
                  <div className="qc-card-action">
                    <div className="qc-card-update-delete"></div>
                    <div className="qc-card-update-status">
                      <div className="qc-card-update-status-text">Status</div>
                      <div className="qc-card-update-status-text">
                        <Box sx={{ width: 150 }}>
                          <FormControl fullWidth>
                            <Select
                              value={item?.Status || "Select Status"}
                              onChange={(event) =>
                                handleStatusChange(item, item.Id, event)
                              }
                              sx={{
                                color:
                                  item?.Status === "P"
                                    ? "rgba(239, 160, 41, 1)"
                                    : item?.Status === "C"
                                    ? "green"
                                    : item?.Status === "NA"
                                    ? "black"
                                    : item?.Status === "Select Status"
                                    ? "gray"
                                    : "red",
                              }}
                            >
                              <MenuItem value={"Select Status"}>
                                Select Status
                              </MenuItem>
                              <MenuItem value={"P"}>Pending</MenuItem>
                              <MenuItem value={"C"}>Completed</MenuItem>
                              <MenuItem value={"NA"}>Not Applicable</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {layout === 'add' && <div>
        <div className="qc-status-bar">
          <div className='' onClick={()=>{setLayout('view')}}>Back to View</div>
        <Box sx={{ width: 150 }}>
          <FormControl fullWidth>
            <Select value={selectedStatus} onChange={handleChange}>
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
              <MenuItem value={"Consultant"}>Consultant</MenuItem>
              <MenuItem value={"Quality Consultant"}>Quality Consultant</MenuItem>
              <MenuItem value={"Managing Director"}>Managing Director</MenuItem>
              <MenuItem value={"Process Lead"}>Process Lead</MenuItem>
              <MenuItem value={"Department Lead"}>Department Lead</MenuItem>
            </Select>
          </FormControl>  
        </Box>
        <button
          className="common_btn"
          onClick={() => {
            setOperation("Add");
            handleOpen("Add");
          }}
        >
          + Add New
        </button>
      </div>

      {qcPoints2?.map((item) => {
        console.log(qcPoints2)
        return (
          <div
            className="qc-status-card"
            key={item?.id}
            style={{
              borderLeft: `5px solid ${
                item?.status === "P"
                  ? "rgba(239, 160, 41, 1)"
                  : item?.status === "C"
                  ? "green": item?.status === "Not Applicable" ? "red" :item?.status === "deleted" ? "gray" :  "black"
              }`,
            }}
          >
            <div className="qc-card-desc">
              <div className="qc-card-control-points">
                <div className="qc-card-control-point">
                  <div className="qc-card-bold-head">Control Points</div>
                  <div>{item?.controlPoint}</div>
                </div>
                <div>
                  <div className="qc-card-bold-head">Key Control</div>
                  <div>{item?.keycontrolsfor}</div>
                </div>
              </div>
              <div className="qc-card-control-description">
                <div className="qc-card-bold-head">Control Description</div>
                <div>{item?.controlDescription}</div>
              </div>
              <div className="qc-card-control-stats">
                <div className="qc-card-control-stat">
                  <div className="qc-card-bold-head">TAT :</div>
                  <div>{item?.tat}</div>
                </div>
                <div className="qc-card-control-stat">
                  <div className="qc-card-bold-head">Accuracy :</div>
                  <div>{item?.accuracy}</div>
                </div>
                <div className="qc-card-control-stat">
                  <div className="qc-card-bold-head">Frequency :</div>
                  <div>{item?.frequency}</div>
                </div>
                <div className="qc-card-control-stat">
                  <div className="qc-card-bold-head">Criticality :</div>
                  <div>{item?.criticality}</div>
                </div>
              </div>
            </div>
            {!(item?.status==="deleted")&&<div className="qc-card-action">
              <div className="qc-card-update-delete">
                <Delete style={{cursor:'pointer'}}
                  onClick={() => {
                    setSelectedId(item?.id);
                    setOperation("Delete");
                    setQC(item);
                    handleOpen("Delete");
                  }}
                />
                <Edit style={{cursor:'pointer'}}
                  onClick={() => {
                    setSelectedId(item?.id);
                    setOperation("Edit");
                    setQC(item);
                    handleOpen("Edit");
                  }}
                />
              </div>
              <div className="qc-card-update-status">
                <div className="qc-card-update-status-text">Status- </div>
                <div className="qc-card-update-status-text">
                  <Box sx={{ width: 150 }}>
                    <FormControl fullWidth>
                      <Select
                        value={statuses[item.id] || "Select Status"}
                        onChange={(event) =>
                          handleStatusChange2(item, item.id, event)
                        }
                        sx={{
                          color:
                            item?.status === "P" 
                              ? "rgba(239, 160, 41, 1)"
                              : item?.status === "C"
                              ? "green"
                              : item?.status === "Select Status"
                              ? "black":"black"
                        }}
                      >
                        <MenuItem value={"Select Status"}>
                          Select Status
                        </MenuItem>
                        <MenuItem value={"C"}>Required</MenuItem>
                        <MenuItem value={"P"}>Not Required</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>}
          </div>
        );
      })}

        </div>}
    

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <XCircle
            onClick={handleClose}
            size={30}
            weight="fill"
            className="absolute cursor-pointer text-rgba-243-237-255 top-4 right-4"
          />
          {operation === "Add" && (
            <div>
              <div className="qc-card-add-title">ADD CONTROL POINTS</div>
              <div className="qc-add-fields">
                <div className="qc-field">
                  <div className="qc-field-title">Control Point</div>
                  <TextField
                    name="controlPoint"
                    value={qc.controlPoint}
                    onChange={handleValueChange}
                    fullWidth
                    style={{
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
                <div className="qc-field">
                  <div className="qc-field-title">Key Control</div>
                  <TextField
                    name="keycontrolsfor"
                    value={qc.keycontrolsfor}
                    onChange={handleValueChange}
                    fullWidth
                    style={{
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              </div>
              <div className="qc-add-desc">
                <div className="qc-add-textarea">
                  <div className="qc-textarea">
                    <div className="qc-field-title">
                      Control Point Description
                    </div>
                    <TextField
                      name="controlDescription"
                      value={qc.controlDescription}
                      onChange={handleValueChange}
                      multiline
                      rows={4}
                      maxRows={8}
                      fullWidth
                      style={{
                        background: "rgba(241, 241, 241, 1)",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="qc-add-dropdowns">
                <div className="qc-add-dropdown">
                  <div className="qc-field-title">TAT</div>
                  {/* <Box sx={{ width: 400, background: "rgba(241, 241, 241, 1)", padding: '8px', borderRadius: '8px' }}>
      <FormControl fullWidth>
        <Select value={qc.tat||tat} onChange={(e) => handleDropdownChange(e, 'tat')}>
          <MenuItem value={"Select Status"}>Select Status</MenuItem>
          <MenuItem value={"Option1"}>Option 1</MenuItem>
          <MenuItem value={"Option2"}>Option 2</MenuItem>
          <MenuItem value={"Option3"}>Option 3</MenuItem>
        </Select>
      </FormControl>
    </Box> */}
                  <TextField
                    name="tat"
                    value={qc.tat}
                    onChange={handleValueChange}
                    fullWidth
                    style={{
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
                <div className="qc-add-dropdown">
                  <div className="qc-field-title">Accuracy</div>
                  {/* <Box
                    sx={{
                      width: 400,
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px", 
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        value={qc.accuracy}
                        onChange={(e) => handleDropdownChange(e, "accuracy")}
                      >
                        <MenuItem value={"0%"}>0%</MenuItem>
                        <MenuItem value={"10%"}>10%</MenuItem>
                        <MenuItem value={"20%"}>20%</MenuItem>
                        <MenuItem value={"30%"}>30%</MenuItem>
                        <MenuItem value={"40%"}>40%</MenuItem>
                        <MenuItem value={"50%"}>50%</MenuItem>
                        <MenuItem value={"60%"}>60%</MenuItem>
                        <MenuItem value={"70%"}>70%</MenuItem>
                        <MenuItem value={"80%"}>80%</MenuItem>
                        <MenuItem value={"90%"}>90%</MenuItem>
                        <MenuItem value={"100%"}>100%</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                  <Box
  sx={{
    width: 400,
    background: "rgba(241, 241, 241, 1)",
    padding: "8px",
    borderRadius: "8px",
  }}
>
  <FormControl fullWidth>
    <Select
      value={qc.accuracy}
      onChange={(e) => handleDropdownChange(e, "accuracy")}
      displayEmpty
    >
      <MenuItem value="" disabled>
        Enter Accuracy Value
      </MenuItem>
      <MenuItem value={"0%"}>0%</MenuItem>
      <MenuItem value={"10%"}>10%</MenuItem>
      <MenuItem value={"20%"}>20%</MenuItem>
      <MenuItem value={"30%"}>30%</MenuItem>
      <MenuItem value={"40%"}>40%</MenuItem>
      <MenuItem value={"50%"}>50%</MenuItem>
      <MenuItem value={"60%"}>60%</MenuItem>
      <MenuItem value={"70%"}>70%</MenuItem>
      <MenuItem value={"80%"}>80%</MenuItem>
      <MenuItem value={"90%"}>90%</MenuItem>
      <MenuItem value={"100%"}>100%</MenuItem>
    </Select>
  </FormControl>
</Box>

                </div>
                <div className="qc-add-dropdown">
                  <div className="qc-field-title">Frequency</div>
                  <Box sx={{ width: 400, background: "rgba(241, 241, 241, 1)", padding: '8px', borderRadius: '8px' }}>
      <FormControl fullWidth>
        <Select value={qc.frequency} onChange={(e) => handleDropdownChange(e, 'frequency')}
            displayEmpty>
            <MenuItem value="" disabled>
        Enter Frequency
      </MenuItem>
          <MenuItem value={"Daily"}>Daily</MenuItem>
          <MenuItem value={"Weekly"}>Weekly</MenuItem>
          <MenuItem value={"Monthly"}>Monthly</MenuItem>
          <MenuItem value={"Bi-Monthly"}>Bi-Monthly</MenuItem>
          <MenuItem value={"Quarterly"}>Quarterly</MenuItem>
        </Select>
      </FormControl>
    </Box>
                  {/* <TextField
                    name="frequency"
                    value={qc.frequency}
                    onChange={handleValueChange}
                    fullWidth
                    style={{
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  /> */}
                </div>
                <div className="qc-add-dropdown">
                  <div className="qc-field-title">Criticality</div>
                  <Box
                    sx={{
                      width: 400,
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        value={qc.criticality}
                        onChange={(e) => handleDropdownChange(e, "criticality")}
                        displayEmpty>
                        <MenuItem value="" disabled>
                    Select Frequency Type
                  </MenuItem>
                        <MenuItem value={"High"}>High</MenuItem>
                        <MenuItem value={"Medium"}>Medium</MenuItem>
                        <MenuItem value={"Low"}>Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="qc-add-dropdown">
                  <div className="qc-field-title">Control Responsible</div>
                  <Box
                    sx={{
                      width: 400,
                      background: "rgba(241, 241, 241, 1)",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        value={qc.controlResponsible}
                        onChange={(e) => handleDropdownChange(e, "controlResponsible")}
                        displayEmpty>
                        <MenuItem value="" disabled>
                    Select Control Responsible
                  </MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                        <MenuItem value={"Quality consultant"}>
                          Quality Consultant
                        </MenuItem>
                        <MenuItem value={"Consultant"}> Consultant</MenuItem>
                        <MenuItem value={"Process Lead"}>Process Lead</MenuItem>
                        <MenuItem value={"Department Lead"}>
                          Department Lead
                        </MenuItem>
                        <MenuItem value={"Managing  Director"}>
                          Managing Director
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div className="qc-add-buttons">
                <button onClick={handleClose}>Cancel</button>
                <button
                  disabled={
                    !qc.accuracy ||
                    !qc.tat ||
                    !qc.controlDescription ||
                    !qc.controlPoint ||
                    !qc.keycontrolsfor ||
                    !qc.frequency ||
                    !qc.criticality ||
                    !qc.controlResponsible
                  }
                  // !qc.tat =="Select Status" ||!qc.frequency =="Select Status" ||!qc.accuracy =="Select Status" ||!qc.criticality =="Select Status"

                  onClick={() => {
                    addQc();
                  }}
                  className="common_btn"
                >
                  Add
                </button>
              </div>
            </div>
          )}
          {operation === "Edit" && (
            <div>
              <div>
                <div className="qc-card-add-title">EDIT CONTROL POINTS</div>
                <div className="qc-add-fields">
                  <div className="qc-field">
                    <div className="qc-field-title">Control Point</div>
                    <TextField
                      name="controlPoint"
                      value={qc.controlPoint}
                      onChange={handleValueChange}
                      fullWidth
                      style={{
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  <div className="qc-field">
                    <div className="qc-field-title">Key Control</div>
                    <TextField
                      name="keycontrolsfor"
                      value={qc.keycontrolsfor}
                      onChange={handleValueChange}
                      fullWidth
                      style={{
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                </div>
                <div className="qc-add-desc">
                  <div className="qc-add-textarea">
                    <div className="qc-textarea">
                      <div className="qc-field-title">
                        Control Point Description
                      </div>
                      <TextField
                        name="controlDescription"
                        value={qc.controlDescription}
                        onChange={handleValueChange}
                        multiline
                        rows={4}
                        maxRows={8}
                        fullWidth
                        style={{
                          background: "rgba(241, 241, 241, 1)",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="qc-add-dropdowns">
                  <div className="qc-add-dropdown">
                    <div className="qc-field-title">TAT</div>
                    {/* <Box sx={{ width: 400, background: "rgba(241, 241, 241, 1)", padding: '8px', borderRadius: '8px' }}>
      <FormControl fullWidth>
        <Select value={qc.tat} onChange={(e) => handleDropdownChange(e, 'tat')}>
          <MenuItem value={"Select Status"}>Select Status</MenuItem>
          <MenuItem value={"Option1"}>Option 1</MenuItem>
          <MenuItem value={"Option2"}>Option 2</MenuItem>
          <MenuItem value={"Option3"}>Option 3</MenuItem>
        </Select>
      </FormControl>
    </Box> */}
                    <TextField
                      name="tat"
                      value={qc.tat}
                      onChange={handleValueChange}
                      fullWidth
                      style={{
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  <div className="qc-add-dropdown">
                    <div className="qc-field-title">Accuracy</div>
                    <Box
                      sx={{
                        width: 400,
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          value={qc.accuracy}
                          onChange={(e) => handleDropdownChange(e, "accuracy")}
                        >
                          <MenuItem value={"0%"}>0%</MenuItem>
                          <MenuItem value={"10%"}>10%</MenuItem>
                          <MenuItem value={"20%"}>20%</MenuItem>
                          <MenuItem value={"30%"}>30%</MenuItem>
                          <MenuItem value={"40%"}>40%</MenuItem>
                          <MenuItem value={"50%"}>50%</MenuItem>
                          <MenuItem value={"60%"}>60%</MenuItem>
                          <MenuItem value={"70%"}>70%</MenuItem>
                          <MenuItem value={"80%"}>80%</MenuItem>
                          <MenuItem value={"90%"}>90%</MenuItem>
                          <MenuItem value={"100%"}>100%</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <TextField
                  name="accuracy"
                  value={qc.accuracy}
                  onChange={handleValueChange}
                  fullWidth
                  style={{ background: "rgba(241, 241, 241, 1)", padding: '8px', borderRadius: '8px' }}
                /> */}
                  </div>
                  <div className="qc-add-dropdown">
                    <div className="qc-field-title">Frequency</div>
                    <Box
                      sx={{
                        width: 400,
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          value={qc.frequency}
                          onChange={(e) => handleDropdownChange(e, "frequency")}
                        >
                          {/* <MenuItem value={"Select Status"}>Select Status</MenuItem> */}
                          <MenuItem value={"Daily"}>Daily</MenuItem>
                          <MenuItem value={"Weekly"}>Weekly</MenuItem>
                          <MenuItem value={"Monthly"}>Monthly</MenuItem>
                          <MenuItem value={"Bi-Monthly"}>Bi-Monthly</MenuItem>
                          <MenuItem value={"Quarterly"}>Quarterly</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <TextField
                      name="frequency"
                      value={qc.frequency}
                      onChange={handleValueChange}
                      fullWidth
                      style={{
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    /> */}
                  </div>
                  <div className="qc-add-dropdown">
                    <div className="qc-field-title">Criticality</div>
                    <Box
                      sx={{
                        width: 400,
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          value={qc.criticality}
                          onChange={(e) =>
                            handleDropdownChange(e, "criticality")
                          }
                        >
                          {/* <MenuItem value={"Select Status"}>Select Status</MenuItem> */}
                          <MenuItem value={"High"}>High</MenuItem>
                          <MenuItem value={"Medium"}>Medium</MenuItem>
                          <MenuItem value={"Low"}>Low</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="qc-add-dropdown">
                    <div className="qc-field-title">Control Responsible</div>
                    <Box
                      sx={{
                        width: 400,
                        background: "rgba(241, 241, 241, 1)",
                        padding: "8px",
                        borderRadius: "8px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          value={qc.controlResponsible}
                          onChange={(e) =>
                            handleDropdownChange(e, "controlResponsible")
                          }
                        >
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                          <MenuItem value={"Quality Consultant"}>
                            Quality Consultant
                          </MenuItem>
                          <MenuItem value={"Consultant"}> Consultant</MenuItem>
                          <MenuItem value={"Process Lead"}>
                            Process Lead
                          </MenuItem>
                          <MenuItem value={"Department Lead"}>
                            Department Lead
                          </MenuItem>
                          <MenuItem value={"Managing Director"}>
                            Managing Director
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
                <div className="qc-add-buttons">
                  <button onClick={handleClose}>Cancel</button>
                  <button
                    disabled={
                      !qc.accuracy ||
                      !qc.tat ||
                      !qc.controlDescription ||
                      !qc.controlPoint ||
                      !qc.keycontrolsfor ||
                      !qc.frequency ||
                      !qc.criticality ||
                      !qc.controlResponsible
                    }
                    // !qc.tat =="Select Status" ||!qc.frequency =="Select Status" ||!qc.accuracy =="Select Status" ||!qc.criticality =="Select Status"

                    onClick={() => {
                      editQc();
                    }}
                    className="common_btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {operation === "Delete" && (
            <div>
              <div className="qc-card-add-title">
                Delete this Control Point?
              </div>
              <div
                className="qc-add-buttons"
                style={{ justifyContent: "center", gap: "50px" }}
              >
                <button onClick={handleClose}>Cancel</button>
                <button
                  onClick={async () => {
                    await editQcStatus(qc, qc.id, "deleted");
                  }}
                  className="common_btn"
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default QualityControl;


