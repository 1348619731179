import React, { useState, useContext, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { AuthContext } from "../context/AuthContext";
import "../Styles/graph.css";
import { Select, MenuItem } from "@mui/material";
import { UserContext } from "../context/UserContext";
import ToastComponent from "./ToastComponent";
import { useNavigate } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function GraphPage() {
  const navigate = useNavigate();
  const { token, logout, userData } = useContext(AuthContext);
  const { teamsData } = useContext(UserContext);

  const [selectedTeam, setSelectedTeam] = useState(userData?.[0]?.DR_Name === "Admin" ? '' : teamsData[0]?.filter(t=>t.id==userData?.[0]?.STM_Team)?.[0]?.name);  
  const [selectedYear, setSelectedYear] = useState("");
  const years = Array.from({ length: 10 }, (_, index) => 2020 + index);
  const currentYear = new Date().getFullYear();
  if (!years.includes(currentYear)) {
    years.push(currentYear);
    years.sort((a, b) => a - b);
  }
  const [forecast, setForecast] = useState([]);
  const [averageMonthlyValues, setAverageMonthlyValues] = useState([]);
  const toastRef = useRef();
  const handleTeamChange = (e) => {
    const value = e.target.value;
    setSelectedTeam(value);
  };

  useEffect(() => {
    setSelectedYear(currentYear);
  }, [currentYear]);
  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const fetchForecast = async () => {
    if (!selectedYear) return;
    try {
      const response = await fetch(
        `${userApiUrl}/GetLatestBudget?Year=${selectedYear}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch budget history data");
      }
      const data = await response.json();
      setForecast(data);
    } catch (error) {
      console.error("Error fetching budget history data:", error);
    }
  };
  useEffect(() => {
    fetchForecast();
  }, [selectedYear, token]);
  useEffect(() => {
    //console.log(forecast);
  }, [forecast]);

  useEffect(() => {
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "decs",
    ];

    const calculateAverages = (data) => {
      return months.map((month) => {
        const total = data.reduce((sum, item) => sum + item[month], 0);
        return Math.round(total / data.length);
      });
    };

    const filteredDataWithoutTeam = forecast.filter((item) => item.team);
    

    if (selectedTeam) {

      const filteredData = filteredDataWithoutTeam.filter((item)=>{
        return item.team === selectedTeam
        
      }
      );
      if (filteredData.length > 0) {
        setAverageMonthlyValues(calculateAverages(filteredData));
      } else {
        setAverageMonthlyValues([]);
      }
    } else {
      setAverageMonthlyValues(calculateAverages(filteredDataWithoutTeam));
    }
  }, [selectedTeam, forecast]);

  const seriesData = [
    {
      name: "series1",
      data: averageMonthlyValues,
    },
  ];

  const optionsData = {
    chart: {
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["none"],
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: " #50145A",
            opacity: 1,
          },
          {
            offset: 100,
            color: " white",
            opacity: 1,
          },
        ],
      },
    },
    xaxis: {
      type: "category",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      min: 20,
      max: 300,
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          return Math.floor(value);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const handleClick = () => {
    navigate("/BudgetForeCast"); 
  };
  return (
    <div className="App-graph-1-dash">
      <div className="graph1-container">
        <div className="graph-1-heading" onClick={handleClick} style={{ cursor: "pointer" }}>MONTHLY FORECAST</div>
        <div className="graph1-drop">
          <div className="graph1-drop2">
            <div className="dropdown-container-g1">
              <Select
                labelId="year-select-label"
                value={selectedYear}
                onChange={handleChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Year
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {console.log(selectedTeam)}
            {userData?.[0]?.DR_Name!="Consultant"&& <Select
              fullWidth
              value={selectedTeam}
              onChange={handleTeamChange}
              displayEmpty
            >
              <MenuItem value="" >
               All Teams
              </MenuItem>
              {teamsData[0]
                ?.filter((team) => team?.status === true && team?.type === "T")
                ?.map((data) => (
                  <MenuItem key={data.id} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
            </Select>}
          </div>
        </div>
      </div>
      <div className="graph-2-subhead">12 months forecast</div>
      <ApexChart series={seriesData} options={optionsData} />
    </div>
  );
}
function ApexChart({ series, options }) {
  document.addEventListener("DOMContentLoaded", function () {
    var element = document.getElementById("SvgjsLine1839");
    if (element) {
      element.parentNode.removeChild(element);
    }
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={405}
      />
    </div>
  );
}

export default GraphPage;
