import React, { useState, useContext, useEffect } from "react";
import "../Styles/Dashboard_graph5.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-circular-progressbar/dist/styles.css";
import i1 from "../Images/Rectangle 104 (1).png";
import i2 from "../Images/Rectangle 105.png";
import "../Styles/Dashboard.css";
import { MenuItem } from "@mui/material";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { Select } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

function Dashboard_graph_5() {
  const { token, userData } = useContext(AuthContext);
  const { teamsData, usersList } = useContext(UserContext);
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(userData?.[0]?.DR_Name==="Admin"?'All Teams':userData?.[0]?.STM_Team);
  const navigate = useNavigate();
  const [statusData, setStatusData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString());
  const getAllActivityTaskStatus = async (monthString) => {
    const [year, month] = monthString.split("-");

    const lastDay = new Date(year, month, 0).getDate();
    const firstDay = "01";

    const formattedDateRange1 = `${year}-${month}-${firstDay}`;
    const formattedDateRange2 = `${year}-${month}-${lastDay.toString().padStart(2, "0")}`;

    if (!filteredEmployeeList?.length > 0) {
        setStatusData([]);
        return;
    }

    // Split the string by commas into an array
    const employeeArray = filteredEmployeeList.split(',');

    // Helper function to split the array into batches
    const chunkArray = (arr, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const batchSize = 20;  // Set your preferred batch size here
    const batches = chunkArray(employeeArray, batchSize);

    let allData = [];  // Collect all responses here

    try {
        for (let batch of batches) {
            const batchString = batch.join(',');  // Convert the batch array back to a comma-separated string

            const response = await fetch(
                `${taskApiUrl}/GetAllActivityTaskStatus?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&AssginedToEmids=${batchString}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response?.status === 401) {
                navigate("/");
                return;
            }
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            allData = [...allData, ...data];  // Combine batch results
        }

        setStatusData(allData);  // Set the combined result
    } catch (error) {
        console.error("Error fetching status data:", error);
    }
};


  useEffect(() => {
    getAllActivityTaskStatus(selectedMonth);
  }, [token, filteredEmployeeList, selectedMonth]);



  const { pendingActivities, completedActivities } = statusData
  .filter((item) => item.ActivityType === "Reporting Activities")
  .reduce(
    (acc, item) => {
      if (item.Status === "A") {
        acc.pendingActivities += parseInt(item.StatusCount, 10);
      } else if (item.Status === "C") {
        acc.completedActivities += parseInt(item.StatusCount, 10);
      }
      return acc;
    },
    { pendingActivities: 0, completedActivities: 0 }
  );

const totalActivities = pendingActivities + completedActivities;
const percentage =
  totalActivities > 0
    ? Math.round((completedActivities / totalActivities) * 100)
    : 0;
const rem = 100 - percentage;  // No need to use Math.round as percentage is already an integer

console.log({
  totalActivities,
  pendingActivities,
  completedActivities,
  percentage,
  rem
});

  function handleMonthChange(monthString) {
    //console.log(monthString);
    setSelectedMonth(monthString);
  }

  const handleTeamChange = (e) => {
    const value = e.target.value;
    setSelectedTeam(value);
  };

  useEffect(() => {
    console.log(selectedTeam)
    if(selectedTeam==="All Teams"){
      setFilteredEmployeeList(
        usersList?.map((item) => `'${item["EM_ID"]}'`).join(",")
      );;
    }
    else{
      const filteredEmployees = usersList?.filter(
        (user) => user?.STM_Team == selectedTeam
      );
  
      setFilteredEmployeeList(
        filteredEmployees?.map((item) => `'${item["EM_ID"]}'`).join(",")
      );

    }
    console.log(filteredEmployeeList)

    
  }, [selectedTeam]);

  const customStyles = {
    text: {
      fill: "orange",
      fontSize: "9px",
      dominantBaseline: "middle",
      textAnchor: "middle",
    },
    path: {
      strokeWidth: "8px",
      stroke: "url(#gradient)",
    },
  };

  const handleClick = () => {
    navigate("/ReportingActivities"); 
  };


  return (
    <div className="graph5-main">
      <div className="graph5-card-heading">
        <div className="g5-heading" onClick={handleClick} style={{ cursor: "pointer" }}>REPORTING ACTIVITIES</div>
        <div className="g5-dropdowns">
          {/* <input
            type="month"
            value={selectedMonth}
            onChange={(e) => handleMonthChange(e.target.value)}
            className="calstyle-for-dashboard"
          /> */}

          <input
            type="month"
            value={selectedMonth.slice(0, 7)}
            onChange={(e) => handleMonthChange(e.target.value)}
            className="calstyle-for-dashboard"
          />
          <div>
          {userData?.[0]?.DR_Name!="Consultant"&& <Select
              fullWidth
              value={selectedTeam}
              onChange={handleTeamChange}
              displayEmpty
            >
<MenuItem value="All Teams" onClick={(e)=>{
                e.preventDefault();
                setSelectedTeam('All Teams')
              }}>
                All Teams
              </MenuItem>              {teamsData[0]
                ?.filter((team) => team?.status === true && team?.type === "T")
                ?.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
            </Select>}
          </div>
        </div>
      </div>
      <div className="g5-subhead">
        Live update of pending and completed activity
      </div>
      <div className="g5-graph">
        <div className="c-p-g">
          <svg width="0" height="0">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "#50195A", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#FFFFFF", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgressbar
            value={percentage}
            text={`${totalActivities} Total Activities`}
            styles={customStyles}
          />
        </div>
        <div className="g5-graph-sidetexts">
          <div className="g5-side-1">
            <img src={i2} alt="Completed Activities" className="g5-i1" />
            Completed Activities {percentage}%
          </div>
          <div className="g5-side-2">
            <img src={i1} alt="Pending Activities" className="g5-i1" />
            Pending Activities {rem}%
          </div>
        </div>
      </div>

      <div className="g5-dash-footer">
        <div className="g5-dash-footer-data">
          Out of {totalActivities} activities{" "}
          <span className="g5-dash-footer-spam">
            {completedActivities} activities are completed
          </span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard_graph_5;
