import React, { useContext, useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import "../Styles/VoucherEntry.css";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "../components/ToastComponent";
export default function VoucherEntryMUI({
  data,
  activityList,
  selectedOption,
  selectedMasterData,
  fetchRelatedVouchersOnDate,
  ...props
}) {
  //console.log(selectedOption);

  const toastRef = useRef();
  const [rows, setRows] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const { userData } = useContext(AuthContext);
  const { token, logout } = useContext(AuthContext);

  const [newRow, setNewRow] = useState({
    Code: "",
    Type: "Vouchers",
    ApprovalIteration: "0",
    Client_CMID: selectedOption?.Id,
    ClientName: selectedOption?.Name,
    ActivityListId: "",
    AssignedTo: userData?.[0]?.EM_ID,
    Status: "P",
    CreatedBy: userData?.[0]?.EM_ID,
    CreateDate: formatDate(),
    StdTime: 0,
    AjdTime: 0,
    TotalTime: 0,
    ExtraTimeRemarks: "",
    Error_SEID: "0",
    ErrorRemarks: "",
    ReviewedBy_EMID: "",
    ReviewStatus: "P",
    ReviewDate: "",
    ReviewCount: "0",
    ErrorCount: "0",
    SalesforceCode: activityList?.[0]?.AL_SalseforceCode || "0201 Vouchers",
    DepartmentId: selectedMasterData?.[0]?.Department,
  });
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    //console.log(props.selectedDay, props.selectedMonth, props.selectedYear);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = monthNames.indexOf(props.selectedMonth);

    if (monthIndex === -1) {
      console.error("Invalid month name");
      return;
    }

    const today = new Date();
    const todayDateOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    const targetDate = new Date(
      props.selectedYear,
      monthIndex,
      props.selectedDay
    );

    // Log the dates for debugging
    //console.log("Today:", todayDateOnly);
    //console.log("Target Date:", targetDate);

    if (
      todayDateOnly.getFullYear() === targetDate.getFullYear() &&
      todayDateOnly.getMonth() === targetDate.getMonth() &&
      todayDateOnly.getDate() === targetDate.getDate()
    ) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [props.selectedDay, props.selectedMonth, props.selectedYear]);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(index);
    //console.log(index);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);
  const [loading, setLoading] = useState(false);
  const columns = [
    { id: "activityName", label: "VOUCHER NUMBER", minWidth: 160 },
    { id: "viewForceCode", label: "VOUCHER TYPE ", minWidth: 380 },
    {
      id: "standardTime",
      label: "Standard Time (Min)",
      minWidth: 190,

      align: "left",
      textAlign: "center",
    },
    {
      id: "actualTime",
      label: "Adjusted Time (Min)",
      minWidth: 190,

      align: "left",
      textAlign: "center",
    },
    {
      id: "totalTime",
      label: "Total Time (Min)",
      minWidth: 270,

      align: "left",
      textAlign: "center",
    },
    {
      id: "comments",
      label: "Comments",
      minWidth: 270,

      align: "left",
      textAlign: "left",
    },
    {
      id: "edit",
      label: "Edit",
      minWidth: 50,

      align: "center",
      textAlign: "center",
    },
  ];

  const addNewTask = async (row) => {
    console.log(row);
    if(!row?.ActivityListId){
      toastRef.current.showToast("Select Activity", "warning");
      return false;
    }
    if(parseFloat(row?.AjdTime) && !row?.ExtraTimeRemarks){
      toastRef.current.showToast("Add Reason for Adjusted Time", "warning");
      return false;
    }
    //console.log(selectedMasterData);
    const updatedRow = {
      ...row,
      Client_CMID: selectedOption?.Id,
      ClientName: selectedOption?.Name,
      DepartmentId: selectedMasterData?.[0]?.Department,
      CreatedBy: userData?.[0]?.EM_ID,
      AssignedTo: userData?.[0]?.EM_ID,
      TotalTime: parseFloat(row.AjdTime) + parseFloat(row.StdTime) || 0,
    };
    try {
      const response = await fetch(`${taskApiUrl}/AddTask`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });

      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorized access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const data = await response.json();

      // Check for a valid response
      if (data?.Status === true) {
        if (data?.Result === true) {
          toastRef.current.showToast("Task added successfully.", "success");
          return true;
        } else if (data?.Result === false) {
          // Custom message when Result is false
          toastRef.current.showToast(
            "Could not add task. Add proper data.",
            "error"
          );
          return false;
        }
      }

      // Default error message for unexpected Status values
      toastRef.current.showToast("Could not add task. Period closed.", "error");
      return false;
    } catch (error) {
      // Handle any unexpected errors
      toastRef.current.showToast("Something went wrong.", "error");
      console.error(error);
    }
  };

  const editTask = async (row) => {
    //console.log(row);
    // return;
    if(!row?.ActivityListId){
      toastRef.current.showToast("Select Activity", "warning");
      return false;
    }
    if(parseFloat(row?.AjdTime) && !row?.ExtraTimeRemarks){
      toastRef.current.showToast("Add Reason for Adjusted Time", "warning");
      return false;
    }
    const updatedRow = {
      ...row,
      Client_CMID: selectedOption?.Id,
      ClientName: selectedOption?.Name,
      CreatedBy: userData?.[0]?.EM_ID,
      DepartmentId: selectedMasterData?.[0]?.Department,
      TotalTime: parseFloat(row.StdTime) + parseFloat(row.AjdTime) || 0,
    };

    try {
      const response = await fetch(`${taskApiUrl}/UpdateTask`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const data = await response.json();
      if (data?.Status === true && data?.Result === true) {
        toastRef.current.showToast("Task Edited successfully.", "success");

return true;
      } else if (
        data?.Status === false &&
        data?.Message === "Activity Period Close" &&
        data?.ResponseCode === 200 &&
        data?.Result === ""
      ) {
        toastRef.current.showToast("Error: Activity Period Closed.", "error");
        return false;
      } else {
        toastRef.current.showToast(
          "Could not Edit task. Add proper data.",
          "error"
        );
        return false;
      }

      //console.log(data);
      // if (data) {
      //   fetchRelatedVouchersOnDate();
      // }
    } catch (e) {
      //console.log(e);
    }
  };

  const handleSelect = (AL_ID) => {
    const time = selectedMasterData?.[0]?.standardActivitiesTime?.filter(
      (a) => a.SATActivity == AL_ID
    )?.[0]?.SATTime;

    //console.log(AL_ID, time);
    //console.log(time);
    //console.log(currentRow, rows?.length);
    if (currentRow != undefined) {
      const updatedRows = rows.map((row, index) => {
        //console.log("ttt");
        //console.log(index, currentRow);
        if (index === currentRow) {
          setEditedRow((editedRow) => ({
            ...editedRow,
            ActivityListId: AL_ID,
            StdTime: time,
          }));
          return { ...editedRow, ActivityListId: AL_ID, StdTime: time };
        }
        return row;
      });
      //console.log(updatedRows);
      setRows(updatedRows);
    } else {
      //console.log("mmm");

      setNewRow({ ...newRow, ActivityListId: AL_ID, StdTime: time });
    }
    handleClose();
  };

  const handleInputChange = (e, rowIndex = null) => {
    const { name, value } = e.target;

    if (rowIndex !== null) {
      // If editing an existing row
      setEditedRow((prevRow) => {
        const updatedRow = {
          ...prevRow,
          TotalTime: parseFloat(prevRow.StdTime) + parseFloat(prevRow.AjdTime),
          [name]: value,
        };
        if (name === "StdTime" || name === "AjdTime") {
          updatedRow.TotalTime = (
            parseFloat(updatedRow.StdTime || 0) +
            parseFloat(updatedRow.AjdTime || 0)
          ).toFixed(2);
        }
        return updatedRow;
      });
    } else {
      // If updating the new row
      setNewRow((prevRow) => {
        const updatedRow = { ...prevRow, [name]: value };
        if (name === "StdTime" || name === "AjdTime") {
          updatedRow.TotalTime =
            parseFloat(updatedRow.StdTime || 0) +
            parseFloat(updatedRow.AjdTime || 0);
        }
        return updatedRow;
      });
    }
  };

  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleAddRow = async () => {
    //console.log(selectedOption);
   

    const requiredFields = [
      "Code",
      "ActivityListID",
      "StdTime",
      // "AjdTime",
      // "TotalTime",
      // "ExtraTimeRemarks",
    ];

    const allFieldsFilled = requiredFields.every(
      (field) => newRow[field] !== ""
    );
    if(parseFloat(newRow?.AjdTime) && !newRow?.ExtraTimeRemarks){
      toastRef.current.showToast("Add Reason for Adjusted Time", "warning");
      return;
    }
    // if(parseFloat(newRow?.Error_SEID) && !newRow?.ErrorRemarks){
    //   toastRef.current.showToast("Add Reason for Error", "warning");
    //   return;
    // }
    if (allFieldsFilled) {
      try {
        const add = await addNewTask(newRow);
        if (add) {
          console.log({...newRow, TotalTime: parseFloat(newRow.StdTime)+parseFloat(newRow.AjdTime)} );
          setRows([...rows, {...newRow, TotalTime: parseFloat(newRow.StdTime)+parseFloat(newRow.AjdTime)}]);
          setNewRow({
            Code: "",
            Type: "Vouchers",
            ApprovalIteration: "0",
            Client_CMID: selectedOption?.Id,
            ActivityListId: "",
            AssignedTo: userData?.[0]?.EM_ID,
            Status: "P",
            CreatedBy: userData?.[0]?.EM_ID,
            CreateDate: formatDate(),
            StdTime: 0,
            AjdTime: 0,
            TotalTime: 0,
            ExtraTimeRemarks: "",
            Error_SEID: "0",
            ErrorRemarks: "",
            ReviewedBy_EMID: "",
            ReviewStatus: "P",
            ReviewDate: "",
            ReviewCount: "0",
            ErrorCount: "0",
            SalesforceCode:
            activityList?.[0]?.AL_SalseforceCode || "0201 Vouchers",
            DepartmentId: selectedMasterData?.[0]?.DepartmentId,
            Client_CMID: selectedOption?.Id,
            ClientName: selectedOption?.Name,
            DepartmentId: selectedMasterData?.[0]?.Department,
            CreatedBy: userData?.[0]?.EM_ID,
            AssignedTo: userData?.[0]?.EM_ID,
          });

          toastRef.current.showToast("Task added successfully", "success");
        }
      } catch (error) {
        console.error("Failed to add the new task:", "error");

        toastRef.current.showToast("Failed to add data");
      }
    } else {
      toastRef.current.showToast("Please fill in all required fields");
    }
  };

  const handleEditClick = (index) => {
    setEditRowIndex(index);
    setEditedRow(rows[index]);
  };

  const handleSaveClick = async (index) => {
    const updatedRows = rows.map((row, i) => (i === index ? editedRow : row));
    

    try {
      //console.log(editedRow);
      // return;
     const ed =  await editTask(editedRow);
     if(ed){
      setRows(updatedRows);
      setEditRowIndex(null);
      setEditedRow({});
              // fetchRelatedVouchersOnDate();

     }
     
    } catch (error) {
      console.error("Failed to edit the task:", error);
      toastRef.current.showToast("Failed to submit data", "error");
    }
  };

  return (
    <div className="RA-T" style={{ background: "#efe6ff", paddingTop: "20px" }}>
      <Paper sx={{ borderRadius: "8px" }}>
        <TableContainer>
          <ToastComponent ref={toastRef} timeout={4000} />
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      textAlign: column.textAlign,
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {column.label === "VOUCHER TYPE" ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {column.label}
                      </div>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 1 ? "" : "#F1F1F1",
                  }}
                >
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="Code"
                        value={editedRow.Code}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.Code
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",

                      textAlign: "left",
                      cursor: isEditable ? "pointer" : "default",
                    }}
                  >
                    {
                      activityList?.filter(
                        (act) => act.AL_ID === row.ActivityListId
                      )[0]?.AL_Name
                    }
                    {editRowIndex === index && (
                      <ArrowDropDownIcon
                        style={{
                          color: "black",
                          cursor: "pointer",
                          marginLeft: "50px",
                        }}
                        onClick={
                          isEditable ? (e) => handleClick(e, index) : null
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",

                      textAlign: "center",
                    }}
                  >
                    <Typography name="StdTime">{row.StdTime}</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "center",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="AjdTime"
                        value={editedRow.AjdTime}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.AjdTime
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",

                      textAlign: "center",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="TotalTime"
                        value={
                          parseFloat(editedRow.StdTime) +
                          parseFloat(!editedRow.AjdTime ? 0 : editedRow.AjdTime)
                        }
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.TotalTime
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    {editRowIndex === index ? (
                      <TextField
                        name="ExtraTimeRemarks"
                        value={editedRow.ExtraTimeRemarks}
                        onChange={(e) => handleInputChange(e, index)}
                        fullWidth
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    ) : (
                      row.ExtraTimeRemarks
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {isEditable &&
                      (editRowIndex === index ? (
                        <IconButton
                          // color="primary"

                          aria-label="save"
                          onClick={() => handleSaveClick(index)}
                        >
                          <SaveIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditClick(index)}
                        >
                          <EditIcon color="red" />
                        </IconButton>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
              {isEditable && (
                <TableRow
                  style={{
                    backgroundColor: rows.length % 2 === 1 ? "" : "#F1F1F1",
                  }}
                >
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    <TextField
                      name="Code"
                      value={newRow.Code}
                      onChange={(e) => handleInputChange(e)}
                      fullWidths
                      sx={{
                        border: "none",
                        outline: "none",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    {activityList?.filter(
                      (act) => act.AL_ID === newRow.ActivityListId
                    )[0]?.AL_Name || "Select a Voucher"}
                    <ArrowDropDownIcon
                      style={{
                        color: "black",
                        cursor: "pointer",
                        marginLeft: "50px",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "center",
                    }}
                  >
                    <TextField
                      name="StdTime"
                      value={newRow.StdTime}
                      // onChange={(e) => handleInputChange(e)}
                      fullWidth
                      sx={{
                        border: "none",
                        outline: "none",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "center",
                    }}
                  >
                    <TextField
                      name="AjdTime"
                      value={newRow.AjdTime}
                      onChange={(e) => handleInputChange(e)}
                      fullWidth
                      sx={{
                        border: "none",
                        outline: "none",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "center",
                    }}
                  >
                    <TextField
                      name="TotalTime"
                      value={
                        parseFloat(newRow.StdTime) +
                        parseFloat(!newRow.AjdTime ? 0 : newRow.AjdTime)
                      }
                      fullWidth
                      disabled
                      sx={{
                        border: "none",
                        outline: "none",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderBottom: "none",
                      borderRight: "1px solid #D9D9D9",
                      padding: "4px 16px",
                      textAlign: "left",
                    }}
                  >
                    <TextField
                      name="ExtraTimeRemarks"
                      value={newRow.ExtraTimeRemarks}
                      onChange={(e) => handleInputChange(e)}
                      fullWidth
                      sx={{
                        border: "none",
                        outline: "none",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <button
                      variant="contained"
                      // color="primary"
                      onClick={handleAddRow}
                    >
                      Add
                    </button>
                  </TableCell>
                </TableRow>
              )}

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {activityList.map((activity) => (
                  <MenuItem
                    key={activity.AL_ID}
                    onClick={() => handleSelect(activity.AL_ID)}
                  >
                    <Checkbox
                      checked={
                        rows[currentRow]?.ActivityListId === activity.AL_ID
                      }
                    />
                    {activity.AL_Name}
                  </MenuItem>
                ))}
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
