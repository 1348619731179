import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { CaretDown } from "@phosphor-icons/react";
import { useNavigate } from "react-router";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import "../Styles/NavMain.css";
import { CaretUp } from "phosphor-react";

const MappedMenu = () => {
  const [items, setItems] = useState([]);
  const [innerItems, setInnerItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItem1, setSelectedItem1] = useState(null);

  const [menu, setMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(true);
  const [current, setCurrent] = useState("Dashboard");
  const { fetchTeams, usersList } = useContext(UserContext);
  const { userData, token, logout } = useContext(AuthContext);
  const toastRef = useRef();

  // const [selectedSubItem, setSelectedSubItem] = useState(null);
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState("");
  const [selectedSubMenuItem1, setSelectedSubMenuItem1] = useState("");

  const dropdownRef = useRef(null);

  const handleMenuItemHover = (item) => {
    setSelectedItem(item);
    const hasInnerItems = innerItems.some((i) => i.parentNames === item);
    setSubMenu(hasInnerItems);
  };
  const handleMenuItemClick2 = (item) => {
    setSelectedItem(item);
    setSubMenu(false);

    if (item === "Dashboard") {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function removeDuplicatesAndGroupByParentNames(items) {
    const uniqueItems = new Map();
    const groupedByParentName = {};

    // Remove duplicates
    items.forEach((item) => {
      if (!uniqueItems.has(item.id)) {
        uniqueItems.set(item.id, item);
      }
    });

    // Group by parentNames
    Array.from(uniqueItems.values()).forEach((item) => {
      if (!groupedByParentName[item.parentNames]) {
        groupedByParentName[item.parentNames] = [];
      }
      groupedByParentName[item.parentNames].push(item);
    });

    return groupedByParentName;
  }

  function removeDuplicates(items) {
    const uniqueItems = new Map();

    items.forEach((item) => {
      if (!uniqueItems.has(item.id)) {
        uniqueItems.set(item.id, item);
      }
    });

    return Array.from(uniqueItems.values());
  }
  function convertRoles(input) {
    // Split the input string by commas and map each part to a number
    return input?.split(",")?.map(Number);
  }

  const fetchMenu = async () => {
    
    if (!token) {
      return;
    }
    let roles = [1, 2, 3, 4, 5, 6];
    roles = convertRoles(userData?.[0]?.DRM_ID);
    try {
      const response = await fetch(
        `${userApiUrl}/GetManu?${roles?.map((id) => `id=${id}`).join("&")}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        // //console.log(data);
        const unique = removeDuplicates(data);
        setInnerItems(unique);
        const names = removeDuplicatesAndGroupByParentNames(data);
        // //console.log(names);
        ////console.log(Object.keys(names));
        ////console.log(["Dashboard", ...Object.keys(names)]);
        setItems(["Dashboard", ...Object.keys(names)]);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchMenu();
    setSubMenu(false);
  }, [userData]);

  useEffect(() => {
    if (!menu) {
      setSubMenu(false);
    }
  }, [menu]);
  const navigate = useNavigate();
  const currentPage = window.location.pathname;

  // useEffect(() => {

  //   setSelectedSubMenuItem1(currentPage);
  // }, [currentPage]);

  // useEffect(() => {

  //   const menuItem = innerItems.find(item => item.route === currentPage);
  //   if (menuItem) {
  //     setSelectedItem(menuItem.parentNames);
  //     setSelectedSubMenuItem1(menuItem.route);
  //   }
  // }, [currentPage, innerItems]);

  useEffect(() => {
    // //console.log(currentPage)
    if (currentPage === "/CustomerMasterData") {
      setCurrent("Customer Master Data");
    } else if (currentPage === "/UserSetupAndRoles") {
      setCurrent("User Setup And Roles");
    }
    if (currentPage.includes("/UserSetUpDetial")) {
      setCurrent("User Setup And Roles");
    } else if (currentPage === "/lookUpPage") {
      setCurrent("LOOK UP INFORMATION ");
    } else if (currentPage === "/BudgetForeCast") {
      setCurrent("BUDGET FORECAST");
    } else if (currentPage === "/ViewForceSupUser") {
      setCurrent("VIEW FORCE DATA IMPORT");
    } else if (currentPage === "/TimeCode") {
      setCurrent("PERIOD OPEN / CLOSE");
    } else if (currentPage === "/dashboard") {
      setCurrent("DASHBOARD");
    } else if (currentPage === "/Activitywisetimereport") {
      setCurrent("ACTIVITY WISE TIME REPORT");
    } else if (currentPage === "/UserwiseTimeReport") {
      setCurrent("USER WISE TIME REPORT");
    } else if (currentPage === "/StandardvsActualTimeReport") {
      setCurrent("STANDARD VS ACTUAL TIME REPORT");
    } else if (currentPage === "/VoucherEntry") {
      setCurrent("VOUCHER ENTRY");
    } else if (currentPage === "/VoucherReview") {
      setCurrent("VOUCHER REVIEW");
    } else if (currentPage === "/STRActivity") {
      setCurrent("STR PROCESS");
    } else if (currentPage === "/WeeklyTimesheet") {
      setCurrent("Weekly Time Sheets");
    } else if (currentPage === "/WeeklyReview") {
      setCurrent("Review of Time Sheets");
    } else if (currentPage === "/ReportingActivities") {
      setCurrent("Reporting Activites");
      //console.log("current page" + currentPage);
    } else if (currentPage === "/QualityControl") {
      setCurrent("Control Points");
      //console.log("current page" + currentPage);
    } else if (currentPage === "/Capacity2") {
      setCurrent("CAPACITY UTILISATION");
    }
  }, []);

  const handleSubMenuItemClick = (item) => {
    // //console.log(item);
    if (item === "Dashboard") {
      navigate("/dashboard");
    } else if (item === "Voucher Entry") {
      navigate("/VoucherEntry");
    } else if (item === "Review of Vouchers") {
      navigate("/VoucherReview");
    } else if (item === "Month End Activities") {
      navigate("/CommingSoon");
    } else if (item === "Adhoc Activities") {
      navigate("/CommingSoon");
    } else if (item === "Review of Activities") {
      navigate("/CommingSoon");
      // } else if (item === "Weekly Time Sheets") {
      //   navigate("/CommingSoon");
      // } else if (item === "Review of Time Sheets") {
      // navigate("/CommingSoon");
    } else if (item === "Process Controls") {
      navigate("/CommingSoon");
      // } else if (item === "Control Points") {
      // navigate("/CommingSoon");
    } else if (item === "STANDARD VS ACTUAL TIME REPORT") {
      navigate("/StandardvsActualTimeReport");
    } else if (item === "STR Checklist") {
      navigate("/STRActivity");
    } else if (item === "User wise Time report") {
      navigate("/UserwiseTimeReport");
    } else if (item === "Activity wise Time report") {
      navigate("/Activitywisetimereport");
    } else if (item === "Standard vs Actual comparison") {
      navigate("/StandardvsActualTimeReport");
    } else if (item === "Average and Transaction time") {
      navigate("/CommingSoon");
    } else if (item === "Department wise Analysis") {
      navigate("/CommingSoon");
    } else if (item === "Capacity Utilization") {
      navigate("/Capacity2");
    } else if (item === "Synergy Billing Information") {
      navigate("/CommingSoon");
    } else if (item === "Customer Master Data") {
      navigate("/CustomerMasterData");
    } else if (item === "View Force Data Import") {
      navigate("/ViewForceSupUser");
    } else if (item === "User Setup & Roles") {
      navigate("/UserSetupAndRoles");
    } else if (item === "Activities & Tasks") {
      navigate("/CommingSoon");
    } else if (item === "Budget & Forecast") {
      navigate("/BudgetForeCast");
    } else if (item === "Period Open / Close") {
      navigate("/TimeCode");
    } else if (item === "Month Roll forward") {
      navigate("/CommingSoon");
    } else if (item === "Lookup Information") {
      navigate("/lookUpPage");
    } else if (item === "Departments") {
      navigate("/lookUpPage");
    } else if (item === "WD Calendar") {
      navigate("/lookUpPage");
    } else if (item === "Time Codes") {
      navigate("/lookUpPage");
    } else if (item === "Reporting Activites") {
      navigate("/ReportingActivities");
    } else if (item === "Control Points") {
      navigate("/QualityControl");
    } else if (item === "Weekly Time Sheets") {
      navigate("/WeeklyTimesheet");
    } else if (item === "Review of Time Sheets") {
      navigate("/WeeklyReview");
    } else {
      //console.log("Item not found");
    }
    setSelectedSubMenuItem1(item);
    setSelectedItem1(
      innerItems.find((innerItem) => innerItem.route === item)?.parentNames ||
        ""
    );
  };

  const handleMenuItemClick = (item) => {
    if (item === "Dashboard") {
      navigate("/dashboard");
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenu(false);
    }
  };

  if (!menu) {
    document.removeEventListener("mousedown", handleClickOutside);
  } else {
    document.addEventListener("mousedown", handleClickOutside);
  }

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <div
        className="font-bold cursor-pointer text-purple-950 text-l font-poppins sm:text-lg"
        onClick={() => {
          setMenu(!menu);
        }}
      >
        {current?.toUpperCase()}
      </div>
      {/* <button
        style={{ margin: "0px 20px" }}
        onClick={() => {
          setMenu(!menu);
        }}
      >
        <CaretDown size={16} color="#3d025f" weight="fill" />
      </button> */}
      <button
        style={{ margin: "0px 20px" }}
        onClick={() => {
          setMenu(!menu);
        }}
      >
        {menu ? (
          <CaretUp size={16} color="#3d025f" weight="fill" />
        ) : (
          <CaretDown size={16} color="#3d025f" weight="fill" />
        )}
      </button>
      {menu && (
        <div
          ref={dropdownRef}
          className="absolute mt-1 bg-white rounded-lg shadow-lg top-3/4 left-48"
          style={{
            display: "flex",
            background: "white",
            gap: "15px",
            position: "absolute",
            zIndex: 1000,
            top: "40px",
            left: "100px",
          }}
          onBlur={() => {
            setSubMenu(false);
            setMenu(false);
          }}
        >
          <div className="list-container-menu">
            <ul className="flex flex-col items-center gap-6 mt-6 mb-6 min-w-60">
              {items?.map((item) => (
                <li
                  key={item}
                  className="w-full px-4 pt-0 whitespace-no-wrap cursor-pointer li-design "
                  onMouseEnter={() => handleMenuItemHover(item)}
                  onClick={() => handleMenuItemClick2(item)}
                  style={{
                    color: selectedItem === item ? "purple" : "black",
                    fontWeight: selectedItem === item ? 600 : 400,
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
            <div className="vertical-line"></div>
          </div>

          {subMenu && (
            <ul className="flex flex-col items-center gap-6 mt-6 mb-6 min-w-60">
              {innerItems
                ?.filter((i) => i?.parentNames === selectedItem)
                ?.map((item) => (
                  <li
                    key={item.route}
                    className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap li-design submenu-item ${
                      selectedSubMenuItem1 === item.route
                        ? "selected-submenu-item1"
                        : ""
                    }`}
                    onClick={() => handleSubMenuItemClick(item.route)}
                  >
                    {item.route}
                  </li>
                ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default MappedMenu;
