import React, { useContext, useEffect, useState, useRef } from "react";
import NavSub from "../components/NavSub";
import "../Styles/WeeklyTimesheet.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { AuthContext } from "../context/AuthContext";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  CircularProgress
} from "@mui/material";
import * as XLSX from "xlsx";
import { CaretDown, CaretUp, FileArrowUp } from "phosphor-react";
import { Button } from "bootstrap";
import ToastComponent from "../components/ToastComponent";
import { clientApiUrl, taskApiUrl } from "../utils/GetUrl";

const WeeklyTimesheet = () => {
  const [clients, setClients] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [viewForce, setViewForce] = useState([]);
  const { userData } = useContext(AuthContext);
  const [selectedTimeCode, setSelectedTimeCode] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);

  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date(); // Current date
    today.setDate(1); // Set to the first day of the current month
    return today.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  });
  
  const [selectedDate2, setSelectedDate2] = useState(
    new Date().toISOString().split("T")[0] // Current date of the current month
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [weeklyReport, setWeeklyReport] = useState([]);
  const { token, logout } = useContext(AuthContext);

  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingViewForce, setLoadingViewForce] = useState(false);
  const [loadingWeeklyReport, setLoadingWeeklyReport] = useState(false);
  const [isDownloadable, setIsDownloadable]= useState(userData?.[0]?.DR_Name==='Consultant'?false:true);
  const [currentWeek, setCurrentWeek] = useState(true)

  useEffect(() => {
    if (selectedDate && selectedDate2) {
      const startDate = new Date(selectedDate); // Start date from selectedDate
      const endDate = new Date(selectedDate2);  // End date from selectedDate2
  
      // Format function to get date in YYYY-MM-DD format
      const formatDate = (date) => date.toISOString().split("T")[0];
  
      // Set formatted start and end dates
      setStartDate(formatDate(startDate));
      setEndDate(formatDate(endDate));
    }
  }, [selectedDate, selectedDate2]);

  // useEffect(() => {
  
  //   const getWeekRange = (date) => {
  //     const cloneDate = new Date(date); // Clone the date to avoid mutating the original
  //     const day = cloneDate.getDay(); // Get current day of the week
  //     const diff = cloneDate.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday (0)
  
  //     const startOfWeek = new Date(cloneDate.setDate(diff)); // Monday
  //     const endOfWeek = new Date(cloneDate.setDate(diff + 6)); // Sunday
  
  //     return { startOfWeek, endOfWeek };
  //   };
  
  //   const currentDate = new Date(); // Today's date
  //   const selectedDateObj = new Date(selectedDate); // Date from state
  
  //   const { startOfWeek: currentStart, endOfWeek: currentEnd } = getWeekRange(currentDate);
  //   const { startOfWeek: selectedStart, endOfWeek: selectedEnd } = getWeekRange(selectedDateObj);
  
  
  //   // Convert dates to timestamps for comparison
  //   if (
  //     currentStart.getTime() <= selectedDateObj.getTime() &&
  //     selectedDateObj.getTime() <= currentEnd.getTime() &&
  //     selectedStart.getTime() <= currentDate.getTime() &&
  //     currentDate.getTime() <= selectedEnd.getTime()
  //   ) {
  //     setCurrentWeek(true);
  //   } else {
  //     setCurrentWeek(false);
  //   }
  // }, [selectedDate]);
  

  useEffect(()=>{
    console.log(currentWeek, isDownloadable)
  }, [currentWeek, isDownloadable])

  const toastRef = useRef();
  const fetchActivityList = async () => {
    if(!token) {
      return;
      } 
    setLoadingActivities(true); // Start loading
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        const filteredData = data?.filter(i=>i.AL_Status=="1")
        setActivityList([
          ...new Set(filteredData?.map((code) => code.AL_SalseforceCode)),
        ]);
      }
    } catch (error) {
      console.log(error);
    }finally {
      setLoadingActivities(false); // End loading
    }
  };


  const checkIfDownloadable = async () => {
    if(!token) {
      return;
      } 
    setLoadingActivities(true); // Start loading
    try {
      const response = await fetch(
        `${taskApiUrl}/GetFileDownloadAction`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
       if(data){

        const validationDate = new Date(data?.[0]?.ValidationDate);
        const date1 = new Date(selectedDate);
        const date2 = new Date(selectedDate2);
  
        // Find the maximum of the two dates
        const maxSelectedDate = new Date(Math.max(date1.getTime(), date2.getTime()));
  
        // Compare maxSelectedDate with validationDate
        if (maxSelectedDate <= validationDate) {
          setIsAllowed(true);
        } else {
          setIsAllowed(false);
        }
      }
     
       
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    checkIfDownloadable();
  },[selectedDate, selectedDate2,token])

  const fetchViewForce = async () => {
    if(!token) {
      return;
      } 
    try {
      const response = await fetch(
        `${taskApiUrl}/GetDataByDate?StartDate=${startDate}&EndDate=${endDate}&EMId=${userData?.[0]?.EM_ID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setViewForce(data);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchWeekly = async () => {
    if(!token) {
      return;
      } 
    setLoadingWeeklyReport(true); 
    try {
      const response = await fetch(
        // `https://fn-hampi-taskmanager.azurewebsites.net/api/GetAllTaskClientWiseReport?DateRange1=${startDate}&DateRange2=${endDate}&AssginedToEmids='C3D788DA-090A-11EF-A8D5-6045BDA599B0'`,
        `${taskApiUrl}/GetAllTaskClientWiseReport?DateRange1=${startDate}&DateRange2=${endDate}&AssginedToEmids='${userData?.[0]?.EM_ID}'`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const data = await response.json();
      setWeeklyReport(data);
    } catch (e) {
      console.log(e);
    }finally {
      setLoadingWeeklyReport(false); // End loading
    }
  };

  useEffect(() => {
    if (userData && startDate) {
      fetchViewForce();
      fetchWeekly();
    }
  }, [userData, startDate, endDate, token]);

  useEffect(() => {
    fetchActivityList();
  }, [token]);

  const handleActivityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedActivities(typeof value === "string" ? value.split(",") : value);
  };

  const downloadExcel = () => {
    // Generate table data
    const { header, data } = generateReportTable(
      activityList,
      weeklyReport,
      viewForce
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the table data to a worksheet
    const worksheetData = [
      header,
      ...data.map((row) =>
        header.map((col) => (row[col] !== undefined ? row[col] : 0))
      ),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `WeeklyReport_${userData?.[0]?.EM_FirstName}`
    );

    // Write the workbook to a binary string
    const workbookBinary = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const buffer = new ArrayBuffer(workbookBinary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < workbookBinary.length; i++) {
      view[i] = workbookBinary.charCodeAt(i) & 0xff;
    }
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Create a link element and trigger a download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `WeeklyReport_${userData?.[0]?.EM_FirstName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Usage example: Add a button to trigger the download

  function generateReportTable(activityList, weeklyReport, viewForce) {
    const filteredActivities =
      selectedActivities.length > 0 ? selectedActivities : activityList;
    const uniqueActivities = [...new Set(filteredActivities)];

    const tableHeader = [
      // "Project Code",
      "Client",
    
      ...uniqueActivities,
      ...uniqueActivities.map((a) => `${a} ViewForce`),
      ...uniqueActivities.map((a) => `${a} Difference`),
    ];

    const clientNames = [
      ...new Set(weeklyReport.map((report) => report.ClientName)),
    ];
    const clientIds = [
      ...new Set(weeklyReport.map((report) => report.ClientId)),
    ];
    const projectIds = [
      ...new Set(weeklyReport.map((report) => report.ProjectNumber)),
    ];

    const tableData = clientNames.map((clientName, index) => {
      const clientId = clientIds[index];
      const projectId = projectIds[index]; 
      const clientRow = {
        clientName: clientName,
        ProjectNumber:projectId,
        clientId: clientId,
        activitiesTotalTime: {},
        activitiesViewForceTime: {},
        activitiesDifference: {},
      };

      uniqueActivities.forEach((activity) => {
        clientRow.activitiesTotalTime[activity] = 0;
        clientRow.activitiesViewForceTime[activity] = 0;
        clientRow.activitiesDifference[activity] = 0;
      });

      weeklyReport.forEach((report) => {
        if (report.ClientName === clientName) {
          if (
            clientRow.activitiesTotalTime[report.SalesforceCode] !== undefined
          ) {
            clientRow.activitiesTotalTime[report.SalesforceCode] += parseFloat(
              report.TotalTime
            );
          }
        }
      });

      viewForce.forEach((view) => {
        if (view.clientId == clientId) {
          if (clientRow.activitiesViewForceTime[view.activity] !== undefined) {
            clientRow.activitiesViewForceTime[view.activity] += parseFloat(
              view.registeredTime
            );
          }
        }
      });

      uniqueActivities.forEach((activity) => {
        clientRow.activitiesDifference[activity] =
          clientRow.activitiesTotalTime[activity] -
          clientRow.activitiesViewForceTime[activity];
      });

      return clientRow;
    });

    return {
      header: tableHeader,
      data: tableData.map((clientRow) => {
        const row = {
          Client: clientRow.clientName,
          ProjectNumber: clientRow.ProjectNumber,
        };
        uniqueActivities.forEach((activity) => {
          row[activity] = clientRow.activitiesTotalTime[activity];
          row[`${activity} ViewForce`] =
            clientRow.activitiesViewForceTime[activity];
          row[`${activity} Difference`] =
            clientRow.activitiesDifference[activity];
        });
        return row;
      }),
    };
  }

  const { header, data } = generateReportTable(
    activityList,
    weeklyReport,
    viewForce
  );
  const getRowStyle = (index) => ({
    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
  });

  const [isdateDropdownOpen, setdatebellDropdownOpen] = useState(false);
  const [chosenDate, setChosenDate] = useState("");

  const toggledateDropdown = () => {
    setdatebellDropdownOpen(!isdateDropdownOpen);
  };

  const handleProceed = () => {
    setChosenDate(selectedDate);
    setdatebellDropdownOpen(!isdateDropdownOpen);
  };

  const dropdown2Ref = useRef(null);

  const menuItemStyle = {
    height: "30px",
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const tableContainerRef = useRef(null);
  const handleMouseScroll = () => {
    const tableContainer = tableContainerRef.current;

    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseDownHandler = (e) => {
      isDown = true;
      tableContainer.classList.add('active');
      startX = e.pageX - tableContainer.offsetLeft;
      scrollLeft = tableContainer.scrollLeft;
    };

    const mouseLeaveHandler = () => {
      isDown = false;
      tableContainer.classList.remove('active');
    };

    const mouseUpHandler = () => {
      isDown = false;
      tableContainer.classList.remove('active');
    };

    const mouseMoveHandler = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - tableContainer.offsetLeft;
      const walk = (x - startX) * 2; 
      tableContainer.scrollLeft = scrollLeft - walk;
    };
    tableContainer.addEventListener('mousedown', mouseDownHandler);
    tableContainer.addEventListener('mouseleave', mouseLeaveHandler);
    tableContainer.addEventListener('mouseup', mouseUpHandler);
    tableContainer.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      tableContainer.removeEventListener('mousedown', mouseDownHandler);
      tableContainer.removeEventListener('mouseleave', mouseLeaveHandler);
      tableContainer.removeEventListener('mouseup', mouseUpHandler);
      tableContainer.removeEventListener('mousemove', mouseMoveHandler);
    };
  };
  return (
    <div>
      <NavSub />
      <div className="weekly_time_sheet_head">
        
        <div>
        <ToastComponent ref={toastRef} timeout={4000} />
        <div className="weekly-nav-edit">
        {/* <ToastComponent ref={toastRef} timeout={4000} /> */}
          <Select
            className="change-font"
            multiple
            value={selectedActivities}
            onChange={handleActivityChange}
            displayEmpty
            onOpen={handleOpen}
            onClose={handleClose}
            IconComponent={() => null}
            renderValue={(selected) => {
              return (
                <button className="title-weekly-design">
                  SELECT ACTIVITY
                  {open ? (
                    <CaretUp
                      size={16}
                      weight="fill"
                      color="#3d025f"
                      className="icon-select-weekly"
                    />
                  ) : (
                    <CaretDown
                      size={16}
                      weight="fill"
                      color="#3d025f"
                      className="icon-select-weekly"
                    />
                  )}
                </button>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {activityList?.map((activity) => (
              <MenuItem key={activity} value={activity} sx={menuItemStyle}>
                <Checkbox checked={selectedActivities.indexOf(activity) > -1} />
                <ListItemText primary={activity} />
              </MenuItem>
            ))}
          </Select>

          <input
            type="date"
            className="date-design-weekly"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            label="Select Date"
          />
           <input
              type="date"
              className="date-design-weekly"
              value={selectedDate2}
              onChange={(e) => setSelectedDate2(e.target.value)}
              label="Select End Date"
            />
        </div>
        </div>
       {(isDownloadable || isAllowed )&& <div className="Nav-btn-user">
          <FileArrowUp size={25} color="#3d025f" weight="fill" />
          <button className="Nav-Sub-btn" onClick={downloadExcel}>
            DOWNLOAD FILES
          </button>
        </div>}
      </div>

      <div className="report-table-cont" ref={tableContainerRef}   onMouseEnter={handleMouseScroll}>
      {loadingWeeklyReport ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <CircularProgress style={{ color: "purple" }} width={40} />
    </div>
  ) : (

        <table className="report-table">
          <thead>
            <tr className="super-header"  >
              
              <th className="client-header " colSpan={1} style={{
                  position: "sticky",
                  left: -1,
                  zIndex: 1, 
                  background:"rgba(230, 230, 230, 1)",
                }}>CLIENT</th>
              <th className="total-time-header" colSpan={header.length / 3}>
                ACTUAL TIME
              </th>
              <th className="viewforce-time-header" colSpan={header.length / 3}>
                VIEW FORCE TIME
              </th>
              <th className="difference-header" colSpan={header.length / 3}>
                DIFFERENCE
              </th>
            </tr>
            <tr className="activity-headers">
              {header?.map((head, index) => (
                <th
                  className="activity-header table-title-weekly"
                  key={`header-${index}`}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr
                className="client-row "
                key={index}
                style={getRowStyle(index)}
               
              >
                
                <td className="client-name content-user "  style={{
                  position: "sticky",
                  left: -1,
                  zIndex: 1, 
                  backgroundColor: index % 2 === 0 ? "rgb(240, 240, 240)" : "rgb(255, 255, 255)", 
                  whiteSpace: "nowrap", 
                  textOverflow: "ellipsis", 
               
                }}>{row.Client} ({row.ProjectNumber})</td>
                
                {/* content-user-line */}

                {header?.slice(1)?.map((activity, activityIndex) => (
                  <td
                    className="activity-cell content-user"
                    key={`activity-${index}-${activityIndex}`}
                  >
                    <div className="activity-cell-value">{row[activity]}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
          )}
</div>
      
    </div>
  );
};

export default WeeklyTimesheet;
