import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/CustomerMasterTabs.css";
import { ArrowLeft, PencilSimpleLine } from "@phosphor-icons/react";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { MenuItem } from "@mui/material";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { UserContext } from "../context/UserContext";
import ToastComponent from "./ToastComponent";
function AddClientTabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [masterData, setMasterdata] = useState();
  const [projectData, setProjectData] = useState();
  const [roles, setRoles] = useState();
  const [department, setDepartment] = useState();
  const [currentDateTime, setCurrentDateTime] = useState();
  const { id } = useParams();
  const [empLoading, setEmpLoading] = useState(false);
  const { token, handleAuthExpiry, logout } = useContext(AuthContext);
  const [customerData, setCustomerData] = useState(null);
  const [voucherActivities, setVoucherActivities] = useState([]);
  const [reportingActivities, setReportingActivities] = useState([]);
  const toastRef = useRef();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const user = userData?.[0];
  const [usersList, setUsersList] = useState();
  const [errors, setErrors] = useState({});
  //   const[name,setName]=useState();
  //   const[projectNumber,setProjectNumber] =useState();
  //   const[taskName,setTaskName]=useState();
  //   const[orgNum,setOrgNum]=useState();
  //   const[outsourcingSubprocessor,setOutSourcingSubprocessor]=useState();
  //   const[costCenter,setCostCenter]=useState();
  //   const[remittanceType,setRemittanceType]=useState();
  //   const[status,setStatus]=useState();
  //   const [outsourcingStdt, setOutsourcingStdt] = useState('');
  // const [reportingFrequency, setReportingFrequency] = useState('');
  // const [voucherFrequency, setVoucherFrequency] = useState('');
  // const [hod, setHod] = useState('');
  // const [subLead, setSubLead] = useState('');
  // const [subOwner, setSubOwner] = useState('');
  // const [subProcLead, setSubProcLead] = useState('');
  // const [subQC, setSubQC] = useState('');

  const fetchCountry = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllCountry`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setCustomerData(data);
      } else {
        console.error("Failed to fetch customer data");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const fetchProjectList = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllProjectList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setProjectData(data);
      } else {
        console.error("Failed to fetchProjectList data");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    fetchProjectList();
  }, []);
  const fetchCustomer = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllClient`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setMasterdata(data);
      } else {
        console.error("Failed to fetch ");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchData = async () => {
    //console.log("Started");
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setVoucherActivities(data);
        setReportingActivities(data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchCustomer();
    fetchData();
  }, []);

  const fetchRoles = async () => {
    setEmpLoading(true);
    try {
      const response = await fetch(`${userApiUrl}/GetRole?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      //console.log(data);
      setRoles(data);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };
  const fetchDepartments = async () => {
    setEmpLoading(true);

    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=80000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      // //console.log(data);
      setDepartment(data);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchDepartments();
  }, [token]);
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString();
  };
  const [formData, setFormData] = useState({
    AccountInfoUpdateDate: getCurrentDateTime(),
    Accountant_EMId: "",
    CostCenter: "",
    Country: "",
    CustType: "",
    Department: "",
    ERPSystem: "",
    HOD: "",
    Industry: "",
    LeadId: "",
    Modifieddt: "",
    ModifyBy: user?.EM_ID,
    Name: "",
    OrgNum: "",
    OutsourcingEddt: "",
    OutsourcingStdt: "",
    OutsourcingSubprocessor: "",
    OwnerEMId: "",
    OwnerId: "",
    ParentAccount: "",
    PriceAgreement: "",
    ProjectNumber: "",
    RemittanceType: "",
    ReportingDt: "",
    ReportingFrequency: "",
    Status: "",
    SubLead: "",
    SubOwner: "",
    SubProcLead: "",
    SubQC: "",
    TaskName: "",
    TerminationDate: "",
    Type: "",
    VoucherFrequency: "",
    standardActivitiesTime: [],
  });
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  // const handleNextButtonClick = () => {
  //   const nextTab = activeTab + 1;
  //   setActiveTab(nextTab >= 0 && nextTab <= 3 ? nextTab : 0);
  // };
  //   const handleNextButtonClick = () => {
  //     if (activeTab === 0) {
  //         // Check for required fields in the first tab
  //         const requiredFields = ['Name', 'ProjectNumber', 'TaskName', 'OrgNum', 'CostCenter', 'Status', 'OutsourcingSubprocessor'];
  //         const hasEmptyField = requiredFields.some(field => !formData[field]);
  //         if (hasEmptyField || (formData.OutsourcingSubprocessor === "Synergy" && !formData.RemittanceType)) {
  //             setErrors(true);
  //             return;
  //         } else {
  //             setErrors(false);
  //             setActiveTab(activeTab + 1);
  //         }
  //     }
  //     else if (activeTab === 1) {
  //       if (formData.OutsourcingSubprocessor === "Synergy") {
  //           const requiredFields = ['OutsourcingStdt', 'ReportingFrequency', 'VoucherFrequency', 'Department', 'HOD', 'SubLead', 'SubOwner', 'SubProcLead', 'SubQC'];
  //           const hasEmptyField = requiredFields.some(field => !formData[field]);
  //           if (hasEmptyField) {
  //               setErrors(true);
  //               return;
  //           } else {
  //               setErrors(false);
  //               setActiveTab(activeTab + 1);
  //           }
  //       } else {
  //           setErrors(false);
  //           setActiveTab(activeTab + 1);
  //       }
  //   }  else if (activeTab === 2 || activeTab === 3) {
  //     const hasEmptyField = formData.standardActivitiesTime.some(activity => activity.SATTime.trim() === '');
  //     if (hasEmptyField || (formData.OutsourcingSubprocessor === "Synergy" && !formData.standardActivitiesTime)) {
  //         setErrors(true);
  //         return;
  //     } else {
  //         setErrors(false);
  //         setActiveTab(activeTab + 1);
  //     }
  // } else {
  //     setActiveTab(activeTab + 1);
  // }
  // };
  // const handleNextButtonClick = () => {
  //   let newErrors = {};

  //   if (activeTab === 0) {
  //     // Validation for tab 0
  //     const requiredFields = ['Name', 'ProjectNumber', 'TaskName', 'OrgNum', 'CostCenter', 'Status', 'OutsourcingSubprocessor'];
  //     requiredFields.forEach(field => {
  //       if (!formData[field]) {
  //         newErrors[field] = `${field} is required`;
  //       }
  //     });
  //     if (formData.OutsourcingSubprocessor === "Synergy" && !formData.RemittanceType) {
  //       newErrors.RemittanceType = 'RemittanceType is required when OutsourcingSubprocessor is Synergy';
  //     }
  //   } else if (activeTab === 1) {
  //     // Validation for tab 1
  //     if (formData.OutsourcingSubprocessor === "Synergy") {
  //       const requiredFields = ['OutsourcingStdt', 'ReportingFrequency', 'VoucherFrequency', 'Department', 'HOD', 'LeadId', 'OwnerId', 'SubLead', 'SubOwner', 'SubProcLead', 'SubQC'];
  //       requiredFields.forEach(field => {
  //         if (!formData[field]) {
  //           newErrors[field] = `${field} is required`;
  //         }
  //       });
  //     }
  //   } else if (activeTab === 2) {
  //     // Validation for tab 2
  //     if (formData.OutsourcingSubprocessor === "Synergy") {
  //       voucherActivities.forEach(voucher => {
  //         if (voucher.AL_SalseforceCode === "0201 Vouchers") {
  //           const activity = formData.standardActivitiesTime.find(activity => activity.SATActivity === voucher.AL_ID);
  //           if (!activity || !activity.SATTime.trim()) {
  //             newErrors[`voucher-${voucher.AL_ID}`] = `Please enter ${voucher.AL_Name}`;
  //           }
  //         }
  //       });
  //     }
  //   } else if (activeTab === 3) {
  //     // Validation for tab 3
  //     if (formData.OutsourcingSubprocessor === "Synergy") {
  //       voucherActivities.forEach(voucher => {
  //         if (voucher.AL_SalseforceCode !== "0201 Vouchers") {
  //           const activity = formData.standardActivitiesTime.find(activity => activity.SATActivity === voucher.AL_ID);
  //           if (!activity || !activity.SATTime.trim()) {
  //             newErrors[`voucher-${voucher.AL_ID}`] = `Please enter ${voucher.AL_Name}`;
  //           }
  //         }
  //       });
  //     }
  //   }

  //   // Update state with errors or move to the next tab
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //   } else {
  //     setErrors({});
  //     setActiveTab(activeTab + 1);
  //   }
  // };

  const handleNextButtonClick = async () => {
    let newErrors = {};
  
    if (activeTab === 0) {
      // Validation for tab 0
      const requiredFields = [
        "Name",
        "ProjectNumber",
        "TaskName",
        "OrgNum",
        "CostCenter",
        "Status",
      ];
      requiredFields.forEach((field) => {
        if (!formData[field]) {
          newErrors[field] = `${field} is required`;
        } else if (
          ["ProjectNumber", "OrgNum"].includes(field) &&
          /[^0-9]/.test(formData[field])
        ) {
          newErrors[field] = `${field} should only contain numbers`;
        }
      });
  
      // Check if OutsourcingSubprocessor is Synergy and RemittanceType is required
      if (formData.OutsourcingSubprocessor === "Synergy" && !formData.RemittanceType) {
        newErrors.RemittanceType =
          "RemittanceType is required when OutsourcingSubprocessor is Synergy";
      }
  
      if (formData.OutsourcingSubprocessor === "") {
        newErrors.OutsourcingSubprocessor = "Outsourcing Sub Processor is required";
      }
  
      // API call to check if ProjectNumber already exists
      if (formData.ProjectNumber) {
        try {
          const response = await fetch(
            `${clientApiUrl}/GetAllClient?SearchByText=${formData.ProjectNumber}`
          );
          const data = await response.json();
          if (data && data.length > 0 && data[0].ProjectNumber === formData.ProjectNumber) {
            newErrors.ProjectNumber = `ProjectNumber ${formData.ProjectNumber} already exists`;
          }
        } catch (error) {
          console.error("Error checking ProjectNumber:", error);
          newErrors.ProjectNumber = "Error checking ProjectNumber. Please try again later.";
        }
      }
    } else if (activeTab === 1) {
      // Validation for tab 1
      if (formData.OutsourcingSubprocessor === "Synergy") {
        const requiredFields = [
          "OutsourcingStdt",
          "ReportingFrequency",
          "VoucherFrequency",
          "Department",
          "HOD",
          "LeadId",
          "OwnerId",
          "SubLead",
          "SubOwner",
          "SubProcLead",
          "SubQC",
        ];
        requiredFields.forEach((field) => {
          if (!formData[field]) {
            newErrors[field] = `${field} is required`;
          }
        });
      }
    } else if (activeTab === 2) {
      // Validation for tab 2
      if (formData.OutsourcingSubprocessor === "Synergy") {
        voucherActivities
          .filter(
            (voucher) => voucher.AL_Type === "Vouchers" && voucher.AL_Status === "1"
          )
          .forEach((voucher) => {
            const activity = formData.standardActivitiesTime.find(
              (activity) => activity.SATActivity === voucher.AL_ID
            );
            if (!activity || !activity.SATTime.trim()) {
              newErrors[`voucher-${voucher.AL_ID}`] = `Please enter ${voucher.AL_Name}`;
            } else if (!/^\d+(\.\d+)?$/.test(activity.SATTime)) {
              newErrors[`voucher-${voucher.AL_ID}`] = `${voucher.AL_Name} should only contain numbers`;
            }
          });
      }
    } else if (activeTab === 3) {
      // Validation for tab 3
      if (formData.OutsourcingSubprocessor === "Synergy") {
        voucherActivities
          .filter(
            (voucher) =>
              voucher.AL_Type === "Reporting Activities" && voucher.AL_Status === "1"
          )
          .forEach((voucher) => {
            const activity = formData.standardActivitiesTime.find(
              (activity) => activity.SATActivity === voucher.AL_ID
            );
            if (!activity || !activity.SATTime.trim()) {
              newErrors[`voucher-${voucher.AL_ID}`] = `Please enter ${voucher.AL_Name}`;
            } else if (!/^\d+(\.\d+)?$/.test(activity.SATTime)) {
              newErrors[`voucher-${voucher.AL_ID}`] = `${voucher.AL_Name} should only contain numbers`;
            }
          });
      }
    }
  
    // If there are any errors, set them; otherwise, proceed to the next tab
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setActiveTab(activeTab + 1);
    }
  };
  

  const [displayValues, setDisplayValues] = useState({
    DepartmentName: "",
    HODName: "",
  });
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [filteredHODOptions, setFilteredHODOptions] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const hasEmptyField = value.trim() === "";
    let errorMessage = "";
    const alphanumericRegex = /^[A-Za-z0-9]+$/;
    const nameRegex = /^[A-Za-z\s]+$/;
    const dateRegex = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
    if (
      (name === "ProjectNumber" || name === "OrgNum") &&
      !alphanumericRegex.test(value)
    ) {
      errorMessage = `${name} should only contain numbers and letters.`;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: hasEmptyField || errorMessage,
    }));
    if (name === "CostCenter") {
      const selectedDepartment = department.find((dept) => dept.id === value);
      if (selectedDepartment) {
        setFormData((prev) => ({
          ...prev,
          CostCenter: value,
          Department: selectedDepartment.id,
          HOD: selectedDepartment.id,
        }));
        setDisplayValues({
          DepartmentName: selectedDepartment.name,
          HODName: selectedDepartment.owner_EM_ID,
        });
        setFilteredDepartments([selectedDepartment]);
        setFilteredHODOptions([selectedDepartment]);
      } else {
        setFormData((prev) => ({
          ...prev,
          CostCenter: value,
          Department: "",
          HOD: "",
        }));
        setDisplayValues({
          DepartmentName: "",
          HODName: "",
        });
        setFilteredDepartments([]);
        setFilteredHODOptions([]);
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {};
    if (formData.OutsourcingSubprocessor === "Synergy") {
      voucherActivities
        .filter(
          (voucher) =>
            voucher.AL_Type === "Reporting Activites" &&
            voucher.AL_Status === "1"
        )
        .forEach((voucher) => {
          const activity = formData.standardActivitiesTime.find(
            (activity) => activity.SATActivity === voucher.AL_ID
          );
          if (!activity || !activity.SATTime.trim()) {
            newErrors[
              `voucher-${voucher.AL_ID}`
            ] = `Please enter ${voucher.AL_Name}`;
          } else if (!/^\d+(\.\d+)?$/.test(activity.SATTime)) {
            newErrors[
              `voucher-${voucher.AL_ID}`
            ] = `${voucher.AL_Name} should only contain numbers`;
          }
        });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setActiveTab(3); // Ensure the user is on the right tab to correct errors
      return;
    }
    let updatedActivities = voucherActivities
    .filter((voucher) => voucher.AL_Status === "1") // Consider only active vouchers
    .map((voucher) => {
      const existingActivity = formData.standardActivitiesTime.find(
        (activity) => activity.SATActivity === voucher.AL_ID
      );
      return {
        SATActivity: voucher.AL_ID,
        SATTime: existingActivity ? existingActivity.SATTime : "0", // Default to '0' if no value is entered
      };
    });

  const modifiedFormData = {
    ...formData,
    standardActivitiesTime: updatedActivities, // Use the updated activities
    OutsourcingSubprocessor:
      formData.OutsourcingSubprocessor === "Others" ? "" : formData.OutsourcingSubprocessor,
  };
    try {
      const response = await fetch(`${clientApiUrl}/AddClient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(modifiedFormData),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        toastRef.current.showToast("Client added successfully", "success");
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay if needed
        fetchData();
        navigate("/CustomerMasterData");
      } else {
        toastRef.current.showToast("Failed to add client", "error");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toastRef.current.showToast("Failed to add client", "error");
    }
  };

  const handleChangeVoucher = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevFormData) => {
      const existingIndex = prevFormData.standardActivitiesTime.findIndex(
        (activity) => activity.SATActivity === name
      );

      let updatedStandardActivities;
      if (existingIndex !== -1) {
        // If it exists, update it
        updatedStandardActivities = [...prevFormData.standardActivitiesTime];
        updatedStandardActivities[existingIndex] = {
          ...updatedStandardActivities[existingIndex],
          SATTime: value !== "" ? value : "", // Set to '0' if value is empty
        };
      } else {
        // If it doesn't exist, add a new activity
        const newActivity = {
          SATActivity: name,
          SATTime: value !== "" ? value : "", // Set to '0' if value is empty
        };
        updatedStandardActivities = [
          ...prevFormData.standardActivitiesTime,
          newActivity,
        ];
      }

      return {
        ...prevFormData,
        standardActivitiesTime: updatedStandardActivities,
      };
    });

    // Validation - check if value is a number
    let validationError = "";
    const decimalRegex = /^\d+(\.\d+)?$/; // Allows decimal numbers
    if (!decimalRegex.test(value)) {
      validationError = `${
        voucherActivities.find((voucher) => voucher.AL_ID === name)?.AL_Name
      } should only contain numbers`;
    } else if (!value.trim()) {
      validationError = `${
        voucherActivities.find((voucher) => voucher.AL_ID === name)?.AL_Name
      } is required`;
    }

    // Update errors object based on validation
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`voucher-${name}`]: validationError,
    }));
  };

  // const handleChangeVoucher = (e) => {
  //   const { name, value } = e.target;
  //     const updatedStandardActivities = formData.standardActivitiesTime.map(activity => {
  //     if (activity.SATId === name) {
  //       return {
  //         ...activity,
  //         SATTime: value
  //       };
  //     }
  //     return activity;
  //   });

  //   const existingIndex = formData.standardActivitiesTime.findIndex(activity => activity.SATId === name);

  //   if (existingIndex !== -1) {
  //     setFormData(prevFormData => ({
  //       ...prevFormData,
  //       standardActivitiesTime: updatedStandardActivities
  //     }));
  //       setErrors(prevErrors => {
  //       const updatedErrors = { ...prevErrors };
  //       delete updatedErrors[`SATTime${existingIndex}`];
  //       return updatedErrors;
  //     });
  //   } else {
  //     const newActivity = {
  //       SATId: name,
  //       SATTime: value
  //     };

  //     setFormData((prevFormData) => ({
  //             ...prevFormData,
  //             standardActivitiesTime: [...prevFormData.standardActivitiesTime, newActivity]
  //           }));
  //         }
  // };

  const determineAccountStatus = (terminationDate) => {
    if (!terminationDate) {
      return "Active";
    }

    const currentDate = new Date();
    const termination = new Date(terminationDate);
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() - 3);

    if (termination >= currentDate) {
      return "In Progress";
    } else if (termination < currentDate && termination >= threeMonthsLater) {
      return "Inactive";
    } else {
      return "Inactive";
    }
  };

  const handleTerminationDateChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData };

    if (name === "terminationDate") {
      updatedFormData.Status = determineAccountStatus(value);
    }
    updatedFormData = {
      ...updatedFormData,
      [name]: value,
    };

    setFormData(updatedFormData);
  };
  const fetchDataEmp = async () => {
    try {
      const response = await fetch(`${userApiUrl}/GetAllEmp`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return false;
      }
      const data = await response.json();
      //console.log(data);
      setUsersList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataEmp();
  }, [token]);
  // const handleChangeRole = (e) => {
  //   const { name, value } = e.target;
  //       if (name === "SubQC" || name === "SubProcLead" || name === "SubLead") {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       SubOwner: value,
  //     }));
  //   }
  // };
  // const filteredDepartments = department?.filter(
  //   (dept) => dept.id === formData.CostCenter
  // );
  // const filteredHODOptions = department?.filter(
  //   (dept) => dept.id === formData.Department
  // );
  useEffect(() => {
    if (formData.AccountInfoUpdateDate === "Yes") {
      const currentDate = new Date().toLocaleString();
      setCurrentDateTime(currentDate);
    }
  }, []);
  // const handleSelectChange = (e) => {
  //   const value = e.target.value;
  //   const currentDate = new Date().toLocaleString();

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     AccountInfoUpdateDate: currentDate,
  //   }));

  //   if (value === 'Yes') {
  //     setCurrentDateTime(currentDate);
  //   } else {
  //     setCurrentDateTime(currentDate);
  //   }
  // };
  const handleSelectChange = (e) => {
    const value = e.target.value;
    const currentDate = new Date().toLocaleString();

    setFormData((prevData) => ({
      ...prevData,
      AccountInfoUpdateDate: currentDate, // Store the date and time
      AccountInfoUpdateSelection: value, // Store the selected option ("Yes" or "No")
    }));

    setCurrentDateTime(currentDate); // Always set the current date and time
  };

  return (
    <div className="main1t">
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className="Container-nav1t">
        <div className="modal_buttons_section1tt">
          <div className="flex gap-4 C-Title">
            <div
              onClick={() => {
                navigate("/CustomerMasterData");
              }}
            >
              <ArrowLeft size={20} color="#50145A" weight="fill" />
            </div>
            <div>CUSTOMER DETAIL</div>
          </div>
        </div>
        <div class=" gap-4  flex-row ">
          <div className="modal_buttons_section1t">
            <button
              className={`modal_btns1t ${activeTab === 0 ? "active" : ""}`}
              // onClick={() => handleTabClick(0)}
            >
              Basic Information
            </button>
            <button
              className={`modal_btns1t ${activeTab === 1 ? "active" : ""}`}
              // onClick={() => handleTabClick(1)}
            >
              Dates and Roles
            </button>
            <button
              className={`modal_btns1t ${activeTab === 2 ? "active" : ""}`}
              // onClick={() => handleTabClick(2)}
            >
              Time estimation for vouchers
            </button>
            <button
              className={`modal_btns1t ${activeTab === 3 ? "active" : ""}`}
              // onClick={() => handleTabClick(3)}
            >
              {" "}
              Time estimation for reporting activities{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="Tabs-props">
        {activeTab === 0 && (
          <div>
            <form class="flex flex-col w-full h-auto  mx-auto gap-4 bg-white  p-4  rounded-lg">
              <div class="flex gap-4 flex-wrap">
                <div>
                  <div className="master-label">Customer Name</div>
                  <div class="w-full h-hug flex gap-0 flex-col">
                    <input
                      type="text"
                      className="textfield_organisation_deatils1"
                      value={formData.Name}
                      name="Name"
                      placeholder="Enter Customer Name"
                      onChange={handleChange}
                    />
                    {/* {!formData.Name &&errors && <span className="error-message">Please enter name.</span>} */}
                    {errors.Name && (
                      <div className="error-message">{errors.Name}</div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="master-label">Project Code</div>
                  <div class="flex gap-0 flex-col">
  <input
    type="text"
    className="textfield_organisation_deatils1"
    value={formData.ProjectNumber}
    name="ProjectNumber"
    placeholder="Enter Project Code"
    onChange={handleChange}
  />
  {errors.ProjectNumber && (
    <span className="error-message">
      {typeof errors.ProjectNumber === "string" && errors.ProjectNumber.includes("already exists")
        ? errors.ProjectNumber
        : "Please enter Project Code."}
    </span>
  )}
</div>

                </div>
                <div>
                  <div className="master-label">Project Name</div>
                  <div class=" flex gap-0 flex-col">
                    <input
                      type="text"
                      className="textfield_organisation_deatils1"
                      value={formData.TaskName}
                      onChange={handleChange}
                      name="TaskName"
                      placeholder="Enter Project Name"
                    />
                    {errors.TaskName && errors && (
                      <span className="error-message">
                        Please enter Project Name .
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Org Num</div>
                  <div class=" flex gap-0 flex-col">
                    <input
                      type="text"
                      className="textfield_organisation_deatils1"
                      value={formData.OrgNum}
                      onChange={handleChange}
                      name="OrgNum"
                      placeholder="Enter Org Num"
                    />
                    {errors.OrgNum && errors && (
                      <span className="error-message">
                        Please enter an organization number.
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <div className="master-label">Customer Type</div>
                  <div>
                    <input
                      type="text"
                      className="textfield_organisation_deatils1"
                      value={formData.CustType}
                      onChange={handleChange}
                      name="CustType"
                      placeholder="Enter Customer Type"
                    />
                  </div>
                </div>

                <div>
                  <div className="master-label">Outsourcing Sub Processor</div>
                  <div className="flex items-center justify-center">
                    <select
                      className="textfield_organisation_deatils1"
                      value={formData.OutsourcingSubprocessor}
                      onChange={handleChange}
                      name="OutsourcingSubprocessor"
                    >
                      <option value="">Select Sub Processor</option>
                      <option value="Synergy">Synergy</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  {errors.OutsourcingSubprocessor && (
                    <div className="error-message">
                      {errors.OutsourcingSubprocessor}
                    </div>
                  )}
                </div>

                <div></div>
              </div>
            </form>
            <form class="flex flex-col  my-4 h-auto  mx-auto gap-4 bg-white w-full p-4 rounded-lg">
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Cost Center</div>
                  <div class="felx flex-col">
                    <div>
                      <select
                        type="text"
                        className="textfield_organisation_deatils12"
                        placeholder="Select Cost Center"
                        value={formData.CostCenter}
                        onChange={handleChange}
                        name="CostCenter"
                      >
                        <option value="">Select Cost Center</option>
                        {department?.map((dept) => (
                          <option key={dept.id} value={dept.id}>
                            {dept.costCenter}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      {" "}
                      {errors.CostCenter && errors && (
                        <span className="error-message">
                          Please select cost center.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="master-label">Account Owner</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Account Owner"
                      className="textfield_organisation_deatils1"
                      value={formData.OwnerEMId}
                      onChange={handleChange}
                      name="OwnerEMId"
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Authorised Accountant</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Authorised Accountant"
                      className="textfield_organisation_deatils1"
                      value={formData.Accountant_EMId} // Use the value from formData
                      onChange={handleChange} // Call handleChange when the input value changes
                      name="Accountant_EMId"
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Country</div>
                  <div className="w-full gap-8 h-hug">
                    <select
                      className="textfield_organisation_deatils1"
                      value={formData.Country}
                      onChange={handleChange}
                      placeholder="Select Country"
                      name="Country"
                    >
                      <option value="">Select Country</option>
                      {customerData?.map((country) => (
                        <option key={country.Name} value={country.Name}>
                          {country.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <div className="master-label">ERP System</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter ERP System"
                      className="textfield_organisation_deatils1"
                      value={formData.ERPSystem}
                      onChange={handleChange}
                      name="ERPSystem"
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Industry</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Industry"
                      className="textfield_organisation_deatils1"
                      value={formData.Industry}
                      onChange={handleChange}
                      name="Industry"
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Price Aggrement</div>
                  <div class="w-full h-hug  gap-8">
                    <input
                      type="text"
                      placeholder="Enter Price Aggrement "
                      className="textfield_organisation_deatils1"
                      value={formData.PriceAgreement}
                      onChange={handleChange}
                      name="PriceAgreement"
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Parent Account</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Parent Account"
                      className="textfield_organisation_deatils1"
                      value={formData.ParentAccount}
                      onChange={handleChange}
                      name="ParentAccount"
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Bank Remittance Type</div>
                  <div className="gap-8 w-fill h-hug">
                    <select
                      value={formData.RemittanceType}
                      onChange={handleChange}
                      name="RemittanceType"
                      placeholder="Select RemittanceType"
                      className="textfield_organisation_deatils12"
                    >
                      <option value="">Select</option>
                      {projectData
                        ?.filter((project) => project.Type === "R")
                        .map((project) => (
                          <option key={project.Id} value={project.Id}>
                            {project.Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {formData.OutsourcingSubprocessor === "Synergy" &&
                    formData.RemittanceType === "" &&
                    errors && (
                      <span className="error-message">
                        Please select Remittance Type
                      </span>
                    )}
                </div>
              </div>

              <div class="flex gap-4">
                <div>
                  <div className="master-label">Account Status</div>
                  <div class="w-full h-hug  gap-0 fkex flex-col">
                    <div>
                      <select
                        onChange={handleChange}
                        value={formData.Status}
                        name="Status"
                        placeholder="Select Account Status"
                        className="textfield_organisation_deatils1"
                      >
                        <option value="">Select</option>
                        <option value="Active">Active</option>
                        <option value="Ongoing Termination">
                          Ongoing Termination{" "}
                        </option>
                        <option value="Terminated ">Terminated </option>
                      </select>
                    </div>
                    {errors.Status && errors && (
                      <span className="error-message">
                        Please select Status.
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <div className="master-label">Termination Date</div>
                  <div>
                    <input
                      type="date"
                      placeholder="30/9/2022"
                      className="textfield_organisation_deatils1"
                      value={formData.TerminationDate}
                      onChange={handleTerminationDateChange}
                      name="TerminationDate"
                    />
                  </div>
                </div>

                <div></div>
              </div>
              <div>
                <div className="master-label">Type</div>
                <div class="w-full h-hug  gap-8">
                  <input
                    type="text"
                    placeholder="Enter Type"
                    className="textfield_organisation_deatils1"
                    value={formData.Type}
                    onChange={handleChange}
                    name="Type"
                  />
                </div>
              </div>
            </form>
            <div className="flex float-right gap-1">
              <div className="modal_button_fieldmd23">
                <button className="common-btnmd">CANCEL</button>
              </div>
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={handleNextButtonClick}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <form class="flex flex-col bg-white w-full mx-auto gap-4 p-4  rounded-lg">
              <div className="masterd-title">DATES</div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Out Sourcing Start Date</div>
                  <div class="w-fill h-hug  gap-8">
                    <input
                      type="date"
                      placeholder="1-SEP-2022"
                      className="textfield_organisation_deatils12"
                      value={formData.OutsourcingStdt}
                      onChange={handleChange}
                      name="OutsourcingStdt"
                    />
                  </div>
                  {errors.OutsourcingStdt && errors && (
                    <span className="error-message">
                      Please select Out Sourcing Start Date.
                    </span>
                  )}
                </div>
                <div>
                  <div className="master-label">Out Sourcing End Date</div>
                  <div>
                    <input
                      type="date"
                      placeholder="1-SEP-2022"
                      className="textfield_organisation_deatils12"
                      value={formData.OutsourcingEddt}
                      onChange={handleChange}
                      name="OutsourcingEddt"
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Customer Reporting Date</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Customer Reporting Date"
                      className="textfield_organisation_deatils12"
                      value={formData.ReportingDt}
                      onChange={handleChange}
                      name="ReportingDt"
                    />
                    {/* {errors.ReportingDt && (
      <div className="error-message">{errors.ReportingDt}</div>
    )} */}
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <div>
                  <div className="master-label">
                    Frequency Report To Customer
                  </div>
                  <div className="gap-8 w-fill h-hug">
                    <select
                      value={formData.ReportingFrequency}
                      onChange={handleChange}
                      name="ReportingFrequency"
                      className="textfield_organisation_deatils12"
                    >
                      <option value="">
                        Select Frequency Report To Customer
                      </option>
                      {projectData
                        .filter((project) => project.Type === "C") // Filter projects with Type 'C'
                        .map((project) => (
                          <option key={project.Id} value={project.Id}>
                            {project.Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.ReportingFrequency && errors && (
                    <span className="error-message">
                      Please select ReportingFrequency.
                    </span>
                  )}
                </div>
                <div>
                  <div className="master-label">
                    Frequency Voucher Processing
                  </div>
                  <div>
                    <select
                      value={formData.VoucherFrequency}
                      onChange={handleChange}
                      name="VoucherFrequency"
                      className="textfield_organisation_deatils12"
                    >
                      <option value="">Frequency Voucher Processing</option>
                      {projectData
                        .filter((project) => project.Type === "V") // Filter projects with Type 'V'
                        .map((project) => (
                          <option key={project.Id} value={project.Id}>
                            {project.Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.VoucherFrequency && errors && (
                    <span className="error-message">
                      Please select VoucherFrequency
                    </span>
                  )}
                </div>
              </div>
              <div class="flex gap-4">
                <div className="flex gap-4">
                  <div>
                    <div className="master-label">Accounting Info Updates</div>
                    <div className="gap-8 w-fill h-hug">
                      <select
                        value={formData.AccountInfoUpdateSelection || ""}
                        onChange={handleSelectChange}
                        className="textfield_organisation_deatils12"
                      >
                        <option value="">Select Accounting Info Updates</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <div className="master-label">Current Date and Time</div>
                    <div className="gap-8 w-fill h-hug">
                      <input
                        type="text"
                        placeholder=""
                        value={currentDateTime}
                        className="textfield_organisation_deatils12"
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div></div>
                <div></div>
              </div>
            </form>
            <form class="flex flex-col  h-auto bg-white w-full my-4 mx-auto gap-4 p-4  rounded-lg">
              <div className="masterd-title">ROLES</div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Department</div>
                  <div className="gap-8 w-fill h-hug">
                    <input
                      className="textfield_organisation_deatils12"
                      value={displayValues.DepartmentName}
                      onChange={handleChange}
                      name="DepartmentName"
                      readOnly
                    />
                    {/* <select
            className="textfield_organisation_deatils12"
            value={formData.Department}
            onChange={handleChange}
            name="Department"
          >
            <option value="">Select Department</option>
            {filteredDepartments.map((dept) => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </select> */}
                  </div>
                  {errors.Department && (
                    <span className="error-message">
                      Please select Department.
                    </span>
                  )}
                </div>

                <div>
                  <div className="master-label">Department Head</div>
                  <div>
                    <input
                      id="HOD"
                      name="HODName"
                      value={displayValues.HODName}
                      onChange={handleChange}
                      className="textfield_organisation_deatils12"
                      readOnly
                    />
                    {/* <option value="">Select Department head</option>
            {filteredHODOptions.map((dept) => (
              <option key={dept.id} value={dept.id}>
                {dept.owner_EM_ID}
              </option>
            ))} */}
                  </div>
                  {errors.HOD && (
                    <span className="error-message">
                      Please Select Department head.
                    </span>
                  )}
                </div>

                <div></div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">View Account Owner</div>
                  <div class="w-fill h-hug  gap-8">
                    <input
                      type="text"
                      placeholder=" Enter View Account Owner"
                      className="textfield_organisation_deatils12"
                      value={formData.OwnerId}
                      name="OwnerId"
                      onChange={handleChange}
                    />
                  </div>
                  {errors.OwnerId && errors && (
                    <span className="error-message">{errors.OwnerId}</span>
                  )}
                </div>
                <div>
                  <div className="master-label">View Team Lead</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter View Team Lead"
                      className="textfield_organisation_deatils12"
                      value={formData.LeadId}
                      onChange={handleChange}
                      name="LeadId"
                    />
                  </div>
                  {errors.LeadId && errors && (
                    <span className="error-message">{errors.LeadId}</span>
                  )}
                </div>

                <div>
                  <div className="master-label">
                    Synergy Accounting Responsible
                  </div>
                  <div>
                    <select
                      className="textfield_organisation_deatils12"
                      value={formData.SubOwner}
                      name="SubOwner"
                      onChange={handleChange}
                    >
                      <option value="">
                        Select Synergy Accounting Responsible
                      </option>
                      {usersList?.map((data, index) => (
                        <option key={data.EM_ID + index} value={data.EM_ID}>
                          {data.EM_FirstName + ' '+ data.EM_LastName}
                          </option>
                      ))}
                    </select>
                  </div>
                  {errors.SubOwner && errors && (
                    <span className="error-message">
                      Please select Synergy Accounting Responsible.
                    </span>
                  )}
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">
                    Synergy Accounting Consultant
                  </div>
                  <div class="w-fill h-hug  gap-8">
                    <select
                      className="textfield_organisation_deatils12"
                      value={formData.SubQC}
                      name="SubQC"
                      onChange={handleChange}
                    >
                      <option value="">
                        Select Synergy Accounting Consultant
                      </option>
                      {usersList?.map((data, index) => (
                        <option key={data.EM_ID + index} value={data.EM_ID}>
                          {data.EM_FirstName + ' '+ data.EM_LastName}
                          </option>
                      ))}
                    </select>
                  </div>
                  {errors.SubQC && errors && (
                    <span className="error-message">
                      Please select Synergy Accounting Consultant
                    </span>
                  )}
                </div>

                <div>
                  <div className="master-label">Synergy Process Lead</div>
                  <div>
                    <select
                      className="textfield_organisation_deatils12"
                      value={formData.SubProcLead}
                      name="SubProcLead"
                      onChange={handleChange}
                    >
                      <option value="">Select Synergy Process Lead</option>
                      {usersList?.map((data, index) => (
                        <option key={data.EM_ID + index} value={data.EM_ID}>
                          {data.EM_FirstName + ' '+ data.EM_LastName}
                          </option>
                      ))}
                    </select>
                  </div>
                  {errors.SubProcLead && errors && (
                    <span className="error-message">
                      Please select Synergy Process Lead
                    </span>
                  )}
                </div>
                <div>
                  <div className="master-label">Synergy Department Lead</div>
                  <div>
                    <select
                      className="textfield_organisation_deatils12"
                      value={formData.SubLead}
                      name="SubLead"
                      onChange={handleChange}
                    >
                      <option value="">Synergy Department Lead</option>
                      {usersList?.map((data, index) => (
                        <option key={data.EM_ID + index} value={data.EM_ID}>
                          {data.EM_FirstName + ' '+ data.EM_LastName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.SubLead && errors && (
                    <span className="error-message">
                      Please select Synergy Department Lead
                    </span>
                  )}
                </div>
              </div>
            </form>
            <div className="flex float-right gap-1">
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={() => handleTabClick(0)}
                >
                  BACK
                </button>
              </div>

              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={handleNextButtonClick}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <form className="flex flex-col w-full gap-4 p-4 mx-auto mb-4 bg-white rounded-lg">
              <div className="masterd-title">
                TIME ESTIMATION OF VOUCHERS (MIN)
              </div>
              <div className="flex flex-wrap gap-4">
                {voucherActivities
                  ?.filter(
                    (item) =>
                      item?.AL_Type === "Vouchers" && item?.AL_Status === "1"
                  )
                  ?.map((voucher) => (
                    <div key={voucher?.AL_ID}>
                      <div className="master-label">{voucher?.AL_Name}</div>
                      <div className="gap-8 w-fill h-hug">
                        <input
                          type="text"
                          placeholder={`Enter ${voucher?.AL_Name}`}
                          className="textfield_organisation_deatils1"
                          value={
                            formData.standardActivitiesTime.find(
                              (activity) =>
                                activity.SATActivity === voucher.AL_ID
                            )?.SATTime || ""
                          }
                          name={voucher.AL_ID}
                          onChange={handleChangeVoucher}
                        />
                      </div>
                      {errors && errors[`voucher-${voucher.AL_ID}`] && (
                        <span className="error-message">
                          {errors[`voucher-${voucher.AL_ID}`]}
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </form>
            <div className="flex float-right gap-1">
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={() => handleTabClick(1)}
                >
                  BACK
                </button>
              </div>

              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={handleNextButtonClick}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}

        {activeTab === 3 && (
          <div>
            <form className="flex flex-col w-full h-auto gap-4 p-4 mx-auto mb-4 bg-white rounded-lg">
              <div className="masterd-title">
                TIME ESTIMATION OF REPORTING ACTIVITIES (MIN)
              </div>
              <div className="flex flex-wrap gap-4">
                {voucherActivities
                  ?.filter(
                    (item) =>
                      item?.AL_Type === "Reporting Activites" &&
                      item?.AL_Status === "1"
                  )
                  ?.map((voucher) => (
                    <div key={voucher?.AL_ID}>
                      <div className="master-label">{voucher?.AL_Name}</div>
                      <div className="gap-8 w-fill h-hug">
                        <input
                          type="text"
                          placeholder={`Enter ${voucher?.AL_Name}`}
                          className="textfield_organisation_deatils1"
                          value={
                            formData.standardActivitiesTime.find(
                              (activity) =>
                                activity.SATActivity === voucher.AL_ID
                            )?.SATTime || ""
                          }
                          name={voucher.AL_ID}
                          onChange={handleChangeVoucher}
                        />
                      </div>
                      {errors && errors[`voucher-${voucher.AL_ID}`] && (
                        <span className="error-message">
                          {errors[`voucher-${voucher.AL_ID}`]}
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </form>
            <div className="flex float-right gap-1">
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={() => handleTabClick(2)}
                >
                  BACK
                </button>
              </div>
              <div className="modal_button_fieldmd23">
                <button className="common_btnmd" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default AddClientTabs;
