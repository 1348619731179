import { useState, useEffect, useRef, useContext } from "react";
import "../Styles/Modal.css";
import "../Styles/SignUpPage.css";
import { Briefcase, User, UsersThree } from "@phosphor-icons/react";
import "../Styles/CustomerTeam1.css";
import "../Styles/ContentNew.css";
import {
  CaretDown,
  DotsThreeVertical,
  Eye,
  PencilSimpleLine,
  PlusCircle,
  Trash,
  XCircle,
} from "@phosphor-icons/react";
import BasicInfo from "../components/BasicInfo";
import Roles from "../components/Roles";
import { useNavigate } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import Organisation from "../components/Organisation";
import {
  createSubTeam,
  deleteSubTeam,
  deleteTeam,
  editSubTeam,
  editTeam,
} from "../utils/Team";
import { UserContext } from "../context/UserContext";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Checkbox, CircularProgress } from "@mui/material";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "../components/ToastComponent";
import { CaretUp, DotsThreeOutlineVertical } from "phosphor-react";

function CustomerTeam1(props) {
  // console.log(props);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [isModalOpen6, setIsModalOpen6] = useState(false);
  const [isModalOpen7, setIsModalOpen7] = useState(false);
  const [subTeamName, setSubTeamName] = useState("");
  const [changedTeamName, setChangedTeamName] = useState();
  const [actualTeamName, setActualTeamName] = useState();
  const [changedSubTeamName, setChangedSubTeamName] = useState();
  const [actualSubTeamName, setActualSubTeamName] = useState();
  const [selectedSubTeam, setSelectedSubTeam] = useState();
  const { fetchTeams } = useContext(UserContext);
  const { token } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { teamsData, setTeamsData, teamNames, setTeamNames } =
    useContext(UserContext);
  const toastRef = useRef();
  const { handleAuthExpiry, logout } = useContext(AuthContext);

  // //console.log(teamNames, teamsData)

  const [roles, setRoles] = useState();
  const [designation, setDesignation] = useState();
  const [department, setDepartment] = useState();

  const fetchRoles = async () => {
    try {
      const response = await fetch(`${userApiUrl}/GetRole?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        logout();
        return false;
      }
      ////console.log(data);
      setRoles(data);
    } catch (error) {
      //console.error('Error fetching data:', error);
    }
  };
  const fetchDepartments = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        logout();
        return false;
      }
      ////console.log(data);
      setDepartment(data);
    } catch (error) {
      //console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchRoles();
      fetchDepartments();
    }
  }, []);

  const openModal2 = () => {
    handleClose2();
    setIsModalOpen2(true);
  };
  const openModal3 = (name) => {
    handleClose2();
    setChangedTeamName(name);
    setActualTeamName(name);

    setIsModalOpen3(true);
  };
  const openModal4 = () => {
    handleClose2();
    setIsModalOpen4(true);
  };
  const openModal5 = (subData) => {
    ////console.log(subData);
    setIsModalOpen5(true);
    setActualSubTeamName(subData[0]?.name);
    setChangedSubTeamName(subData[0]?.name);
    setSelectedSubTeam(subData[0].id);
  };
  const openModal6 = (subData) => {
    ////console.log(subData);
    setIsModalOpen6(true);
    setChangedSubTeamName(subData[0]?.name);
    setSelectedSubTeam(subData[0].id);
  };
  const closeModal = () => {
    setIsModalOpen2(false);
  };
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  const closeModal5 = () => {
    setIsModalOpen5(false);
  };
  const closeModal6 = () => {
    setIsModalOpen6(false);
  };
  const closeModalUser = () => {
    setIsModalOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // const [activeTab, setActiveTab] = useState(0);

  // const handleTabClick = (index) => {
  //   setActiveTab(index);
  // };
  // const handleNextButtonClick = () => {
  //   const nextTab = activeTab + 1;
  //   setActiveTab(nextTab >= 0 && nextTab <= 2 ? nextTab : 0);
  // };

  const handleViewClick = (emp) => {
    window.scrollTo(0, 0);
    navigate(`/UserSetUpDetial/${emp}`);
  };

  let subteams = {};
  // ////console.log(props.ax);
  ////console.log(props);
  subteams = props?.data?.reduce((acc, team) => {
    const id = team.id;
    if (!acc[id]) {
      acc[id] = [];
    }
    acc[id].push(team);
    return acc;
  }, {});
  // ////console.log(subteams);

  const filtered = subteams ? Object.values(subteams) : [];
  ////console.log(filtered);
  // ////console.log(props);

  const [menuState, setMenuState] = useState({});

  const handleClick = (event, index) => {
    setMenuState({
      ...menuState,
      [index]: {
        anchorEl: event.currentTarget,
        open: true,
      },
    });
  };

  const handleClose = (index) => {
    setMenuState({
      ...menuState,
      [index]: {
        anchorEl: null,
        open: false,
      },
    });
  };

  const handleEditSubteam = (data) => {
    setError(false);
    openModal5(data);
  };
  const handleDeleteSubteam = (data) => {
    openModal6(data);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  let teams = [];

  if (props && props.data) {
    teams = [
      {
        id: "allSubTeams",
        name: "All Sub Teams",
        status: true,
        superTeamId: "",
        Type: "S",
      },
      ...props.data.filter((team) => team?.status === true),
    ];
  } else {
  }
  ////console.log(teams);
  const [teamCheckStatus, setTeamCheckStatus] = useState(
    teams.reduce((status, team) => ({ ...status, [team.id]: false }), {})
  );
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleMenuOpen = (event) => {
    setIsOpen(!isOpen);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleTeamCheckToggle = (teamId) => {
    ////console.log(teamCheckStatus)
    setTeamCheckStatus((prevState) => ({
      ...prevState,
      [teamId]: !prevState[teamId],
    }));
  };

  function getFirstRoleId(DRM_ID) {
    if (DRM_ID?.indexOf(",") !== -1) {
      // Split the DRM_ID by commas and take the first part
      return DRM_ID?.split(",")[0];
    } else {
      // If there are no commas, DRM_ID is a single role ID
      return DRM_ID;
    }
  }

  function exportToExcel(data, fileName) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the array of objects to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate buffer
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Create a Blob object
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Create an anchor element and trigger the download
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${fileName}.xlsx`;
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  }

  // const xldata = [
  //   { name: 'John', email: 'john@example.com', age: 28 },
  //   { name: 'Jane', email: 'jane@example.com', age: 34 },
  //   { name: 'Doe', email: 'doe@example.com', age: 23 },
  // ];

  const handleDownload = () => {
    let selected = [];
    ////console.log(props);
    ////console.log(teamCheckStatus);
    ////console.log('filtered' , '333', filtered)
    // exportToExcel(xldata, 'UserData');
    // handleMenuClose()
    let empDatatoExport = JSON.parse(JSON.stringify(props.employeeData));
    if (teamCheckStatus?.allSubTeams === true) {
      empDatatoExport?.map((emp) => {
        let currentTeam = parseFloat(emp.STM_Team) === props.teamId;
        if (currentTeam) {
          selected.push(emp);
        }
      });
      ////console.log(selected)
    } else {
      empDatatoExport?.forEach((emp) => {
        // Check if the STM_SubTeam value exists in teamCheckStatus and is true
        if (teamCheckStatus[emp?.STM_Subteam?.toString()] === true) {
          selected.push(emp);
        }
      });
    }
    ////console.log('filtered' , '345', filtered)
    if (selected?.length <= 0) {
      toastRef.current.showToast(
        "Please Select a valid Subteam with Users",
        "error"
      );
      return;
    }
    ////console.log(selected);
    let selectedExportables = selected?.map((user) => {
      ////console.log(user)
      // ////console.log(roles)
      ////console.log(department)
      ////console.log(teams);
      // user.EM_FirstName ='Anand';
      

      user.EM_Designation = roles?.find(
        (role) => role?.Id === parseFloat(user?.EM_Designation)
      )?.Name;
      
      user.STM_DM_Head = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_DM_Head
      )?.EM_FirstName;
      user.STM_PL = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_PL
      )?.EM_FirstName;
      user.STM_QC = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_QC
      )?.EM_FirstName;
      user.STM_Manager = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_Manager
      )?.EM_FirstName;
      user.STM_Team = teamNames?.find(
        (team) => team?.id == parseFloat(user?.STM_Team)
      )?.name;
      user.STM_Subteam = teamNames?.find(
        (team) => team?.id == parseFloat(user?.STM_Subteam)
      )?.name;
      user.UM_Firstlogin = null;
      user.EM_ID = null;


      return user;
    });
    ////console.log('filtered' , '376', filtered)

    // //console.log(selectedExportables);
    if (selected?.length <= 0) {
      toastRef.current.showToast(
        "Please Select a valid Subteam with Users",
        "error"
      );
      return;
    }
    exportToExcel(selectedExportables, "UserData");
    handleMenuClose();
  };

  return (
    <>
      <ToastComponent ref={toastRef} timeout={4000} />

      <div className="mainT1">
        <div className="T-1">
          <div className="Cont-T1">
            <div className="T-01">{props?.teamName}</div>

            <div className="flex ">
              <div className="T-11">
                <Button
                  aria-controls="export-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  style={{ color: "#3d025f", gap: "6px" }}
                  ref={buttonRef}
                >
                  Export Files
                  {isOpen ? (
                    <CaretUp
                      size={16}
                      weight="fill"
                      color="#3d025f"
                      className="icon-select-weekly"
                    />
                  ) : (
                    <CaretDown
                      size={16}
                      weight="fill"
                      color="#3d025f"
                      className="icon-select-weekly"
                    />
                  )}
                </Button>
                <Menu
                  id="export-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  {teams.map((team) => (
                    <MenuItem
                      key={team.id}
                      onClick={() => handleTeamCheckToggle(team.id)}
                    >
                      <Checkbox
                        checked={teamCheckStatus[team.id]}
                        style={{ color: "purple" }}
                      />
                      {team.name}
                    </MenuItem>
                  ))}
                  <MenuItem>
                    <Button
                      style={{
                        backgroundColor: "#3d025f",
                        color: "white",
                        width: "100%",
                      }}
                      onClick={handleDownload}
                    >
                      EXPORT FILES
                    </Button>
                  </MenuItem>
                </Menu>
              </div>

              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick2}
                >
                  <DotsThreeOutlineVertical
                    size={20}
                    color="#061347"
                    weight="fill"
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  style={{ boxShadow: "none" }}
                >
                  <MenuItem
                    onClick={() => {
                      openModal3(props.teamName);
                      setError(false);
                    }}
                  >
                    <ListItemIcon>
                      <PencilSimpleLine
                        size={20}
                        color="#030435"
                        weight="fill"
                      />
                    </ListItemIcon>
                    <Typography variant="inherit">Edit Team</Typography>
                  </MenuItem>
                  <MenuItem onClick={openModal4}>
                    <ListItemIcon>
                      <Trash size={20} color="#030435" weight="fill" />{" "}
                    </ListItemIcon>
                    <Typography variant="inherit">Delete Team</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setError(false);
                      openModal2();
                    }}
                  >
                    <ListItemIcon>
                      <PlusCircle size={20} color="#030435" weight="fill" />{" "}
                    </ListItemIcon>
                    <Typography variant="inherit">Add SubTeam</Typography>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          {filtered
            ?.filter((subs) => subs[0]?.status)
            .map((sub, index) => (
              <div className="S-00" key={sub[0].id}>
                <div className="S-1">
                  <div className="S-01">{sub[0]?.name}</div>

                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={
                        menuState[index]?.open ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        menuState[index]?.open ? "true" : undefined
                      }
                      onClick={(event) => handleClick(event, index)}
                    >
                      {/* <DotsThreeVertical
                      color="#061347"
                      size={20}
                      weight=""
                    /> */}
                      <DotsThreeOutlineVertical
                        size={20}
                        color="#061347"
                        weight="fill"
                      />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={menuState[index]?.anchorEl}
                      open={menuState[index]?.open}
                      onClose={() => handleClose(index)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      style={{ boxShadow: "none" }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose(index);
                          handleEditSubteam(sub);
                        }}
                      >
                        <ListItemIcon>
                          <PencilSimpleLine
                            size={20}
                            color="#030435"
                            weight="fill"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">Edit Sub Team</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose(index);
                          handleDeleteSubteam(sub);
                        }}
                      >
                        <ListItemIcon>
                          <Trash size={20} color="#030435" weight="fill" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          Delete Sub Team
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose(index);
                          handleViewClick("new");
                        }}
                      >
                        <ListItemIcon>
                          <PlusCircle size={20} color="#030435" weight="fill" />
                        </ListItemIcon>
                        <Typography variant="inherit">Add User</Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div className="Content-design">
                  <div className="form-Content">
                    {sub?.map((employee, index) => {
                      // ////console.log(employee);
                      // ////console.log("sub", sub);
                      // ////console.log(parseFloat(props?.employeeData[1].STM_Subteam), employee.Id)
                      const filteredEmployeeData = props?.employeeData?.filter(
                        (emp) =>
                          parseFloat(emp.STM_Subteam) == parseFloat(employee.id)
                      );
                      ////console.log(filteredEmployeeData);
                      let abc = roles?.find(
                        (role) =>
                          role.ID ==
                          parseFloat(filteredEmployeeData?.[0]?.DRM_ID)
                      )?.name;
                      // ////console.log(roles?.find(role=>))
                      // ////console.log(index, filteredEmployeeData);
                      return (
                        <>
                          {filteredEmployeeData?.map((filteredEmp, idx) => (
                            <div className="Content-main" style={{background:`${(!filteredEmp?.EM_TerminationDate || new Date(filteredEmp?.EM_TerminationDate) > new Date())?'white':'#cbcbcb'}`
                            }}>
                              <div key={idx} className="Content-m1">
                                <div className="Content-m2">
                                  <div className="Content-label">
                                    Employee ID
                                  </div>
                                  <div className="Content-imp1">
                                    {filteredEmp?.EM_EmpID}
                                  </div>
                                </div>
                                <div className="Content-m2">
                                  <div className="Content-label">
                                    Employee Name
                                  </div>
                                  <div className="Content-imp">
                                    {filteredEmp.EM_FirstName+ ' '+ filteredEmp.EM_LastName}
                                  </div>
                                </div>
                                <div className="Content-m2">
                                  <div className="Content-label">Email ID</div>
                                  <div className="Content-imp">
                                    {filteredEmp?.EM_EmailId}
                                  </div>
                                </div>
                                <div
                                  className="Content-m2"
                                  // style={{
                                  //   width: "170px",
                                  //   paddingLeft: "30px",
                                  // }}
                                >
                                  <div className="Content-label">Role</div>
                                  <div className="Content-imp">
                                    {filteredEmp?.DR_Name}
                                  </div>
                                </div>
                                <div className="Content-m2">
                                  <div className="Content-label">
                                    Designation
                                  </div>
                                  <div className="Content-imp">
                                    {
                                      roles?.filter(
                                        (role) =>
                                          role.Id ==
                                          parseFloat(filteredEmp.EM_Designation)
                                      )?.[0]?.Name
                                    }
                                  </div>
                                </div>
                                <button
                                  className="Content-button"
                                  onClick={() => {
                                    handleViewClick(filteredEmp?.EM_ID);
                                  }}
                                >
                                  <div>
                                    <Eye
                                      size={16}
                                      color="#3d025f"
                                      weight="fill"
                                    />
                                  </div>
                                  <div className="Content-last">VIEW</div>
                                </button>
                              </div>
                            </div>
                          ))}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}

          {isModalOpen2 && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="modalLayout">
                <div className="modalLayout_contact">
                  <div className="modalLayoutMain_contact">
                    <div className="modalStyling1">
                      <div className="flex justify-between">
                        <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                          {" "}
                          ADD NEW SUB TEAM
                        </div>
                        <XCircle
                          onClick={closeModal}
                          size={24}
                          weight="fill"
                          className="text-rgba-243-237-255"
                        />
                      </div>
                      <div className="textfield_container_lookup">
                        <div className="textfile_field_lookup">
                          <div className="Roles_row__label">
                            Create New Subteam
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Name"
                              className="textfield_Modal"
                              value={subTeamName}
                              onChange={(e) => {
                                setSubTeamName(e.target.value);
                              }}
                            />
                            {error && !subTeamName && (
                              <p style={{ color: "red" }}>
                                Sub Team Name is Required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="modal_button_field122">
                          {!loading && (
                            <button
                              className="common_btn12N"
                              onClick={async () => {
                                setLoading(true);
                                if (!subTeamName) {
                                  setError(true);
                                  setLoading(false);
                                  return;
                                }
                                // saveActivity(name, code);
                                const response = await createSubTeam(
                                  props.teamId,
                                  subTeamName,
                                  token
                                );
                                setLoading(false);
                                ////console.log(response)
                                if (response === "Unauthorised") {
                                  toastRef.current.showToast(
                                    "Session Timed out. Please Login",
                                    "error"
                                  );
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                  navigate("/");
                                  return;
                                }
                                if (!response) {
                                  toastRef.current.showToast(
                                    "Could not create Subteam. Possible error: Subteam with the same name already exists",
                                    "error"
                                  );
                                  setLoading(false);
                                  return;
                                }

                                if (response) {
                                  toastRef.current.showToast(
                                    "Subteam Created Successfully!",
                                    "success"
                                  );
                                  fetchTeams(token);
                                  setSubTeamName("");
                                } else {
                                  toastRef.current.showToast(
                                    "Something went wrong. Please try again",
                                    "error"
                                  );
                                }
                                setError(false);
                                closeModal();
                              }}
                            >
                              SAVE
                            </button>
                          )}
                          {loading && (
                            <CircularProgress style={{ color: "purple" }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isModalOpen3 && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="modalLayout">
                <div className="modalLayout_contact">
                  <div className="modalLayoutMain_contact">
                    <div className="modalStyling1">
                      <div className="flex justify-between">
                        <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                          {" "}
                          Edit Team
                        </div>
                        <XCircle
                          onClick={closeModal3}
                          size={24}
                          weight="fill"
                          className="text-rgba-243-237-255"
                        />
                      </div>
                      <div className="textfield_container_lookup">
                        <div className="textfile_field_lookup">
                          <div className="Roles_row__label">Team Name</div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Name"
                              className="textfield_Modal"
                              value={changedTeamName}
                              onChange={(e) => {
                                setChangedTeamName(e.target.value);
                              }}
                            />
                            {error && !changedTeamName && (
                              <p style={{ color: "red" }}>
                                Team Name is Required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="modal_button_field122">
                          {!loading ? (
                            <button
                              className="common_btn12N"
                              onClick={async () => {
                                // saveActivity(name, code);
                                setLoading(true);
                                const teamAvailable = teamNames?.find(
                                  (team) => team.name == changedTeamName
                                );
                                ////console.log(empAvailable)
                                if (
                                  teamAvailable &&
                                  actualTeamName !== changedTeamName
                                ) {
                                  ////console.log(empAvailable)

                                  toastRef.current.showToast(
                                    "A Team with the same name already exists",
                                    "error"
                                  );
                                  setLoading(false);
                                  return;
                                }
                                if (!changedTeamName) {
                                  setError(true);
                                  setLoading(false);

                                  return;
                                }
                                const response = await editTeam(
                                  props.teamId,
                                  changedTeamName,
                                  token
                                );
                                if (response === "Unauthorised") {
                                  toastRef.current.showToast(
                                    "Session Timed out. Please Login",
                                    "error"
                                  );
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                  navigate("/");
                                  return;
                                }
                                if (response) {
                                  fetchTeams(token);
                                  toastRef.current.showToast(
                                    "Team Edited successfully!",
                                    "success"
                                  );
                                  setChangedTeamName("");
                                } else {
                                  toastRef.current.showToast(
                                    "Something went wrong. Please try again",
                                    "error"
                                  );
                                }
                                setLoading(false);
                                setError(false);
                                closeModal3();
                              }}
                            >
                              SAVE
                            </button>
                          ) : (
                            <CircularProgress style={{ color: "purple" }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isModalOpen4 && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="modalLayout">
                <div className="modalLayout_contact">
                  <div className="modalLayoutMain_contact">
                    <div className="modalStyling1">
                      <div className="flex justify-between">
                        <div className="Roles_row__label-delete">
                          Delete Team
                        </div>

                        {/* <XCircle
                        onClick={closeModal4}
                        size={24}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      /> */}
                      </div>

                      <div className="textfield_container_lookup">
                        <div className="warning-message">
                          Are you sure you want to Delete this Team?
                        </div>

                        <div className="modal_button_field122">
                          <button
                            className="common_btn-teams-design"
                            onClick={() => {
                              closeModal4();
                              setLoading(false);
                            }}
                          >
                            Cancel
                          </button>
                          {!loading ? (
                            <button
                              className="common_btn12N"
                              style={{ marginRight: "24px" }}
                              onClick={async () => {
                                // ////console.log(activeTab)

                                setLoading(true);

                                if (
                                  props?.data?.filter((item) => item?.status)
                                    .length > 0
                                ) {
                                  toastRef.current.showToast(
                                    "This Team cannot be deleted because It Contains Active Subteams",
                                    "error"
                                  );
                                  setLoading(false);

                                  return;
                                }
                                const response = await deleteTeam(
                                  props.teamId,
                                  props.teamName,
                                  token
                                );
                                setLoading(false);
                                if (response === "Unauthorised") {
                                  toastRef.current.showToast(
                                    "Session Timed out. Please Login",
                                    "error"
                                  );
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                  navigate("/");
                                  return;
                                }
                                if (response) {
                                  fetchTeams(token);
                                  // ////console.log(activeTab)
                                  props.setActiveTab(
                                    teamsData[0]?.find(
                                      (team) => team?.status === true
                                    )?.id
                                  );
                                  setLoading(false);
                                  toastRef.current.showToast(
                                    "Team Deleted successfully!",
                                    "success"
                                  );
                                } else {
                                  toastRef.current.showToast(
                                    "Something went wrong. Please try again",
                                    "error"
                                  );
                                }
                                closeModal4();
                              }}
                            >
                              YES
                            </button>
                          ) : (
                            <CircularProgress style={{ color: "purple" }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isModalOpen5 && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="modalLayout">
                <div className="modalLayout_contact">
                  <div className="modalLayoutMain_contact">
                    <div className="modalStyling1">
                      <div className="flex justify-between">
                        <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                          {" "}
                        </div>
                        <div className="space-user-setup">
                          <div className="Roles_row__label-sub">
                            Sub Team Name
                          </div>
                          <XCircle
                            onClick={closeModal5}
                            size={24}
                            weight="fill"
                            className="text-rgba-243-237-255"
                          />
                        </div>
                      </div>
                      <div className="textfield_container_lookup">
                        {/* <div className="Roles_row__label">Sub Team Name</div> */}
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            className="textfield_Modal"
                            value={changedSubTeamName}
                            onChange={(e) => {
                              setChangedSubTeamName(e.target.value);
                            }}
                          />
                          {error && !changedSubTeamName && (
                            <p style={{ color: "red" }}>
                              Sub team Name is Required
                            </p>
                          )}
                        </div>
                        <div className="modal_button_field122">
                          {!loading ? (
                            <button
                              className="common_btn12N"
                              style={{ marginRight: "24px" }}
                              onClick={async () => {
                                setLoading(true);
                                const teamAvailable = teamNames?.find(
                                  (team) => team.name == changedSubTeamName
                                );
                                ////console.log(empAvailable)
                                if (
                                  teamAvailable &&
                                  actualSubTeamName !== changedSubTeamName
                                ) {
                                  ////console.log(empAvailable)

                                  toastRef.current.showToast(
                                    "A Subteam with the same name already exists",
                                    "error"
                                  );
                                  setLoading(false);
                                  return;
                                }
                                if (!changedSubTeamName) {
                                  setError(true);
                                  setLoading(false);
                                  return;
                                }
                                const response = await editSubTeam(
                                  selectedSubTeam,
                                  props.teamId,
                                  changedSubTeamName,
                                  token
                                );
                                setLoading(false);
                                if (response === "Unauthorised") {
                                  toastRef.current.showToast(
                                    "Session Timed out. Please Login",
                                    "error"
                                  );
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                  navigate("/");
                                  return;
                                }

                                if (response) {
                                  fetchTeams(token);
                                  setLoading(false);
                                  toastRef.current.showToast(
                                    "SubTeam Edited successfully!",
                                    "success"
                                  );
                                } else {
                                  toastRef.current.showToast(
                                    "Something went wrong. Please try again",
                                    "error"
                                  );
                                }
                                setError(false);
                                setSelectedSubTeam("");
                                setChangedSubTeamName("");
                                closeModal5();
                                setLoading(false);
                              }}
                            >
                              Save
                            </button>
                          ) : (
                            <CircularProgress
                              style={{ color: "purple", margin: "0 auto" }}
                            />
                          )}
                          <button
                            className="common_btn12N"
                            onClick={() => {
                              setSelectedSubTeam("");
                              setChangedSubTeamName("");
                              closeModal5();
                              setLoading(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isModalOpen6 && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="modalLayout">
                <div className="modalLayout_contact">
                  <div className="modalLayoutMain_contact">
                    <div className="modalStyling1">
                      <div className="flex justify-between">
                        <div className="design-delete">Delete Sub Team</div>

                        {/* <XCircle
                        onClick={closeModal6}
                        size={24}
                        weight="fill"
                        className="text-rgba-243-237-255"
                      /> */}
                      </div>
                      <div className="textfield_container_lookup">
                        <div className="titile-delete-design">
                          Are you sure you want to Delete this Sub Team?
                        </div>

                        <div className="modal_button_field122-delete">
                          <button
                            className="common_btn12N-delete"
                            onClick={() => {
                              setSelectedSubTeam("");
                              setChangedSubTeamName("");
                              closeModal6();
                              setLoading(false);
                            }}
                          >
                            Cancel
                          </button>
                          {!loading ? (
                            <button
                              className="common_btn12N"
                              style={{ marginRight: "24px" }}
                              onClick={async () => {
                                setLoading(true);

                                const empAvailable = props?.employeeData?.find(
                                  (emp) =>
                                    parseFloat(emp.STM_Subteam) ==
                                    parseFloat(selectedSubTeam)
                                );
                                ////console.log(empAvailable)
                                if (empAvailable) {
                                  ////console.log(empAvailable)

                                  toastRef.current.showToast(
                                    "This SubTeam cannot be deleted because It Contains Active Users",
                                    "error"
                                  );
                                  setLoading(false);
                                  return;
                                }
                                const response = await deleteSubTeam(
                                  selectedSubTeam,
                                  props.teamId,
                                  changedSubTeamName,
                                  token
                                );

                                setLoading(false);
                                if (response === "Unauthorised") {
                                  toastRef.current.showToast(
                                    "Session Timed out. Please Login",
                                    "error"
                                  );
                                  await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                  );
                                  navigate("/");
                                  return;
                                }
                                if (response) {
                                  fetchTeams(token);
                                  setLoading(false);
                                  toastRef.current.showToast(
                                    "SubTeam Deleted successfully!",
                                    "success"
                                  );
                                } else {
                                  toastRef.current.showToast(
                                    "Something went wrong. Please try again",
                                    "error"
                                  );
                                }

                                setSelectedSubTeam("");
                                setChangedSubTeamName("");
                                closeModal6();
                                setLoading(false);
                              }}
                            >
                              YES
                            </button>
                          ) : (
                            <CircularProgress style={{ color: "purple" }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default CustomerTeam1;
