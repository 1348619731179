import React, { useContext, useEffect, useRef } from "react";
// import NavChild from '../components/NavChild';
import "../Styles/VoucherEntry.css";
import { useState } from "react";
import HorizontalDateCalender from "./HorizontalDateCalender";
import { CaretDown, CaretUp, MagnifyingGlass } from "@phosphor-icons/react";
import NavSub from "../components/NavSub";
import VoucherEntrynewMUI from "../components/VoucherEntrynewMUI";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress } from "@mui/material";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import {
  FunnelSimple
} from "@phosphor-icons/react";
import ToastComponent from "../components/ToastComponent";
function ClientListDropdown({
  showOptions,
  setShowOptions,
  selectedOption,
  clients,
  handleOptionClick,
}) {
  console.log(selectedOption)
  return (
    <div className="dropdown-container-str">
      <div
        className="Title-STR-main2"
        onClick={() => setShowOptions(!showOptions)}
      >
        {selectedOption?.Name || " Customer Name "}
        <FontAwesomeIcon
          className="str-caret-icon"
          icon={showOptions ? faCaretUp : faCaretDown}
        />
      </div>
      {showOptions && (
        <div className="dropdown-proper-alligned">
          {clients?.map((option) => (
            <div
              key={option.Id}
              className="dropdown-proper-alligned-options"
              onClick={() => handleOptionClick(option)}
            >
              {option?.Name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function VoucherEntry() {
  const todaysDate = new Date();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [clients, setClients] = useState([]);
  const [selectedYear, setSelectedYear] = useState(todaysDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    todaysDate.toLocaleString("default", { month: "long" })
  );
  const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());
  const { userData } = useContext(AuthContext);
  const [selectedMasterData, setSelectedMasterData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const { token, logout } = useContext(AuthContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);

  const [selectedVouchersOnDate, setSelectedVouchersOnDate] = useState([]);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };
  const handleCustomersearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const dropdownsearchRef = useRef(null);
  const debounceTimeout = useRef(null);

  const handleClicksearchOutside = (event) => {
    if (
      dropdownsearchRef.current &&
      !dropdownsearchRef.current.contains(event.target)
    ) {
      setIsSearchOpen(false);
      // setIsSecondaryDropdownOpen(false);
    }
  };
  const [query, setQuery] = useState("");
  const handleClearAll = () => {
    setQuery("");
    setSelectedOption(null)
    setSelectedSuggestions([]);
  };
  const [searchLoading, setSearchLoading] = useState(false);

  const fetchSuggestionByText = async () => {
    if(!token){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        // alert("Error");

        setLoading(false);

        return;
      }

      const data = await response.json();

      ////console.log(data);

      setSuggestions(data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);

      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);

      setLoading(false);
    }

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);

  const toastRef = useRef();
  const fetchActivityList = async () => {
    if(!token){
      return;
    }
    try {
      const response = await fetch(`${clientApiUrl}/GetAllActivity`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setActivityList(
          data?.filter(
            (item) => item?.AL_Type === "Vouchers" && item?.AL_Status === "1"
          )
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  const [page, setPage] = useState(1);
  const fetchCustomer = async () => {
    if(!userData?.[0]?.EM_ID){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${page}&ItemsPerPage=20&SubOwnerEMID=${userData?.[0]?.EM_ID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();

        if (data?.length) {
          setClients([...clients, ...data]);
          setPage(page + 1);
        } else {
          return;
        }
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [userData, page]);

  useEffect(() => {
    fetchActivityList();
  }, [token]);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  // const teamData = [
  //   {
  //     viewForceCode: "0201 voucher",
  //     AccountingSystem: "172 stavenger triple",
  //     AccountStatus: "Active",
  //   },
  // ];

  function getDateInReqdFormat(day, month, year) {
    const monthNames = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    const monthNumber = monthNames[month];

    const date = new Date(year, monthNumber, day);

    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const formattedDay = String(date.getDate()).padStart(2, "0");
    const formattedYear = date.getFullYear();

    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
  }

  const fetchSelectedMasterData = async () => {
    if(!selectedOption?.Id){
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?id=${selectedOption?.Id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setSelectedMasterData(data);
      }
    } catch (e) {
      //console.log(e);
    }
  };
  const [loading, setLoading] = useState(false);
  const fetchRelatedVouchersOnDate = async () => {
    // if (!selectedOption?.SubOwner) {
    //   return;
    // }
    try {
      setLoading(true);
      const response = await fetch(
        `${taskApiUrl}/GetAllTask?createdDate=${getDateInReqdFormat(
          selectedDay,
          selectedMonth,
          selectedYear
        )}&clientId=${
          selectedOption?.Id
        }&ActivityType=Vouchers`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setSelectedVouchersOnDate(data);
      }
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    //console.log(selectedDay, selectedMonth, selectedYear);
    fetchRelatedVouchersOnDate();
  }, [selectedDay, selectedMonth, selectedYear, selectedOption]);

  useEffect(() => {
    fetchSelectedMasterData();
  }, [selectedOption]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClicksearchOutside);

    return () => {
      document.removeEventListener("mousedown", handleClicksearchOutside);
    };
  }, []);
  return (
    <div className="VoucherEntry">
      <NavSub />
      <div className="Voucher-acc">
        {/* <div className='Voucher-cust-name'>Customer Name   <CaretDown size={18} className='icon-VE' color="#3d025f" weight="fill" /></div> */}
        {/* <ClientListDropdown
          selectedOption={selectedOption}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          clients={clients}
          handleOptionClick={handleOptionClick}
        /> */}
         <div className="header_title">
         {selectedOption?.Name || " Customer Name "}
         {/* <FunnelSimple onClick={handleCustomerMenuClick} /> */}
              <FunnelSimple
                className="icon-search-dropdown"
                onClick={handleCustomersearchClick}
                weight="bold"
                size={20}
                color="#061347"
              />
              {isSearchOpen && (
                <div ref={dropdownsearchRef} className="search-drop-design">
                  <div className="search-drop-page">
                    <div className="main-suggestion">
                      <div className="input-suggestion">
                        <div className="icon-search-phospher">
                          <MagnifyingGlass
                            size={20}
                            color="#ada4a4"
                            weight="fill"
                          />{" "}
                        </div>

                        <input
                          type="text"
                          value={query}
                          className="input-suggestion-search"
                          onChange={(e) => setQuery(e.target.value)}
                          placeholder="Start typing..."
                        />

                        <button
                          className="clear-title"
                          onClick={handleClearAll}
                        >
                          Clear All
                        </button>
                      </div>

                      {/* <button style={{ display: 'inline' }} onClick={handleClearAll}>Clear All</button> */}

                      {searchLoading ? (
                        <p className="suggestion-title">
                          <CircularProgress
                            width={30}
                            style={{ color: "purple" }}
                          />
                        </p>
                      ) : (
                        <div style={{ maxHeight: "500px" }} tabIndex={1}>
                          {suggestions.map((suggestion) => (
  <div key={suggestion.Id} className="suggestion-list-li">
    <input
      type="radio"
      name="suggestion" // Ensures all radios are linked together
      className="check-searchbox"
      checked={selectedOption?.Id === suggestion.Id}
      onChange={() => setSelectedOption(suggestion)}
    />
    <label className="li-content">
      {suggestion.Name}
    </label>
  </div>
))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

        <div className="Voucher-status">
          {selectedMasterData?.map((item, index) => (
            <div className="Acc-detail">
              <div
                className="Voucher-props"
                style={{
                  borderRight: "1px solid rgba(80, 20, 90, 0.50)",
                  paddingRight: "16px",
                }}
              >
                View Force code : {"0201 Vouchers"}
              </div>
              <div className="Voucher-props" style={{ padding: "0px 16px" }}>
                AccountingSystem : {item.ERPSystem ? item.ERPSystem : "N/A"}
              </div>
              <div
                className="Voucher-props"
                style={{
                  borderLeft: "1px solid rgba(80, 20, 90, 0.50)",
                  paddingLeft: "16px",
                }}
              >
                Account Status : {item.Status}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ------------------- Information ---------------------------------------------- */}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress style={{ color: "purple" }} width={40} />
        </div>
      )}
      {!loading && (
        <div className="ve-info-main">
          <div className="ve-info-main-bg"  onClick={toggleVisibility}>
            <div className="ve-info-main-head">
              <div className="Voucher-cust-name">Information</div>
              {isVisible ? (
                <CaretUp
                  size={18}
                  className="icon-VE"
                  color="#3d025f"
                  weight="fill"
                  // onClick={toggleVisibility}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <CaretDown
                  size={18}
                  className="icon-VE"
                  color="#3d025f"
                  weight="fill"
                  // onClick={toggleVisibility}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {isVisible && (
              <div className="vocher-entry-info-main">
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Header Information
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      Client Name | Supplier Name | Invoice Name | Invoice Date
                      & Due Date | KID CID | Invoice Amount & Currency
                    </div>
                  </div>
                </div>
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Subject Information
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      GL Account | VAT Code & Amount | Periodization | Project /
                      Department | Re Invoicing
                    </div>
                  </div>
                </div>
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Remittance
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      Bank Account | Payment Type | Credit Note Adjustment |
                      Attestation | Foreign Payment | Auto Pay
                    </div>
                  </div>
                </div>
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Instructions :
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      RAM | DOCN | PTNB | PTNP
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {selectedMasterData?.length > 0 && (
            <div>
              <HorizontalDateCalender
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </div>
          )}

          {selectedMasterData?.length > 0 && (
            <VoucherEntrynewMUI
              selectedOption={selectedOption}
              data={selectedVouchersOnDate}
              activityList={activityList}
              selectedDay={selectedDay}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              selectedMasterData={selectedMasterData}
              fetchRelatedVouchersOnDate={fetchRelatedVouchersOnDate}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default VoucherEntry;
