import React, { useContext } from 'react'
import "../Styles/UserSetupAndRoles.css";
import HampiNavbar1 from '../components/HampiNavbar1'
// import CustomerTeam1 from './CustomerTeam1'
import HampiNavbarNew from '../components/HampiNavbarNew';
import MappedMenu from '../components/MappedMenu';
import { UserContext } from '../context/UserContext';

function UserSetupAndRoles() {
  const {usersList} = useContext(UserContext);

  return (
    <div className='Register-M'>
      <HampiNavbarNew employeeData={usersList}/>
      <HampiNavbar1  />
      {/* <MappedMenu /> */}
    </div>
  )
}

export default UserSetupAndRoles
