import React from 'react';
import NavSub from '../components/NavSub';
import WeekActivityHistory from '../components/WeekActivityHistory';
import Weeklytimesheet2History from '../components/Weeklytimesheet2History';
import Footerweek from '../components/Footerweek';
import { useNavigate } from 'react-router-dom';
import { CaretLeft } from '@phosphor-icons/react';

function HistoryDetailViewForce({ item ,setSelectedHistoryDetailData}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/Mergenew");
  };

  return (
    <>
      <NavSub />
      <div onClick={handleNavigate} style={{ margin: "1% auto", display: "flex", alignItems: "center", paddingLeft: "16px",cursor:"pointer"}}><CaretLeft weight='fill' />Back</div>
      <div className='week-page' style={{ overflowX: 'scroll', whiteSpace: 'nowrap' ,cursor:"pointer"}}>
      <>
            <WeekActivityHistory setSelectedHistoryDetailData={setSelectedHistoryDetailData} />
            <Weeklytimesheet2History setSelectedHistoryDetailData={setSelectedHistoryDetailData}  />
          </>
       
      </div>
      {/* <Footerweek /> */}
    </>
  );
}

export default HistoryDetailViewForce;
