import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import logo123 from "../Images/NewLogo.png";
import "../Styles/NavMain.css";
import { useNavigate } from "react-router-dom";
import {
  Bell,
  Plus,
  MagnifyingGlass,
  Eye,
  User,
  UsersThree,
  DotOutline,
  FileArrowUp,
  PlusCircle,
  CaretDown,
  CaretDoubleRight,
  PencilSimpleLine,
  Download,
  CaretUpDown,
} from "@phosphor-icons/react";
import CustomerMasterData from "../pages/CustomerMasterData";
import UserSetupAndRoles from "../pages/UserSetupAndRoles";
import BudgetForeCast from "../pages/BudgetForeCast";
import Mergenew from "../pages/Mergenew";
import "../Styles/Modalwindow.css";
// import Modalwindow from '../pages/Modalwindow';

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import close from "../Images/XCircle.png";
import modal_icon1 from "../Images/UserCheck.png";
import modal_icon2 from "../Images/Vector.png";
import modal_profile from "../Images/image 2.jpg";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import MappedMenu from "./MappedMenu";
import { CaretUp } from "phosphor-react";
import "../Styles/Notification.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNotifications } from "../context/NotificationContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: "400",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 2,
};
dayjs.extend(relativeTime);

function NavSub() {
  const [isPrimaryDropdownOpen, setIsPrimaryDropdownOpen] = useState(false);
  const [isSecondaryDropdownOpen, setIsSecondaryDropdownOpen] = useState(false);
  const [isthirdDropdownOpen, setIsthirdDropdownOpen] = useState(false);
  const [isbellDropdownOpen, setIsbellDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { userData, logout} = useContext(AuthContext);
  const { usersList, teamNames } = useContext(UserContext);
  const { generalNotifications, unreadNotifications, setUnreadNotifications, fetchGeneralNotifications,markNotificationsAsRead } = useContext(AuthContext);
  const handleReset = () => {
    navigate("/HampiReset");
  };
  const dropdownRef = useRef(null);
  const dropdown2Ref = useRef(null);
  const dropdown3Ref = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsPrimaryDropdownOpen(false);
      setIsSecondaryDropdownOpen(false);
      setIsbellDropdownOpen(false);
    }
  };
  const handleClick2Outside = (event) => {
    if (dropdown2Ref.current && !dropdown2Ref.current.contains(event.target)) {
      setIsbellDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick2Outside);
    return () => {
      document.removeEventListener("mousedown", handleClick2Outside);
    };
  }, []);

  const handleClick3Outside = (event) => {
    if (dropdown3Ref.current && !dropdown3Ref.current.contains(event.target)) {
      setIsthirdDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick3Outside);
    return () => {
      document.removeEventListener("mousedown", handleClick3Outside);
    };
  }, []);
  const togglethirdDropdown = () => {
    setIsthirdDropdownOpen(!isthirdDropdownOpen);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getUserNameById = (id) => {
    const user = usersList.find((user) => user.EM_ID === id);
    return user ? user.EM_FirstName : "Unknown";
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const formatRelativeTime = (date) => {
    const now = dayjs();
    const then = dayjs(date).add(5, 'hour').add(30, 'minute'); // Add 5 hours and 30 minutes
    const diffInSeconds = now.diff(then, 'seconds');
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
  
    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

const togglebellDropdown = () => {
  setIsbellDropdownOpen(!isbellDropdownOpen);

  if (!isbellDropdownOpen) {
    markNotificationsAsRead();  
  }
};


  return (
    <main className="Nav-Main">
      <nav className="flex items-center justify-between px-8 border-b shadow-lg h-22 nav-color ">
        <section className="relative flex items-center gap-6">
          <img className="w-16 h-8 " loading="lazy" src={logo123} alt="" />
   <div className="flex items-center justify-between py-6 gap-11 ">
            <div className="Customer-nav">
              <MappedMenu />
            </div>
          </div>
        </section>
        <section className="flex items-center gap-4">
        <div className="Nav-icon-Main" onClick={togglebellDropdown}>
      <div style={{ display: "flex" }}>
       
          <>
            <Bell size={18} className="bell1" color="#3d025f" weight="fill" onClick={togglebellDropdown} />
            {unreadNotifications && (   <DotOutline size={52} className="be" color="#0fdf0c" weight="fill" />  )}
          </>
      
      </div>
      {isbellDropdownOpen && (
        <div className="notifications-dropdown">
          <div className="notifications-list" ref={dropdownRef}>
            {generalNotifications.length > 0 ? (
              generalNotifications.map((notification, index) => (
                <div className={`notification-item ${notification.read ? 'read' : 'unread'}`} key={index}>
                  <p className="notification-message">
                    {getUserNameById(notification.createdby)} {notification.message}
                  </p>
                  <p className="notification-time">
                    {formatRelativeTime(notification.createdDate)}
                  </p>
                </div>
              ))
            ) : (
              <p>No notifications available</p>
            )}
          </div>
        </div>
      )}
    </div>
           <div className='flex justify-between gap-2 pl-8 items-center py-6'>
            <User size={20} color="#3d025f" weight="fill" />
            <div
              className="h-6 font-semibold cursor-pointer  text-purple-950 text-l font-poppins"
              onClick={togglethirdDropdown}
            >
              {userData?.[0]?.EM_FirstName}
            </div>
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="custom-modal"
            >
              <Box sx={style}>
                <div className="modal-head1">
                  <div className="modal-h1">PROFILE</div>
                  <img
                    src={close}
                    alt="profie-image"
                    className="close-me"
                    onClick={handleClose}
                  />
                </div>
                <div className="modal-head2">
                  <div className="profile-image">
                    <img
                      src={modal_profile}
                      alt="profie-image"
                      className="profile-pic"
                    />
                  </div>
                  <div className="modal-profile-data">
                    <div className="modal-profile-heading1">
                      {userData?.[0]?.EM_FirstName + ' '+ userData?.[0]?.EM_LastName}
                    </div>

                    <div className="modal-profile-heading2">
                    {userData?.[0]?.DR_Name || "Unknown"}

                    </div>

                    {/* {//console.log(teamNames)} */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <div className="modal-profile-heading3">
                        {" "}
                        Team :{" "}
                        {teamNames?.find(
                          (team) =>
                            team?.id?.toString() === userData?.[0]?.STM_Team
                        )?.name || "Unknown"}
                      </div>
                      <div className="modal-profile-heading3">
                        SubTeam :{" "}
                        {teamNames?.find(
                          (team) =>
                            team?.id?.toString() === userData?.[0]?.STM_Subteam
                        )?.name || "Unknown"}
                      </div>
                    </div>

                    <div className="modal-profile-part2">
                      <div className="modal-profile-heading4">
                        <img
                          src={modal_icon1}
                          alt="profie-image"
                          className="modal1_icon"
                        />
                        <div className="m-mail-data">
                          {userData?.[0]?.EM_EmpID}
                        </div>
                      </div>
                    </div>
                    <div className="modal-profile-part2">
                      <div className="modal-profile-heading5">
                        <img
                          src={modal_icon2}
                          alt="profie-image"
                          className="modal2_icon"
                        />
                        <div className="m-mail-data">
                          {userData?.[0]?.EM_EmailId}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="organization-structure-container">
                  <div className="modal-profile-2-h1">
                    ORGANISATION STRUCTURE
                  </div>

                  {userData?.[0]?.STM_Manager && (
                    <div className="modal-profile-2">
                      <div className="modal-profile-2-h2">
                        <div className="modal-profile-2-level">
                          <div className="modal-profile-2-h2-sh2">
                            {
                              usersList?.find(
                                (user) =>
                                  user?.EM_ID === userData?.[0]?.STM_Manager
                              )?.EM_FirstName
                            }
                          </div>
                          <div className="modal-profile-2-h2-sh1">Manager</div>

                          <div className="modal-desig-color">
                            {" "}
                            {usersList?.find(
                              (user) =>
                                user?.EM_ID === userData?.[0]?.STM_Manager
                            )?.DR_Name || "Unknown"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {userData?.[0]?.STM_DM_Head && (
                    <div className="modal-profile-2">
                      <div className="modal-profile-2-h2">
                        <div className="modal-profile-2-level">
                          <div className="modal-profile-2-h2-sh2">
                            {
                              usersList?.find(
                                (user) =>
                                  user?.EM_ID === userData?.[0]?.STM_DM_Head
                              )?.EM_FirstName
                            }
                          </div>
                          <div className="modal-profile-2-h2-sh1">
                            Department Head
                          </div>
                          <div className="modal-desig-color">
                            {usersList?.find(
                              (user) =>
                                user?.EM_ID === userData?.[0]?.STM_DM_Head
                            )?.DR_Name || "Unknown"}
                          </div>
                          {/* <div className="modal-profile-heading2">{usersList?.find(user => user.EM_Designation === userData?.[0]?.EM_Designation)?.DR_Name || 'Unknown'}</div> */}
                        </div>
                      </div>
                    </div>
                  )}
                  {userData?.[0]?.STM_PL && (
                    <div className="modal-profile-2">
                      <div className="modal-profile-2-h2">
                        <div className="modal-profile-2-level">
                          <div className="modal-profile-2-h2-sh2">
                            {
                              usersList?.find(
                                (user) => user?.EM_ID === userData?.[0]?.STM_PL
                              )?.EM_FirstName
                            }
                          </div>
                          <div className="modal-profile-2-h2-sh1">
                            Process Lead
                          </div>
                          <div className="modal-desig-color">
                            {usersList?.find(
                              (user) => user?.EM_ID === userData?.[0]?.STM_PL
                            )?.DR_Name || "Unknown"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {userData?.[0]?.STM_QC && (
                    <div className="modal-profile-2">
                      <div className="modal-profile-2-h2">
                        <div className="modal-profile-2-level">
                          <div className="modal-profile-2-h2-sh2">
                            {
                              usersList?.find(
                                (user) => user?.EM_ID === userData?.[0]?.STM_QC
                              )?.EM_FirstName
                            }
                          </div>
                          <div className="modal-profile-2-h2-sh1">
                            Quality Consultant
                          </div>
                          <div className="modal-desig-color">
                            {" "}
                            {usersList?.find(
                              (user) => user?.EM_ID === userData?.[0]?.STM_QC
                            )?.DR_Name || "Unknown"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Box>
            </Modal>
            {/* <button >
            <CaretDown size={16} color="#3d025f" weight="fill" onClick={togglethirdDropdown}/>
          </button>  */}

            <button onClick={togglethirdDropdown}>
              {isthirdDropdownOpen ? (
                <CaretUp size={16} color="#3d025f" weight="fill" />
              ) : (
                <CaretDown size={16} color="#3d025f" weight="fill" />
              )}
            </button>

            {isthirdDropdownOpen && (
              <div ref={dropdown3Ref} className="third-Drop">
                <ul>
                  <li
                    className="px-2 py-2 cursor-pointer hover:bg-gray-100 design-family"
                    onClick={handleOpen}
                  >
                    My Profile{" "}
                  </li>

                  <li
                    className="px-2 py-2 cursor-pointer hover:bg-gray-100 design-family"
                    onClick={handleReset}
                  >
                    Reset Password
                  </li>
                  <li
                    className="px-2 py-2 cursor-pointer hover:bg-gray-100 design-family"
                    onClick={logout}
                  >
                    Log Out{" "}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </section>
      </nav>
    </main>
  );
}
export default NavSub;
