import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [usersList, setUsersList] = useState();
  const [allUsersList, setAllUsersList] = useState();
  const [teamsData, setTeamsData] = useState([]);
  const [teamNames, setTeamNames] = useState([]);
  const { token, handleAuthExpiry, logout } = useContext(AuthContext);
  const fetchData = async () => {
    if(!token) {
      return;
      } 
    try {
      const response = await fetch(`${userApiUrl}/GetAllEmp`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        // ('Your Session has expired', 'error');
        // await new Promise(resolve => setTimeout(resolve, 2000));

        logout();
        return false;
      }
      const data = await response.json();
      fetchTeams(token);
      console.log(data);
      setAllUsersList(data);console.log(
        data
          .filter(item => !item.EM_TerminationDate || new Date(item.EM_TerminationDate) > new Date())
          .sort((a, b) => a.EM_FirstName.localeCompare(b.EM_FirstName))
      );
      
      setUsersList(
        data
          .filter(item => !item.EM_TerminationDate || new Date(item.EM_TerminationDate) > new Date())
          .sort((a, b) => a.EM_FirstName.localeCompare(b.EM_FirstName))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  // useEffect(()=>{
  //   fetchData();

  // },[token])

  const fetchTeams = async (token) => {
    if (!token) {
      return;
    }
    try {
      const response = await fetch(`${userApiUrl}/GetTeams?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      // const handleAuthError = handleAuthExpiry(response);
      // if (handleAuthError === true) {

      //   logout();
      //   return false;
      // }
      const data = await response.json();

      const groupedTeams = data?.reduce((acc, _, index) => {
        const teams = data?.filter((t) => t?.superTeamId === index);
        acc[index] = teams;
        return acc;
      }, {});

      const filtered = Object.values(groupedTeams);
      setTeamsData(filtered);
      setTeamNames(data);
      return filtered;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        usersList,
        setUsersList,
        teamsData,
        setTeamsData,
        teamNames,
        setTeamNames,
        fetchTeams,
        fetchData,
        allUsersList
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
