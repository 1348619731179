import React, { useContext, useEffect, useRef, useState } from "react";
import NavSub from "../components/NavSub";
import ToastComponent from "../components/ToastComponent";
import { clientApiUrl, taskApiUrl } from "../utils/GetUrl";
import {
  CaretLeft,
  CaretRight,
  CaretDown,
  ClockCountdown,
  Download,
  FileArrowUp,
} from "@phosphor-icons/react";
import "../Styles/ActivitywisetimereportNav.css";
import "../Styles/UserwiseTimeReportNav.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Footerweek from "../components/Footerweek";
import { UserContext } from "../context/UserContext";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Menu,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import ActivityWise2 from "../components/Activitywise2";
import { CaretUp } from "phosphor-react";

function ActivityWiseTimeReport() {
  const {
    teamsData,
    setTeamsData,
    teamNames,
    setTeamNames,
    usersList,
    fetchTeams,
  } = useContext(UserContext);
  const { token, logout } = useContext(AuthContext);
  // const [selectedDate, setSelectedDate] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [teams, setTeams] = useState(
    teamNames?.filter(
      (team) =>
        team?.superTeamId === 0 && team?.type === "T" && team?.status === true
    )
  );
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState(usersList);
  const [timeCode, setTimeCode] = useState(null);
  const [department, setDepartment] = useState(null);
  const [uniqueDepartments, setUniqueDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedIds, setSelectedIds] = useState("");
  const [selectedCostCenters, setSelectedCostCenters] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [uniqueTimeCodes, setUniqueTimeCodes] = useState([]);
  const [selectedTimeCode, setSelectedTimeCode] = useState("");
  const [selectedTimeCodeIds, setSelectedTimeCodeIds] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [processedData, setProcessedData] = useState([]);
  const [downloadFile, setDownloadFile] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(()=>{
    setSelectedEmployees(usersList)
  }, [usersList])

  useEffect(()=>{
    setTeams( teamNames?.filter(
      (team) =>
        team?.superTeamId === 0 && team?.type === "T" && team?.status === true
    )
  )
  },[teamNames])

  const currentDate = new Date();
  const currentMonthYear = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}`;

  const [selectedDate, setSelectedDate] = useState(currentMonthYear);

  const isOpen = Boolean(anchorEl);

  const handleProceed = () => {
    setChosenDate(selectedDate);
    setdatebellDropdownOpen(!isdateDropdownOpen);
  };
  const [isdateDropdownOpen, setdatebellDropdownOpen] = useState(false);

  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);

  const dropdown2Ref = useRef(null);

  const closeDateDropdown = () => {
    setdatebellDropdownOpen(false);
  };

  useEffect(() => {
    const handleClick2Outside = (event) => {
      if (
        dropdown2Ref.current &&
        !dropdown2Ref.current.contains(event.target)
      ) {
        setdatebellDropdownOpen(false);
      }
    };

    const handleScroll = () => {
      closeDateDropdown();
    };

    document.addEventListener("mousedown", handleClick2Outside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClick2Outside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDateDropdown = () => {
    setdatebellDropdownOpen(!isdateDropdownOpen);
    // setIsDropdownOpen(!isDropdownOpen);
    setIsDateDropdownOpen(!isDateDropdownOpen);
    // setIsDateDropdownOpen((prev) => !prev);
  };

  const handleChange = (event) => {
    //console.log(event.target.value);
    if (event.target.value[event.target.value?.length - 1] === undefined) {
      //console.log("mmmmmmmmmmmmmmmm");
      setSelectedTeams([]);
      return;
    }
    setSelectedTeams(event.target.value);
  };

  useEffect(() => {
    const filteredEmployees = usersList?.filter((user) =>
      selectedTeams?.includes(parseInt(user?.STM_Team))
    );
    setSelectedEmployees(filteredEmployees);
    //console.log(filteredEmployees);
  }, [selectedTeams, usersList]);

  useEffect(() => {
    //console.log(selectedTeams.length);
    if (!selectedTeams?.length) {
      setSelectedEmployees(usersList);
    }
  }, [selectedTeams]);

  useEffect(() => {
    const uniqueNames = [...new Set(department?.map((dept) => dept.name))];
    setUniqueDepartments(uniqueNames);
  }, [department]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeptChange = (event) => {
    const name = event.currentTarget.getAttribute("data-value");
    setSelectedDepartment(name);

    const ids = department
      ?.filter((dept) => dept.name === name)
      .map((dept) => dept.id)
      .join(",");
    setSelectedIds(ids);
    const costCenters = department
      ?.filter((dept) => dept.name === name)
      .map((dept) => `'${dept.costCenter}'`)  
      .join(",");
      console.log(costCenters)
    setSelectedCostCenters(costCenters);
    handleClose();
  };

  useEffect(() => {
    const uniqueCodes = [
      ...new Set(timeCode?.map((code) => code.AL_SalseforceCode)),
    ];
    setUniqueTimeCodes(uniqueCodes);
  }, [timeCode]);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleTimeCodeChange = (event) => {
    const code = event.currentTarget.getAttribute("data-value");
    setSelectedTimeCode(code);

    const ids = timeCode
      ?.filter((item) => item.AL_SalseforceCode === code)
      .map((item) => item.AL_ID)
      .join(",");
    setSelectedTimeCodeIds(ids);
    handleMenuClose();
  };
  const toastRef = useRef();
  const fetchDepartments = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=500`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setDepartment(data);
      } else {
        console.error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTimeCode = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=500`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setTimeCode(data?.filter((item) => item?.AL_Status == "1"));
      } else {
        console.error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchDepartments();
      fetchTimeCode();
    }
  }, [token]);

  return (
    <div className="back-design">
      <NavSub />
      <div className="Voucher-acc-timereports">
        <div>
          <div className="main-drop-userwise">
            <ToastComponent ref={toastRef} timeout={4000} />

            <FormControl className="select-dropdown-user">
              <InputLabel
                id="team-selector-label"
                className="select-dropdown-label"
                shrink={false}
              >
                SELECT TEAMS
                <div className="caret-icon-user">
                  {/* <CaretDown size={16} weight="fill" /> */}
                </div>
              </InputLabel>

              <Select
                labelId="team-selector-label"
                id="team-selector"
                className="dev-select-change "
                multiple
                value={selectedTeams}
                onChange={handleChange}
                renderValue={(selected) =>
                  selected
                    .map((teamId) => {
                      const team = teams.find((team) => team.id === teamId);
                      return team?.Name;
                    })
                    .join(" ")
                }
                // IconComponent={() => <div />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
                sx={{ width: 150 }}
              >
                {selectedTeams?.length > 0 && (
                  <MenuItem>
                    <ListItemText
                      style={{ color: "red" }}
                      primary={"Clear All"}
                    />
                  </MenuItem>
                )}
                {teams.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
                    <ListItemText primary={team.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{ display: "flex" }}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpen}
                className="drop-department"
                endIcon={isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              >
                {selectedDepartment ? selectedDepartment : "Department"}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="drop-menu-dept"
              >
                <MenuItem onClick={() => setSelectedDepartment()}>
                  {" "}
                  <ListItemText style={{ color: "red" }} primary={"Clear"} />
                </MenuItem>
                {uniqueDepartments.map((name, index) => (
                  <MenuItem
                    key={index}
                    data-value={name}
                    onClick={handleDeptChange}
                  >
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Menu>
            </div>

            <div className="Voucher-cust-name" onClick={toggleDateDropdown}>
              DATE RANGE
              {isdateDropdownOpen ? (
                <ArrowDropUpIcon style={{ marginLeft: "8px" }} />
              ) : (
                <ArrowDropDownIcon style={{ marginLeft: "8px" }} />
              )}
            </div>

            {isdateDropdownOpen && (
              <div ref={dropdown2Ref} className="Icon-Drop-date-Activity">
                <div className="drop-date">
                  <div>
                    <div className="drop-content-2">
                      Select A Custom Month Of Your Choice
                    </div>
                    <input
                      className="date-userwise"
                      type="month"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div className="drop-button">
                    <button
                      className="drop-button-design"
                      onClick={handleProceed}
                    >
                      PROCEED
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="Nav-btn-user">
            <FileArrowUp size={25} color="#3d025f" weight="fill" />
            <button
              className="Nav-Sub-btn"
              onClick={() => {
                setDownloadFile(true);
              }}
            >
              DOWNLOAD FILES
            </button>
          </div>
        </div>
      </div>

      <div className="time-report-nav">
        <div className="content-timereport">ACTIVITIES</div>
      </div>
      <div className="merge-activity">
        <ActivityWise2
          downloadFile={downloadFile}
          setDownloadFile={setDownloadFile}
          processedData={processedData}
          setProcessedData={setProcessedData}
          selectedIds={selectedIds}
          selectedTimeCode={selectedTimeCode}
          timeCode={timeCode}
          selectedCostCenters={selectedCostCenters}
          department={department}
          selectedDate={chosenDate}
          teams={teams}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
          selectedEmployees={selectedEmployees}
        />
      </div>
      {/* <Footerweek /> */}
    </div>
  );
}
export default ActivityWiseTimeReport;
