import React from 'react'
import "../Styles/WeekelyTimesheet2.css";
import "../Styles/WeekelyTimesheet1.css";
import "../Styles/ContentWeek.css";
import "../Styles/WeekActivity.css";
import VerticalDivider from '@mui/material/Divider';

import "../Styles/Activitywise1new.css";
import { FunnelSimple } from '@phosphor-icons/react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';






function Activitywise1new() {

    
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const data = [
        {
          details: [
           
            { companyName: 'Anusha' },
            { phoneNumber: 'Team'},
     
          ]
        },
        {
            details: [
             
              { companyName: 'Anusha' },
              { phoneNumber: 'Team'},
       
            ]
          },
          {
            details: [
             
              { companyName: 'Anusha' },
              { phoneNumber: 'Team'},
       
            ]
          },
          {
              details: [
               
                { companyName: 'Anusha' },
                { phoneNumber: 'Team'},
         
              ]
            },
            {
              details: [
               
                { companyName: 'Anusha' },
                { phoneNumber: 'Team'},
         
              ]
            },
            {
                details: [
                 
                  { companyName: 'Anusha' },
                  { phoneNumber: 'Team'},
           
                ]
              },
              {
                details: [
                 
                  { companyName: 'Anusha' },
                  { phoneNumber: 'Team'},
           
                ]
              },
              {
                  details: [
                   
                    { companyName: 'Anusha' },
                    { phoneNumber: 'Team'},
             
                  ]
                },
                {
                  details: [
                   
                    { companyName: 'Anusha' },
                    { phoneNumber: 'Team'},
             
                  ]
                },
                {
                    details: [
                     
                      { companyName: 'Anusha' },
                      { phoneNumber: 'Team'},
               
                    ]
                  },
                  {
                    details: [
                     
                      { companyName: 'Anusha' },
                      { phoneNumber: 'Team'},
               
                    ]
                  },
                  {
                      details: [
                       
                        { companyName: 'Anusha' },
                        { phoneNumber: 'Team'},
                 
                      ]
                    },
      
 
      

      ];
      const EmptyRow = ({ columns, index }) => {
        const className = index % 2 === 0 ? "Content2-week2" : "Content2-week22";
        return (
          <div className={className}>
            <div className="slider2-container">
              {columns.map((column, index) => (
                <div className="Content2-w21" key={index}>
                  <div className="Content-weeklabel12new">&nbsp;</div>
                  <div className={`Content2-weeklabel111 ${index === 1 ? "second" : ""}`}>&nbsp;</div> 
                </div>
              ))}
            </div>
          </div>
        );
      };
      
      const generateEmptyRows = (numRows, numColumns) => {
        const emptyRows = [];
        for (let i = 0; i < numRows; i++) {
          const emptyDetail = [];
          for (let j = 0; j < numColumns; j++) {
            emptyDetail.push({ label: `Empty-${i}-${j}` });
          }
          emptyRows.push({ details: emptyDetail });
        }
        return emptyRows;
      };


      const numRowsToAdd = 2; 
  const numColumns = data[0].details.length; 
  const emptyRows = generateEmptyRows(numRowsToAdd, numColumns);

  return (
    <div  className='main-newcontent11'>
        <div className='Content-week2211'>
        

<div className='slider-container2211'>
  



<div className='Content-w21'>
  <div className='Content-weeklabel12new'>EMPLOYEE   <FunnelSimple size={25} color="#111112"
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                  marginLeft: "100px", 
                                }}
                                onClick={handleClick1}
                              />

                              <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          
                         
                          >
                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, lineHeight: '21px', textAlign: 'left' , gap:"10px"}}><input type="checkbox" /> All</MenuItem>
                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, lineHeight: '21px', textAlign: 'left', gap:"10px" }}> <input type="checkbox" />Supplier Invoice</MenuItem>
                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, lineHeight: '21px', textAlign: 'left', gap:"10px" }}> <input type="checkbox" />Adavance Voucher/ Manual Journal</MenuItem>
                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, lineHeight: '21px', textAlign: 'left', gap:"10px" }}> <input type="checkbox" />Supplier Invoice</MenuItem>
                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, lineHeight: '21px', textAlign: 'left', gap:"10px" }}> <input type="checkbox" />Customer Declarations</MenuItem>
                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, lineHeight: '21px', textAlign: 'left', gap:"10px" }}> <input type="checkbox" />Customer Declarations</MenuItem>
   
                          </Menu> </div>
</div>
<div className='Content-w21'>
  <div className='Content1-new'> TEAM</div>
</div>

</div>





{data.map((item, index) => (
  <div className={index % 2 === 0 ? "Content2-week2" : "Content2-week22"} key={index}>
    <div className="slider2-container">
      {item.details.map((detail, index) => (
        <div className="Content2-w21" key={index}>
         
          <div className="Content2-weeklabel11new">{detail.companyName}</div>
          {/* <div className="Content2-weeklabel1">{detail.label3}</div> */}
          <div className={`Content2-weeklabel1new ${index === 1 ? "second" : ""}`}>{detail.phoneNumber}</div>
       
        </div>
      ))}
    </div>
  </div>
))}      

 {emptyRows.map((row, index) => (
          <EmptyRow key={index} columns={row.details} index={index} />
        ))}

</div>

  
    </div>
  )
}

export default Activitywise1new;
