import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/HampiNavbar1.css";
import "../Styles/RegisterUserDetails.css";
import CustomerTeam1 from "../pages/CustomerTeam1";
import { UserContext } from "../context/UserContext";
import ToastComponent from "./ToastComponent";
import { AuthContext } from "../context/AuthContext";
function HampiNavbar1() {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const { teamsData, setTeamsData, teamNames, setTeamNames, fetchTeams } =
    useContext(UserContext);
  const { token } = useContext(AuthContext);
  const [employeeData, setEmployeeData] = useState([]);
  const { usersList, setUsersList , allUsersList} = useContext(UserContext);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const toastRef = useRef();

  useEffect(() => {
    const sortedTeams = [...teamNames].sort((a, b) => a.id - b.id);
    const activeTeam = sortedTeams.find(
      (t) => t.type === "T" && t.status === true
    );
    if (activeTeam) {
      setActiveTab(activeTeam.id - 1);
    }
  }, [teamNames]);

  return (
    <>
      <ToastComponent ref={toastRef} timeout={4000} />

      <div className="user_setup_layout">
        <div className="Container-nav1">
          <div class="flex justify-center  gap-4  flex-row ">
            <div className="modal_buttons_section1">
              {teamNames?.map((teamName, index) => {
                return (
                  <button
                    key={index}
                    className={`modal_btns1 ${
                      activeTab === index ? "active" : ""
                    }`}
                    style={{
                      display: `${
                        teamName?.type === "S" || !teamName?.status
                          ? "none"
                          : "block"
                      }`,
                      minWidth: "100px, ",
                    }}
                    onClick={() => {
                      handleTabClick(index);
                    }}
                  >
                    {teamName.name}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          {/* {activeTab === 0 && ( */}
          <div>
            <CustomerTeam1
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={teamsData?.[activeTab + 1]}
              employeeData={allUsersList}
              teamName={teamNames?.[activeTab]?.name}
              teamId={teamNames?.[activeTab]?.id}
            />
          </div>
          {/* )} */}
          {/* {activeTab === 1 && (
          <div>
            <CustomerTeam1 />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <CustomerTeam1 />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <CustomerTeam1 />
          </div>
        )} */}
        </div>
      </div>
    </>
  );
}
export default HampiNavbar1;
