import React, { useContext, useState } from 'react'
// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import "../Styles/Organisation.css"
import "../Styles/Roles.css"
import { UserContext } from '../context/UserContext';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
function OrganisationDetail({ edit, isNew , errors, empData, setEmpData ,roles }) {
  const [employeeData, setEmployeeData] = useState(empData[0]);
  const {usersList, setUsersList} = useContext(UserContext);
  // //console.log(usersList)
  const editMode = isNew?true:edit;

  const [qc, setQc] = useState(empData[0]?.STM_QC);
  const [pl, setPl] = useState(empData[0]?.STM_PL );
  const [dl, setDl] = useState(empData[0]?.STM_DM_Head);
  const [manager, setManager] = useState(empData[0]?.STM_Manager);

  return (
    <div>
          <div className='Roles_layout'>
        <div className='Roles_row_fiels'>
            <div className='Roles_row'>
                <div className='Roles_row__label'>Quality Consultant <span className="span-icon-star">*</span></div>
                <div className='select_box'>

  
  <Box sx={{ minWidth: 870 }}>
  <FormControl fullWidth>
    <InputLabel htmlFor="qc-select" shrink={!editMode}>
      {!qc && "Choose Quality Consultant"} 
    </InputLabel>
    <Select
      fullWidth
      id="qc-select"
      className='textfield_organisation_deatils-oraganisation'
      disabled={!editMode}
      value={qc}
      onChange={(e) => {
        setQc(e.target.value);
        const updatedEmpData = {
          ...empData,
          "0": {
            ...empData["0"],
            "STM_QC": e.target.value
          }
        };
        setEmpData(updatedEmpData);
      }}
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 200, 
            overflowY: 'auto', 
            mt: 1 
          }
        }
      }}
    >
   
      {usersList?.map((data, index) => (
        <MenuItem key={data.EM_ID + index} value={data.EM_ID}>
                      {data?.EM_FirstName + ' '+ data?.EM_LastName}
                      </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>

    

                </div>
                {!qc &&errors&&<div style={{color:'red'}}>Quality Consultant is required</div>}
            </div>
            <div className='Roles_row'>
                <div  className='Roles_row__label'>Process Lead <span className="span-icon-star">*</span></div>
                <div className='select_box'>
              
    <Box sx={{ minWidth: 870 }}>
  <FormControl fullWidth>
    <InputLabel htmlFor="pl-select" shrink={!editMode}>
      {!pl && "Choose Process Lead"} 
    </InputLabel>
    <Select
      fullWidth
      id="pl-select"
      className='textfield_organisation_deatils-oraganisation'
      disabled={!editMode}
      value={pl}
      onChange={(e) => {
        setPl(e.target.value);
        const updatedEmpData = {
          ...empData,
          "0": {
            ...empData["0"],
            "STM_PL": e.target.value
          }
        };
        setEmpData(updatedEmpData);
      }}
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 200, 
            overflowY: 'auto', 
            mt: 1 
          }
        }
      }}
    >
   
      {usersList?.map((data, index) => (
        <MenuItem key={data.EM_ID + index} value={data.EM_ID}>
                      {data?.EM_FirstName + ' '+ data?.EM_LastName}
                      </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
    {!pl&&errors&&<div style={{color:'red'}}>Process Lead is required</div>}

                </div>
            </div>
        </div>
        <div className='Roles_row_fiels'>
        <div className='Roles_row'>
                <div  className='Roles_row__label'>Department lead <span className="span-icon-star">*</span></div>
                <div className='select_box'>
                

    <Box sx={{ minWidth: 870 }}>
  <FormControl fullWidth>
    <InputLabel htmlFor="dl-select" shrink={!editMode}>
      {!dl && "Choose Department Lead"} 
    </InputLabel>
    <Select
      fullWidth
      id="dl-select"
      className='textfield_organisation_deatils-oraganisation'
      disabled={!editMode}
      value={dl}
      onChange={(e) => {
        setDl(e.target.value);
        const updatedEmpData = {
          ...empData,
          "0": {
            ...empData["0"],
            "STM_DM_Head": e.target.value
          }
        };
        setEmpData(updatedEmpData);
      }}
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 200, 
            overflowY: 'auto', 
            mt: 1 
          }
        }
      }}
    >
     
      {usersList?.map((data, index) => (
        <MenuItem key={data.EM_ID + index} value={data.EM_ID}>
                      {data?.EM_FirstName + ' '+ data?.EM_LastName}
                      </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>


 
   

                </div>
                {!dl&&errors&&<div style={{color:'red'}}>Department Lead is required</div>}
            </div>
            <div className='Roles_row'>
                <div  className='Roles_row__label'>Manager <span className="span-icon-star">*</span> </div>
                <div className='select_box'>
              

<Box sx={{ minWidth: 870 }}>
  <FormControl fullWidth>
    <InputLabel htmlFor="manager-select" shrink={!editMode}>
      {!manager && "Choose Manager"} 
    </InputLabel>
    <Select
      fullWidth
      id="manager-select"
      className='textfield_organisation_deatils-oraganisation'
      disabled={!editMode}
      value={manager}
      onChange={(e) => {
        setManager(e.target.value);
        const updatedEmpData = {
          ...empData,
          "0": {
            ...empData["0"],
            "STM_Manager": e.target.value
          }
        };
        setEmpData(updatedEmpData);
      }}
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 200, // Set the max height of the dropdown
            overflowY: 'auto', // Add vertical scroll if needed
            mt: 1 // Adjust margin top to ensure it appears below the text field
          }
        }
      }}
    >
    
      {usersList?.map((data, index) => (
        <MenuItem key={data.EM_ID + index} value={data.EM_ID}>
                      {data?.EM_FirstName + ' '+ data?.EM_LastName}
                      </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>

   

               
                </div>
                {!manager&&errors&&<div style={{color:'red'}}>Manager is required</div>}
            </div>
        </div>
    </div>
    </div>
  )
}

export default OrganisationDetail