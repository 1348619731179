import React, { useEffect, useState } from "react";
import "../Styles/SignUpPage.css"
import "react-datepicker/dist/react-datepicker.css";
import { Box, TextField } from "@mui/material";

function BasicInfoDetail({ edit, isNew , empData, setEmpData , errors}) {
  const [employeeData, setEmployeeData] = useState(empData[0]);
  
  const [fullName, setFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [empId, setEmpId] = useState( );
  const [joinDate, setJoinDate] = useState();
  const [terminationDate, setTerminationDate] = useState();
  const [userName, setUserName] = useState()
  const editMode = isNew?true:edit;

  useEffect(()=>{
    // //console.log('inside', empData);
    setFullName(
      (empData[0]?.EM_FirstName ? empData[0]?.EM_FirstName : '') + 
      " " + 
      (empData[0]?.EM_LastName ? empData[0]?.EM_LastName : '')
    );
        setFirstName(empData[0]?.EM_FirstName);
    setLastName(empData[0]?.EM_LastName);
    setEmpId(empData[0]?.EM_EmpID);
    setJoinDate(empData[0]?.EM_JoiningDate);
    setTerminationDate(empData[0]?.EM_TerminationDate);
    setUserName(empData?.[0]?.EM_EmailId?.split('@')?.[0]);
   
      },[empData])
  return (
    <div>
      <form class="flex flex-col my-4 mx-auto" style={{display:"flex",flexDirection:"column",gap:"24px", flexWrap:'wrap'}}>
        <div class="flex gap-4 flex-wrap">
          <div>
            <div className='Roles_row__label'>Employee Full Name <span className="span-icon-star">*</span></div>
            <div class="w-fill h-hug  gap-8">
            <Box sx={{ minWidth:  600}}>

            <TextField
  fullWidth
  type="text"
  placeholder="Enter Employee Full Name"
  className="textfield_organisation_deatils1"
  disabled={!editMode}
  style={{ cursor: editMode ? 'text' : 'not-allowed' }}
  value={fullName}
  onChange={(e) => {
    const { value } = e.target;
    setFullName(value); // Temporarily allow spaces while typing
  }}
  onBlur={() => {
    const nameParts = fullName.trim().split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' ');

    setFirstName(firstName); // Update first name
    setLastName(lastName);   // Update last name

    // Update the employee data object
    const updatedEmpData = {
      ...empData,
      "0": {
        ...empData["0"],
        "EM_FirstName": firstName,
        "EM_LastName": lastName
      }
    };
    setEmpData(updatedEmpData);
  }}
/>

                              </Box>
                              {!firstName&&errors&&<div style={{color:'red'}}>Name is required</div>}
            </div>
          </div>
          <div>
          <div className='Roles_row__label'>Employee Id <span className="span-icon-star">*</span></div>
            <div>
            <Box sx={{ minWidth:  600}}>

            <TextField               fullWidth

                type="text"
                placeholder="Enter Employee Id "
                className='textfield_organisation_deatils1'
                disabled={!editMode}
                style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                // class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
                value={empId }
                onChange={(e) => {
                  const { value } = e.target;
                  setEmpId(value);
                  // Update the 'EM_FirstName' inside the employee object
                  const updatedEmpData = {
                    ...empData,
                    "0": {
                      ...empData["0"],
                      "EM_EmpID": value
                    }
                  };
                
                  setEmpData(updatedEmpData);
                }}

              />
              </Box>
                                            {!empId&&errors&&<div style={{color:'red'}}>EMployee ID is required</div>}

            </div>
          </div>
        </div>
        {/* <div>
        <div className='Roles_row__label'>Employee ID</div>
          <div>
            <input
              type="text"
              placeholder="Employee ID"
            //    className={`textfield_organisation_deatils ${editMode ? 'enabled' : ''}`}
                disabled={!editMode}
                style={{ cursor: editMode ? 'text' : 'not-allowed' }}
              class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
          </div>
        </div> */}
        <div class="flex gap-4 ">
          {!editMode&&<div>
          <div className='Roles_row__label'>Joining Date </div>
          <div>
          <Box sx={{ minWidth: 600 }}>
<TextField type='text'                 className='textfield_organisation_deatils1'
 value={joinDate} disabled fullWidth/> </Box>
</div>

          </div>}
          {editMode&&<div>
          <div className='Roles_row__label'>Joining Date <span className="span-icon-star">*</span></div>
          <div>
          <Box sx={{ minWidth: 600 }}>

          <TextField fullWidth
  type='date' 
  className='textfield_organisation_deatils1'
  value={joinDate} 
  onChange={(e) => {
    const { value } = e.target;
    setJoinDate(value);
    // Update the 'EM_FirstName' inside the employee object
    const updatedEmpData = {
      ...empData,
      "0": {
        ...empData["0"],
        "EM_JoiningDate": value
      }
    };
  
    setEmpData(updatedEmpData);
  }}
/>
</Box>
{!joinDate&&errors&&<div style={{color:'red'}}>Joining Date is required</div>}

</div>

          </div>}
          {!editMode && <div>
          <div className='Roles_row__label'>Termination Date</div>
            <div>
            <Box sx={{ minWidth:  600}}>

            <TextField fullWidth disabled type='text'                 className='textfield_organisation_deatils1'
 value={terminationDate}/>
            </Box>
            </div>
          </div>}
          {editMode && <div>
          <div className='Roles_row__label'>Termination Date</div>
            <div>
            <Box sx={{ minWidth:  600}}>

            <TextField fullWidth type='date'                 className='textfield_organisation_deatils1'
 value={terminationDate}
             onChange={(e) => {
              const { value } = e.target;
              setTerminationDate(value);
              // Update the 'EM_FirstName' inside the employee object
              const updatedEmpData = {
                ...empData,
                "0": {
                  ...empData["0"],
                  "EM_TerminationDate": value
                }
              };
            
              setEmpData(updatedEmpData);
            }}
            />
            </Box>
            {/* {!terminationDate&&<div style={{color:'red'}}>Joining Date is required</div>} */}

            </div>
          </div>}
        </div>
        <div class="flex gap-4">
          <div>
          <div className='Roles_row__label'>User Name <span className="span-icon-star">*</span></div>
            <div>
            <Box sx={{ minWidth:  600}}>

            <TextField
                          fullWidth

              type="text"
              placeholder="Enter User Name"
              className='textfield_organisation_deatils1'
              style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                disabled={!editMode}
            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            value={userName}
            onChange={(e) => {
              const { value } = e.target;
              
              setUserName(value.includes('@') ? value.split('@')[0] : value);
              // Update the 'EM_FirstName' inside the employee object
              const updatedEmpData = {
                ...empData,
                "0": {
                  ...empData["0"],
                  "EM_EmailId": value.trim()+'@viewgroup.in'
                }
              };
            
              setEmpData(updatedEmpData);
            }}
            />
            </Box>
            {!userName&&errors&&<div style={{color:'red'}}>Email is required</div>}

            </div>
          </div>
          <div>
          <div className='Roles_row__label'>Domain</div>
            <div>
            <Box sx={{ minWidth:  600}}>

            <TextField
                          fullWidth

              type="text"
              placeholder="Domain"
              className='textfield_organisation_deatils1'
              disabled={!editMode}
                style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                value={'@viewgroup.in'}

            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
            </Box>
            </div>
          </div>
        </div>
      </form>
      <div class="flex flex-col text-custom-gray-text mt-4">
        <div className="design-footer-font">*Automatically Set Password</div>
        <div  className="design-footer-font" >Require this user to change password when they first login</div>
        
      </div>
     
    </div>
  );
}

export default BasicInfoDetail;
