import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/NewreportingActivities.css";
import "../Styles/ReportinActivitiesC1.css";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import ToastComponent from "../components/ToastComponent";
import { CircularProgress, MenuItem } from "@mui/material";
import { MagnifyingGlass, CaretDown, CaretUp } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
const STRNav = () => {
  const { token, userData, logout } = useContext(AuthContext);
  const { usersList } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const toastRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedMasterData, setSelectedMasterData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const debounceTimeout = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [strTasks, setStrTasks] = useState([]);
  const [allActivities, setAllActivities] = useState([]);
  const [tab, setTab] = useState(0);
  const [basicActivities, setBasicActivities] = useState([]);
  const [taxActivities, setTaxActivities] = useState([]);
  const [annualActivities, setAnnualActivities] = useState([]);
  const [actualTime, setActualTime] = useState(0);
  const [totalStandardTime, setTotalStandardTime] = useState(0);
  const [totalRegisteredActivities, setTotalRegisteredActivities] = useState(0);
  const [submitAllowed, setSubmitAllowed] = useState(false);

  async function fetchData() {
    if (!selectedClient?.Id) {
      return;
    }
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?id=${selectedClient?.Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      //console.log("Fetched Data for subdata:", data);

      setSelectedMasterData(data);
    } catch (error) {
      //console.log(error);
    }
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleCaretDownClick = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (selectedClient?.Id) {
      fetchData();
    }
  }, [selectedClient]);

  const fetchSuggestionByText = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?SearchByText=${query}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        toastRef.current.showToast("Something went wrong.", "error");
        setLoading(false);
        return;
      }
      const data = await response.json();
      //console.log("Suggestions Data for integration", data);
      setSuggestions(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!query?.length) {
      return;
    }
    if (query.length > 0) {
      setLoading(true);
      debounceTimeout.current = setTimeout(() => {
        fetchSuggestionByText();
      }, 1000);
    } else {
      setSuggestions([]);
      setLoading(false);
    }
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [query]);

  const handleSuggestionClick = (suggestion) => {
    setSelectedClient(suggestion);
    setSuggestions([]);
    setShowSearch(false);
    setTab(0);
    setTab(1);

    setIsDropdownOpen(false);
    setShowSearch(false);
  };
  const searchBoxRef = useRef(null);

  const [loadingTasks, setLoadingTasks] = useState(false); // Loading state for tasks
  const [loadingActivities, setLoadingActivities] = useState(false); // Loading state for activities

  const getAllSTRTasks = async () => {
    try {
      setLoadingTasks(true); // Start loading
      const currDate = new Date();
      const response = await fetch(
        `${taskApiUrl}/GetAllTask?inputYear=${currDate.getFullYear()}&clientId=${
          selectedMasterData?.[0]?.Id
        }&ActivityType=Control Point`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      //console.log(data);
      setStrTasks(data);
      setActualTime(
        Math.ceil(
          data
            ?.filter((act) => act?.Status === "A")
            ?.reduce((acc, num) => acc + parseFloat(num?.TotalTime), 0)
        )
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingTasks(false); // End loading
    }
  };

  useEffect(() => {
    if (!selectedMasterData?.[0]?.Id) {
      return;
    }
    getAllSTRTasks();
  }, [selectedMasterData]);

  const fetchAllActivities = async () => {
    try {
      setLoadingActivities(true); //
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data?.filter((act) => act?.AL_Type === "Control Point"));
        setAllActivities(
          data?.filter((act) => act?.AL_Type === "Control Point")
        );
        return data;
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingActivities(false); // End loading
    }
  };

  useEffect(() => {
    setBasicActivities([]);
    setTaxActivities([]);
    setAnnualActivities([]);
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient) {
      fetchAllActivities();
    }
  }, [selectedClient]);

  useEffect(() => {
    if (allActivities?.length > 0) {
      setBasicActivities(
        allActivities?.filter(
          (act) =>
            act.AL_SubType === "Basic Information" && act.AL_Status == "1"
        )
      );
      setTaxActivities(
        allActivities?.filter(
          (act) =>
            act.AL_SubType === "Prepare Tax Return" && act.AL_Status == "1"
        )
      );
      setAnnualActivities(
        allActivities?.filter(
          (act) =>
            act.AL_SubType === "Prepare Annual Accounts" && act.AL_Status == "1"
        )
      );
    }
  }, [allActivities, selectedClient]);

  useEffect(() => {
    const activityCount =
      basicActivities?.length +
      taxActivities?.length +
      annualActivities?.length;
    const registeredActivitiesCount = strTasks?.filter(
      (act) => act?.Status == "A" || act?.Status == "R"
    )?.length;
    //console.log(activityCount, registeredActivitiesCount);

    if (activityCount == registeredActivitiesCount) {
      setSubmitAllowed(true);
    } else {
      setSubmitAllowed(false);
    }
  }, [basicActivities, strTasks]);

  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const [newRow, setNewRow] = useState({
    Code: "",
    Type: "Control Point",
    ApprovalIteration: "0",
    Client_CMID: selectedMasterData?.[0]?.Id,
    ClientName: selectedMasterData?.[0]?.Name,
    ActivityListId: "",
    AssignedTo: userData?.[0]?.EM_ID,
    Status: "P",
    CreatedBy: userData?.[0]?.EM_ID,
    CreateDate: formatDate(),
    StdTime: 0,
    AjdTime: 0,
    TotalTime: 0,
    ExtraTimeRemarks: "",
    Error_SEID: "0",
    ErrorRemarks: "",
    ReviewedBy_EMID: "",
    ReviewStatus: "P",
    ReviewDate: "",
    ReviewCount: "0",
    ErrorCount: "0",
    SalesforceCode: "0801 Financial statement and tax report",
    DepartmentId: selectedMasterData?.[0]?.Department,
  });

  const addTask = async (taskData) => {
    try {
      const response = await fetch(`${taskApiUrl}/AddTask`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(taskData),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) throw new Error("Failed to add task");
      getAllSTRTasks();
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const updateTask = async (taskId, taskData) => {
    try {
      const response = await fetch(`${taskApiUrl}/UpdateTask`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...taskData, Id: taskId }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) throw new Error("Failed to update task");
      const data = await response.json();

      if (data.Result === true && data.Status === true) {
        toastRef.current.showToast("Updated successfully.", "success");
        return;
      }
      if (data.Message === "Activity Period Close") {
        toastRef.current.showToast(
          "Period is closed. Unable to update.",
          "error"
        );
        return;
      } else {
        toastRef.current.showToast("Check the data.", "error");
      }
    } catch (error) {
      console.error("Error updating task:", error);
      toastRef.current.showToast(
        "Error updating task. Please try again.",
        "error"
      );
      return false;
    }
  };

  useEffect(() => {
    //console.log(strTasks);
    const totalTasksCount = strTasks?.filter(
      (task) => task?.Status == "A"
    )?.length;
    //console.log(totalTasksCount);
    setTotalStandardTime(totalTasksCount * 10);
  }, [strTasks]);

  const handleSubmit = async () => {
    const totalExecuted = strTasks?.filter(
      (act) => act?.Status === "A"
    )?.length;
    const actualTimePerTask = actualTime / totalExecuted;

    try {
      for (let i = 0; i < strTasks.length; i += 1) {
        const batch = strTasks.slice(i, i + 1);

        await Promise.all(
          batch.map((task) => {
            if (task?.Status === "A") {
              return updateTask(task.Id, {
                ...task,
                AjdTime:
                  parseFloat(actualTimePerTask) > 10
                    ? parseFloat(actualTimePerTask) - 10
                    : parseFloat(actualTimePerTask) - 10,
                StdTime: 10,
                TotalTime:
                  (parseFloat(actualTimePerTask) > 10
                    ? parseFloat(actualTimePerTask) - 10
                    : parseFloat(actualTimePerTask) - 10) + 10,
                ApprovalIteration: parseFloat(task?.ApprovalIteration) + 1,
                ReviewCount:1,
                CreatedBy: userData?.[0]?.EM_ID,
                SalesforceCode: "0801 Financial statement and tax report",
                DepartmentId: selectedMasterData?.[0]?.Department,
              });
            } else {
              return updateTask(task.Id, {
                ...task,
                AjdTime: 0,
                StdTime: 0,
                TotalTime: 0,
                ApprovalIteration: parseInt(task?.ApprovalIteration) + 1,
                CreatedBy: userData?.[0]?.EM_ID,
                SalesforceCode: "0801 Financial statement and tax report",
                DepartmentId: selectedMasterData?.[0]?.Department,
                ReviewCount:1,
              });
            }
          })
        );
      }

      getAllSTRTasks();
    } catch (e) {
      //console.log(e);
    }
  };

  const [loadingState, setLoadingState] = useState({});

  let debounceTimeoutCheckbox;

  const handleStatusChange = async (event, status, act) => {
    const matchingTask = strTasks?.find(
      (task) => task.ActivityListId == act.AL_ID
    );

    const taskData = {
      Code: "",
      Type: "Control Point",
      ApprovalIteration: "0",
      Client_CMID: selectedMasterData?.[0]?.Id,
      ClientName: selectedMasterData?.[0]?.Name,
      ActivityListId: act.AL_ID,
      AssignedTo: userData?.[0]?.EM_ID,
      Status: status,
      CreatedBy: userData?.[0]?.EM_ID,
      CreateDate: formatDate(),
      StdTime: 0,
      AjdTime: 0,
      TotalTime: 0,
      ExtraTimeRemarks: "",
      Error_SEID: "0",
      ErrorRemarks: "",
      ReviewedBy_EMID: "",
      ReviewStatus: "P",
      ReviewDate: "",
      ReviewCount: "0",
      ErrorCount: "0",
      SalesforceCode: "0801 Financial statement and tax report",
      DepartmentId: selectedMasterData?.[0]?.Department,
    };

    clearTimeout(debounceTimeoutCheckbox);

    setLoadingState((prevState) => ({
      ...prevState,
      [act.AL_ID]: true,
    }));

    debounceTimeoutCheckbox = setTimeout(async () => {
      if (!matchingTask) {
        await addTask(taskData);
      } else {
        await updateTask(matchingTask.Id, {
          ...taskData,
          Status: status,
        });
        getAllSTRTasks();
      }

      setLoadingState((prevState) => ({
        ...prevState,
        [act.AL_ID]: false,
      }));
    }, 1000);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      searchBoxRef.current &&
      !searchBoxRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
      setShowSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateActualTime = (actualTime) => {
    if (!actualTime) {
      toastRef.current.showToast("Enter Actual Time", "error", 3000);
      return false;
    }
    if (!/^\d+(\.\d+)?$/.test(actualTime)) {
      toastRef.current.showToast("Actual Time must be a valid number", "error", 3000);
      return false;
    }
    return true;
  };

  return (
    <div>
      <div className="nra-top-copfor-col">
        <div className="nra-top-cop">
          <div
            ref={dropdownRef}
            className="nra-top-cop2"
            onClick={handleCaretDownClick}
          >
            <div className="Title-STR-main2">
              {selectedClient?.Name || "Customer Name"}
            </div>
            <div
              style={{ display: "flex", margin: "4px auto", cursor: "pointer" }}
            >
              {isDropdownOpen ? (
                <CaretUp size={16} color="#3d025f" weight="fill" />
              ) : (
                <CaretDown size={16} color="#3d025f" weight="fill" />
              )}
            </div>
            {isDropdownOpen && <div className="dropdown-menu"></div>}
          </div>
        </div>
        <div ref={searchBoxRef}>
          {showSearch && (
            <div className="nra-dd-main-suggestion">
              <div className="input-suggestion-nra">
                <div className="icon-search-phospher">
                  <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />
                </div>
                <input
                  type="text"
                  value={query}
                  className="input-suggestion-search"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Start typing..."
                />
              </div>
              {loading ? (
                <p className="suggestion-title">
                  <CircularProgress
                    width={40}
                    style={{ color: "purple", position: "absolute" }}
                  />
                </p>
              ) : (
                <div>
                  {suggestions.length > 0 && (
                    <div className="new-dd-f-nra">
                      {suggestions.map((suggestion) => (
                        <div key={suggestion.Id} className="suggestion-list-li">
                          <label
                            className="li-content"
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.Name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="nra-top-cop2-top">
          <div className="detail-str-client">
            Scope : {selectedMasterData?.[0]?.OutsourcingSubprocessor}{" "}
            <span>|</span>
          </div>
          <div className="detail-str-client">
            Accounting System : {selectedMasterData?.[0]?.ERPSystem}
            <span>|</span>
          </div>
          <div className="detail-str-client">
            Department : {selectedMasterData?.[0]?.DepartmentName}{" "}
            <span>|</span>
          </div>
          <div className="detail-str-client">
            View Account Owner : {selectedMasterData?.[0]?.OwnerEMId}
          </div>
        </div>
      </div>

      <div className="Container-nav-STR">
        <div
          className={`modal_btns1 ${tab === 1 ? "active-tab" : ""}`}
          onClick={() => setTab(1)}
        >
          Basic Information
        </div>
        <div
          className={`modal_btns1 ${tab === 2 ? "active-tab" : ""}`}
          onClick={() => setTab(2)}
        >
          Prepare Tax Return
        </div>
        <div
          className={`modal_btns1 ${tab === 3 ? "active-tab" : ""}`}
          onClick={() => setTab(3)}
        >
          Prepare income statement and balance sheet
        </div>
      </div>

      <div
        style={{
          margin: "20px",
          display: "flex",
          gap: "40px",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "15px" }}>
          <div className="design-STR-actual">Actual Time </div>
          <input
            type="text"
            value={actualTime}
            onChange={(e) => setActualTime(e.target.value)}
            placeholder="Enter Time"
            className="STR-input"
          />
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          <div className="design-STR-actual">Standard Time</div>
          <input className="STR-input" type="text" value={totalStandardTime} />
        </div>
      </div>

      {/* {tab === 1 && selectedClient?.Id && (
        <div
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingBottom:"20px"
          }}
        >
          {basicActivities?.map((act) => {
            const matchingTask = strTasks?.find(
              (task) => task.ActivityListId == act.AL_ID
            );

            return (
              <div key={act.AL_ID} className="border-status">
                <div className="Container-STR-tabsinfo">
                  <div className="Title-sub-STR">Control Point</div>
                  <div className="Title-sub-STR1">
                    {act.AL_Name} {act.AL_ID}
                  </div>
                </div>
                <div className="status-side">
                  <div className="Title-sub-STR2">Status</div>
                  {!loadingState[act.AL_ID] && (
                   
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "25px",
                      }}
                    >
                      <label className="check-yes">
                        <input
                          type="radio"
                          id={`yes-${act.AL_ID}`}
                          name={`answer-${act.AL_ID}`}
                          value="Yes"
                          checked={matchingTask?.Status === "A"}
                          onChange={(e) => handleStatusChange(e, "A", act)}
                          disabled={loadingState[act.AL_ID]}
                          style={{
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />
                        <span
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            display: "inline-block",
                            marginRight: "5px",
                            position: "relative",
                            border:
                              matchingTask?.Status === "A"
                                ? "2px solid green"
                                : "2px solid green",
                          }}
                        >
                          <div
                            style={{
                              width: "11px",
                              height: "11px",
                              backgroundColor:
                                matchingTask?.Status === "A"
                                  ? "green"
                                  : "transparent",
                              borderRadius: "50%",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </span>
                        Yes
                      </label>
                      <label className="check-yes">
                        <input
                          type="radio"
                          id={`no-${act.AL_ID}`}
                          name={`answer-${act.AL_ID}`}
                          value="No"
                          checked={matchingTask?.Status === "R"}
                          onChange={(e) => handleStatusChange(e, "R", act)}
                          disabled={loadingState[act.AL_ID]}
                          style={{
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <span
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            display: "inline-block",
                            marginRight: "5px",
                            position: "relative",
                            border:
                              matchingTask?.Status === "R"
                                ? "2px solid red"
                                : "2px solid red",
                          }}
                        >
                          <div
                            style={{
                              width: "11px",
                              height: "11px",
                              backgroundColor:
                                matchingTask?.Status === "R"
                                  ? "red"
                                  : "transparent",
                              borderRadius: "50%",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </span>
                        No
                      </label>
                    </div>
                  )}
                  {loadingState[act.AL_ID] && (
                    <div className="design-font">Processing...</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )} */}
      {tab === 1 && selectedClient?.Id && (
        <div
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingBottom: "20px",
          }}
        >
          {loadingActivities && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress style={{ color: "purple" }} width={40} />
            </div>
          )}
          {!loadingActivities &&
            basicActivities?.map((act) => {
              const matchingTask = strTasks?.find(
                (task) => task.ActivityListId == act.AL_ID
              );

              return (
                <div key={act.AL_ID} className="border-status">
                  <div className="Container-STR-tabsinfo">
                    <div className="Title-sub-STR">Control Point</div>
                    <div className="Title-sub-STR1">
                      {act.AL_Name} {act.AL_ID}
                    </div>
                  </div>
                  <div className="status-side">
                    <div className="Title-sub-STR2">Status</div>
                    {!loadingState[act.AL_ID] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <label className="check-yes">
                          <input
                            type="radio"
                            id={`yes-${act.AL_ID}`}
                            name={`answer-${act.AL_ID}`}
                            value="Yes"
                            checked={matchingTask?.Status === "A"}
                            onChange={(e) => handleStatusChange(e, "A", act)}
                            disabled={loadingState[act.AL_ID]}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                          />
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "5px",
                              position: "relative",
                              border:
                                matchingTask?.Status === "A"
                                  ? "2px solid green"
                                  : "2px solid green",
                            }}
                          >
                            <div
                              style={{
                                width: "11px",
                                height: "11px",
                                backgroundColor:
                                  matchingTask?.Status === "A"
                                    ? "green"
                                    : "transparent",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </span>
                          Yes
                        </label>
                        <label className="check-yes">
                          <input
                            type="radio"
                            id={`no-${act.AL_ID}`}
                            name={`answer-${act.AL_ID}`}
                            value="No"
                            checked={matchingTask?.Status === "R"}
                            onChange={(e) => handleStatusChange(e, "R", act)}
                            disabled={loadingState[act.AL_ID]}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "5px",
                              position: "relative",
                              border:
                                matchingTask?.Status === "R"
                                  ? "2px solid red"
                                  : "2px solid red",
                            }}
                          >
                            <div
                              style={{
                                width: "11px",
                                height: "11px",
                                backgroundColor:
                                  matchingTask?.Status === "R"
                                    ? "red"
                                    : "transparent",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </span>
                          No
                        </label>
                      </div>
                    )}
                    {loadingState[act.AL_ID] && (
                      <div className="design-font">Processing...</div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {tab === 2 && selectedClient?.Id && (
        <div
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingBottom: "20px",
          }}
        >
          {" "}
          {loadingActivities && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress style={{ color: "purple" }} width={40} />
            </div>
          )}
          {!loadingActivities &&
            taxActivities?.map((act) => {
              const matchingTask = strTasks?.find(
                (task) => task.ActivityListId == act.AL_ID
              );

              return (
                <div key={act.AL_ID} className="border-status">
                  <div className="Container-STR-tabsinfo">
                    <div className="Title-sub-STR">Control Point</div>
                    <div className="Title-sub-STR1">
                      {act.AL_Name} {act.AL_ID}
                    </div>
                  </div>
                  <div className="status-side">
                    <div className="Title-sub-STR2">Status</div>
                    {!loadingState[act.AL_ID] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <label className="check-yes">
                          <input
                            type="radio"
                            id={`yes-${act.AL_ID}`}
                            name={`answer-${act.AL_ID}`}
                            value="Yes"
                            checked={matchingTask?.Status === "A"}
                            onChange={(e) => handleStatusChange(e, "A", act)}
                            disabled={loadingState[act.AL_ID]}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                          />
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "5px",
                              position: "relative",
                              border:
                                matchingTask?.Status === "A"
                                  ? "2px solid green"
                                  : "2px solid green",
                            }}
                          >
                            <div
                              style={{
                                width: "11px",
                                height: "11px",
                                backgroundColor:
                                  matchingTask?.Status === "A"
                                    ? "green"
                                    : "transparent",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </span>
                          Yes
                        </label>
                        <label className="check-yes">
                          <input
                            type="radio"
                            id={`no-${act.AL_ID}`}
                            name={`answer-${act.AL_ID}`}
                            value="No"
                            checked={matchingTask?.Status === "R"}
                            onChange={(e) => handleStatusChange(e, "R", act)}
                            disabled={loadingState[act.AL_ID]}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "5px",
                              position: "relative",
                              border:
                                matchingTask?.Status === "R"
                                  ? "2px solid red"
                                  : "2px solid red",
                            }}
                          >
                            <div
                              style={{
                                width: "11px",
                                height: "11px",
                                backgroundColor:
                                  matchingTask?.Status === "R"
                                    ? "red"
                                    : "transparent",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </span>
                          No
                        </label>
                      </div>
                    )}
                    {loadingState[act.AL_ID] && (
                      <div className="design-font">Processing...</div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {tab === 3 && selectedClient?.Id && (
        <div
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingBottom: "20px",
          }}
        >
          {" "}
          {loadingActivities && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress style={{ color: "purple" }} width={40} />
            </div>
          )}
          {!loadingActivities &&
            annualActivities?.map((act) => {
              const matchingTask = strTasks?.find(
                (task) => task.ActivityListId == act.AL_ID
              );

              return (
                <div key={act.AL_ID} className="border-status">
                  <div className="Container-STR-tabsinfo">
                    <div className="Title-sub-STR">Control Point</div>
                    <div className="Title-sub-STR1">
                      {act.AL_Name} {act.AL_ID}
                    </div>
                  </div>
                  <div className="status-side">
                    <div className="Title-sub-STR2">Status</div>
                    {!loadingState[act.AL_ID] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <label className="check-yes">
                          <input
                            type="radio"
                            id={`yes-${act.AL_ID}`}
                            name={`answer-${act.AL_ID}`}
                            value="Yes"
                            checked={matchingTask?.Status === "A"}
                            onChange={(e) => handleStatusChange(e, "A", act)}
                            disabled={loadingState[act.AL_ID]}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                          />
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "5px",
                              position: "relative",
                              border:
                                matchingTask?.Status === "A"
                                  ? "2px solid green"
                                  : "2px solid green",
                            }}
                          >
                            <div
                              style={{
                                width: "11px",
                                height: "11px",
                                backgroundColor:
                                  matchingTask?.Status === "A"
                                    ? "green"
                                    : "transparent",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </span>
                          Yes
                        </label>
                        <label className="check-yes">
                          <input
                            type="radio"
                            id={`no-${act.AL_ID}`}
                            name={`answer-${act.AL_ID}`}
                            value="No"
                            checked={matchingTask?.Status === "R"}
                            onChange={(e) => handleStatusChange(e, "R", act)}
                            disabled={loadingState[act.AL_ID]}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "5px",
                              position: "relative",
                              border:
                                matchingTask?.Status === "R"
                                  ? "2px solid red"
                                  : "2px solid red",
                            }}
                          >
                            <div
                              style={{
                                width: "11px",
                                height: "11px",
                                backgroundColor:
                                  matchingTask?.Status === "R"
                                    ? "red"
                                    : "transparent",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </span>
                          No
                        </label>
                      </div>
                    )}
                    {loadingState[act.AL_ID] && (
                      <div className="design-font">Processing...</div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {submitAllowed && basicActivities?.length > 0 && (
        <div className="style-button">
          <button
            className="common_btn"
            onClick={async () => {
              if (!validateActualTime(actualTime)) {
                return;
              }
              try {
                await handleSubmit();
                toastRef.current.showToast(
                  "Submitted successfully",
                  "success",
                  2000
                );
              } catch (error) {
                toastRef.current.showToast("Submission failed", "error", 2000);
              }
            }}
          >
            Submit
          </button>
        </div>
      )}
      <ToastComponent ref={toastRef} timeout={4000} />
    </div>
  );
};

export default STRNav;
