import { useState, useEffect, useRef, useContext } from "react";
import logo123 from "../Images/NewLogo.png";
import "../Styles/HampiNavbarNew.css";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../context/NotificationContext";

import {
  Bell,
  Plus,
  MagnifyingGlass,
  Eye,
  User,
  UsersThree,
  DotOutline,
  FileArrowUp,
  PlusCircle,
  CaretDown,
  CaretDoubleRight,
  PencilSimpleLine,
  XCircle,
  Download,
} from "@phosphor-icons/react";
import * as XLSX from "xlsx";
import { createTeam } from "../utils/Team";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress, Menu } from "@mui/material";
import ToastComponent from "./ToastComponent";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import close from "../Images/XCircle.png";
import modal_icon1 from "../Images/UserCheck.png";
import modal_icon2 from "../Images/Vector.png";
import modal_profile from "../Images/image 2.jpg";
import { Button, MenuItem } from "@mui/base";
import MappedMenu from "./MappedMenu";
import { CaretUp } from "phosphor-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { clientApiUrl, userApiUrl } from "../utils/GetUrl";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: "400",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 2,
};
function HampiNavbarNew(props) {
  const [activeItem, setActiveItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPrimaryDropdownOpen, setIsPrimaryDropdownOpen] = useState(false);
  const [isSecondaryDropdownOpen, setIsSecondaryDropdownOpen] = useState(false);
  const [isthirdDropdownOpen, setIsthirdDropdownOpen] = useState(false);
  const [isfourthDropdownOpen, setIsfourthDropdownOpen] = useState(false);
  // const [isfifthDropdownOpen, setIsfifthDropdownOpen] = useState(false);
  const [isbellDropdownOpen, setIsbellDropdownOpen] = useState(false);
  const { fetchTeams, usersList,allUsersList, teamNames } = useContext(UserContext);
  const { userData, token, logout, handleAuthExpiry } = useContext(AuthContext);
  const {
    generalNotifications,
    unreadNotifications,
    setUnreadNotifications,
    fetchGeneralNotifications,
    markNotificationsAsRead,
  } = useContext(AuthContext);
  const {
    postNotification,
    fetchNotifications,
    notifications,
    setNotifications,
  } = useNotifications();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();

  const handleReset = () => {
    navigate("/HampiReset");
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const closeDropdown = () => {
    // Reset activeItem when dropdown is closed
    setActiveItem(null);
    setIsPrimaryDropdownOpen(false);
  };

  const dropdownRef = useRef(null);
  const dropdown2Ref = useRef(null);
  const dropdown3Ref = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsPrimaryDropdownOpen(false);
      setIsSecondaryDropdownOpen(false);
      setIsbellDropdownOpen(false);
    }
  };

  const handleClick2Outside = (event) => {
    if (dropdown2Ref.current && !dropdown2Ref.current.contains(event.target)) {
      setIsbellDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick2Outside);
    return () => {
      document.removeEventListener("mousedown", handleClick2Outside);
    };
  }, []);
  /////////////////////////////////////////////////////

  const handleClick3Outside = (event) => {
    if (dropdown3Ref.current && !dropdown3Ref.current.contains(event.target)) {
      setIsthirdDropdownOpen(false);
      // setIsSecondaryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick3Outside);
    return () => {
      document.removeEventListener("mousedown", handleClick3Outside);
    };
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [subTeams, setSubTeams] = useState([]);

  // const addSubTeam = () => {
  //   setSubTeams([...subTeams, ""]);
  // };

  // const handleSubTeamChange = (index, value) => {
  //   const updatedSubTeams = [...subTeams];
  //   updatedSubTeams[index] = value;
  //   setSubTeams(updatedSubTeams);
  // };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  //////////////////////////////

  // const openModal1 = () => {
  //   setIsModalOpen1(true);
  // };
  // const closeModal1 = () => {
  //   setIsModalOpen1(false);
  // };

  // ////////////////////////////

  const togglePrimaryDropdown = () => {
    setIsPrimaryDropdownOpen(!isPrimaryDropdownOpen);
    setIsSecondaryDropdownOpen(false); // Close secondary dropdown when primary is toggled
  };

  const toggleSecondaryDropdown = () => {
    setIsSecondaryDropdownOpen(!isSecondaryDropdownOpen);
  };

  // togglethirdDropdown

  const togglethirdDropdown = () => {
    setIsthirdDropdownOpen(!isthirdDropdownOpen);
  };
  const togglefourthDropdown = () => {
    setIsfourthDropdownOpen(!isfourthDropdownOpen);
  };

  const togglebellDropdown = () => {
    setIsbellDropdownOpen(!isbellDropdownOpen);

    if (!isbellDropdownOpen) {
      markNotificationsAsRead();
    }
  };

  // const togglefifthDropdown = () => {
  //   setIsfifthDropdownOpen(!isfifthDropdownOpen);
  // };

  const [roles, setRoles] = useState();
  const [designation, setDesignation] = useState();
  const [department, setDepartment] = useState();

  const fetchRoles = async () => {
    try {
      const response = await fetch(`${userApiUrl}/GetRole?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        logout();
        return false;
      }
      ////console.log(data);
      setRoles(data);
    } catch (error) {
      //console.error('Error fetching data:', error);
    }
  };
  const fetchDepartments = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        logout();
        return false;
      }
      ////console.log(data);
      setDepartment(data);
    } catch (error) {
      //console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchRoles();
      fetchDepartments();
    }
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleTeamNameChange = (event) => {
    setTeamName(event.target.value);
  };
  const handleSubTeamChange = (index, value) => {
    const updatedSubTeams = [...subTeams];
    updatedSubTeams[index] = value;
    setSubTeams(updatedSubTeams);
  };
  const addSubTeam = () => {
    setSubTeams([...subTeams, ""]);
  };
  const handleSubmit = async () => {
    setLoading(true);

    setError(false);
    try {
      if (!teamName || !subTeams.every((value) => !!value)) {
        setError(true);
        setLoading(false);

        return;
      }
      const teamData = await createTeam(teamName, subTeams, token);

      setLoading(false);
      if (teamData === "Unauthorised") {
        toastRef.current.showToast("Session Timed out. Please Login", "error");
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate("/");
        return;
      }
      //console.log(teamData);
      if (teamData) {
        //console.log("Team created successfully:", teamData);
        toastRef.current.showToast("Team created Successfully", "success");

        fetchTeams(token);
        setTeamName("");
        setSubTeams([]);
        closeModal();
        setLoading(false);
      } else {
        toastRef.current.showToast(
          "Could not create. Possible error: A team or Subteam already exists by that Name",
          "error"
        );
        console.error("Failed to create team");
      }
    } catch (error) {
      setLoading(false);
      setLoading(false);

      console.error("Error creating team:", error);
    }
  };
  const [isAnnualDropdownOpen, setIsAnnualDropdownOpen] = useState(false);
  const [primaryDropdownName, setPrimaryDropdownName] = useState(
    "USER SET UP AND ROLES"
  );
  const handleAnnualItemClick = (itemName, path) => {
    setPrimaryDropdownName(itemName);
    toggleAnnualDropdown();
    navigate(path);
  };
  const handleSecondaryItemClick = (itemName, path) => {
    setPrimaryDropdownName(itemName);
    toggleSecondaryDropdown();
    navigate(path);
  };
  const toggleAnnualDropdown = () => {
    setIsAnnualDropdownOpen(!isAnnualDropdownOpen);
  };

  const [searchData, setSearchData] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const suggestionsRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose2 = () => setOpen(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!searchData) {
      return;
    }
    const filtered = allUsersList?.filter((item) =>
      item?.EM_FirstName?.toLowerCase().includes(searchData?.toLowerCase())
    );
    setSuggestions(filtered);
    //console.log(filtered);
  }, [searchData, usersList]);

  // //console.log(userData)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function convertRoles(input) {
    // Split the input string by commas and map each part to a number
    return input?.split(",")?.map(Number);
  }

  function removeDuplicatesAndGroupByParentNames(items) {
    // Remove duplicates based on id
    const uniqueItemsMap = new Map();
    items.forEach((item) => {
      uniqueItemsMap.set(item.id, item);
    });

    // Group items by parentNames
    const groupedByParentName = {};
    uniqueItemsMap.forEach((item) => {
      if (!groupedByParentName[item.parentNames]) {
        groupedByParentName[item.parentNames] = [];
      }
      groupedByParentName[item.parentNames].push(item);
    });

    // Convert grouped items to array of key-value pairs
    const resultArray = Object.entries(groupedByParentName).map(
      ([parentName, elements]) => ({
        [parentName]: elements,
      })
    );

    return resultArray;
  }

  const [groupedData, setGroupedData] = useState([]);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const getUserNameById = (id) => {
    const user = usersList.find((user) => user.EM_ID === id);
    return user ? user.EM_FirstName : "Unknown";
  };
  const formatRelativeTime = (date) => {
    const now = dayjs();
    const then = dayjs(date).add(5, 'hour').add(30, 'minute'); // Add 5 hours and 30 minutes
    const diffInSeconds = now.diff(then, 'seconds');
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
  
    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  const handleDownload = () => {
   
    let empDatatoExport = JSON.parse(JSON.stringify(props?.employeeData));
  
    let selectedExportables = empDatatoExport?.map((user) => {
      

      user.EM_Designation = roles?.find(
        (role) => role?.Id === parseFloat(user?.EM_Designation)
      )?.Name;
     
      user.STM_DM_Head = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_DM_Head
      )?.EM_FirstName;
      user.STM_PL = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_PL
      )?.EM_FirstName;
      user.STM_QC = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_QC
      )?.EM_FirstName;
      user.STM_Manager = props.employeeData?.find(
        (person) => person?.EM_ID == user?.STM_Manager
      )?.EM_FirstName;
      user.STM_Team = teamNames?.find(
        (team) => team?.id == parseFloat(user?.STM_Team)
      )?.name;
      user.STM_Subteam = teamNames?.find(
        (team) => team?.id == parseFloat(user?.STM_Subteam)
      )?.name;
      user.UM_Firstlogin = null;
      user.EM_ID = null;

      return user;
    });
    ////console.log('filtered' , '376', filtered)

    // //console.log(selectedExportables);
   
    exportToExcel(selectedExportables, "UserData");
  };

  function exportToExcel(data, fileName) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the array of objects to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate buffer
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Create a Blob object
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Create an anchor element and trigger the download
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${fileName}.xlsx`;
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <ToastComponent ref={toastRef} timeout={4000} />

      <main className="Nav-Main">
        <div className="nav-color">
          <nav className="flex items-center justify-between px-8 h-22 ">
            <section className="relative flex items-center gap-5">
              <img className="w-16 h-8 " loading="lazy" src={logo123} alt="" />
              <div className="flex items-center justify-between py-6 gap-11 ">
                <div className="Customer-nav">
                  <MappedMenu />
                </div>
              </div>
            </section>

            <section className="flex items-center ">
              <div className="Nav-icon-Main" onClick={togglebellDropdown}>
                <div style={{ display: "flex" }}>
                  <>
                    <Bell
                      size={18}
                      className="bell1"
                      color="#3d025f"
                      weight="fill"
                      onClick={togglebellDropdown}
                    />
                    {unreadNotifications && (
                      <DotOutline
                        size={52}
                        className="be"
                        color="#0fdf0c"
                        weight="fill"
                      />
                    )}
                  </>
                </div>
                {isbellDropdownOpen && (
                  <div className="notifications-dropdown">
                    <div className="notifications-list" ref={dropdownRef}>
                      {generalNotifications.length > 0 ? (
                        generalNotifications.map((notification, index) => (
                          <div
                            className={`notification-item ${
                              notification.read ? "read" : "unread"
                            }`}
                            key={index}
                          >
                            <p className="notification-message">
                              {getUserNameById(notification.createdby)}{" "}
                              {notification.message}
                            </p>
                            <p className="notification-time">
                              {formatRelativeTime(notification.createdDate)}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p>No notifications available</p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between gap-2 py-6 pl-8">
                <User size={20} color="#3d025f" weight="fill" />
                <div
                  className="h-6 font-semibold cursor-pointer text-purple-950 text-l design-navmain"
                  onClick={togglethirdDropdown}
                >
                  {userData?.[0]?.EM_FirstName}
                </div>

                <Modal
                  open={open1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="custom-modal"
                >
                  <Box sx={style}>
                    <div className="modal-head1">
                      <div className="modal-h1">PROFILE</div>
                      <img
                        src={close}
                        alt="profie-image"
                        className="close-me"
                        onClick={handleClose1}
                      />
                    </div>
                    <div className="modal-head2">
                      <div className="profile-image">
                        <img
                          // src={userData?.[0]?.profileImage}
                          src={modal_profile}
                          alt="profie-image"
                          className="profile-pic"
                        />
                      </div>
                      <div className="modal-profile-data">
                        <div className="modal-profile-heading1">
                          {userData?.[0]?.EM_FirstName + ' '+ userData?.[0]?.EM_LastName}
                        </div>

                        {/* <div className="modal-profile-heading2">{userData?.[0]?.EM_Designation}</div> */}

                        <div className="modal-profile-heading2">
                          {userData?.[0]?.DR_Name || "Unknown"}
                        </div>

                        {/* <div className="modal-profile-heading3">Team : {userData?.[0]?.STM_Team}</div>
              <div className="modal-profile-heading3">Sub Team : {userData?.[0]?.STM_Subteam}</div> */}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                          }}
                        >
                          <div className="modal-profile-heading3">
                            {" "}
                            Team :{" "}
                            {teamNames?.find(
                              (team) =>
                                team?.id?.toString() === userData?.[0]?.STM_Team
                            )?.name || "Unknown"}
                          </div>
                          <div className="modal-profile-heading3">
                            SubTeam :{" "}
                            {teamNames?.find(
                              (team) =>
                                team?.id?.toString() ===
                                userData?.[0]?.STM_Subteam
                            )?.name || "Unknown"}
                          </div>
                        </div>

                        <div className="modal-profile-part2">
                          <div className="modal-profile-heading4">
                            <img
                              src={modal_icon1}
                              alt="profie-image"
                              className="modal1_icon"
                            />
                            <div className="m-mail-data">
                              {userData?.[0]?.EM_EmpID}
                            </div>
                          </div>
                        </div>
                        <div className="modal-profile-part2">
                          <div className="modal-profile-heading5">
                            <img
                              src={modal_icon2}
                              alt="profie-image"
                              className="modal2_icon"
                            />
                            <div className="m-mail-data">
                              {userData?.[0]?.EM_EmailId}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="organization-structure-container">
                      <div className="modal-profile-2-h1">
                        ORGANISATION STRUCTURE
                      </div>

                      {userData?.[0]?.STM_Manager && (
                        <div className="modal-profile-2">
                          <div className="modal-profile-2-h2">
                            <div className="modal-profile-2-level">
                              <div className="modal-profile-2-h2-sh2">
                                {
                                  usersList?.find(
                                    (user) =>
                                      user?.EM_ID === userData?.[0]?.STM_Manager
                                  )?.EM_FirstName
                                }
                              </div>
                              <div className="modal-profile-2-h2-sh1">
                                Manager
                              </div>

                              <div className="modal-desig-color">
                                {" "}
                                {usersList?.find(
                                  (user) =>
                                    user?.EM_ID === userData?.[0]?.STM_Manager
                                )?.DR_Name || "Unknown"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {userData?.[0]?.STM_DM_Head && (
                        <div className="modal-profile-2">
                          <div className="modal-profile-2-h2">
                            <div className="modal-profile-2-level">
                              <div className="modal-profile-2-h2-sh2">
                                {
                                  usersList?.find(
                                    (user) =>
                                      user?.EM_ID === userData?.[0]?.STM_DM_Head
                                  )?.EM_FirstName
                                }
                              </div>
                              <div className="modal-profile-2-h2-sh1">
                                Department Head
                              </div>
                              <div className="modal-desig-color">
                                {usersList?.find(
                                  (user) =>
                                    user?.EM_ID === userData?.[0]?.STM_DM_Head
                                )?.DR_Name || "Unknown"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {userData?.[0]?.STM_PL && (
                        <div className="modal-profile-2">
                          <div className="modal-profile-2-h2">
                            <div className="modal-profile-2-level">
                              <div className="modal-profile-2-h2-sh2">
                                {
                                  usersList?.find(
                                    (user) =>
                                      user?.EM_ID === userData?.[0]?.STM_PL
                                  )?.EM_FirstName
                                }
                              </div>
                              <div className="modal-profile-2-h2-sh1">
                                Process Lead
                              </div>
                              <div className="modal-desig-color">
                                {usersList?.find(
                                  (user) =>
                                    user?.EM_ID === userData?.[0]?.STM_PL
                                )?.DR_Name || "Unknown"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {userData?.[0]?.STM_QC && (
                        <div className="modal-profile-2">
                          <div className="modal-profile-2-h2">
                            <div className="modal-profile-2-level">
                              <div className="modal-profile-2-h2-sh2">
                                {
                                  usersList?.find(
                                    (user) =>
                                      user?.EM_ID === userData?.[0]?.STM_QC
                                  )?.EM_FirstName
                                }
                              </div>
                              <div className="modal-profile-2-h2-sh1">
                                Quality Consultant
                              </div>
                              <div className="modal-desig-color">
                                {" "}
                                {usersList?.find(
                                  (user) =>
                                    user?.EM_ID === userData?.[0]?.STM_QC
                                )?.DR_Name || "Unknown"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Box>
                </Modal>
                <button onClick={togglethirdDropdown}>
                  {isthirdDropdownOpen ? (
                    <CaretUp size={16} color="#3d025f" weight="fill" />
                  ) : (
                    <CaretDown size={16} color="#3d025f" weight="fill" />
                  )}
                </button>

                {isthirdDropdownOpen && (
                  <div ref={dropdown3Ref} className="third-Drop">
                    <ul>
                      <li
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100 design-family"
                        onClick={handleOpen1}
                      >
                        My Profile{" "}
                      </li>

                      <li
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100 design-family"
                        onClick={handleReset}
                      >
                        Reset Password
                      </li>
                      <li
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100 design-family"
                        onClick={logout}
                      >
                        Log Out{" "}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </section>
          </nav>

          <nav className="flex items-center justify-between px-8 pb-4 border-b shadow-lg h-22 nav-Maincolor">
            <section className="relative flex items-center gap-6">
              <div className="relative titel11">
                <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />
                <input
                  type="text"
                  className="title111"
                  id="searchInput"
                  name="searchData"
                  value={searchData}
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                  placeholder="Search by employee Id, employee name"
                />

                <div
                  ref={suggestionsRef}
                  className="absolute w-full bg-white rounded-md shadow-md suggestions top-10 "
                  onBlur={() => {
                    setSuggestions([]);
                  }}
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  {suggestions?.map((item) => (
                    <ul>
                      <li
                        className="w-full px-12 py-2 text-left cursor-pointer design-family"
                        onClick={() => {
                          setSuggestions([]);
                          navigate(`/UserSetUpDetial/${item?.EM_ID}`);
                        }}
                      >
                        {item?.EM_FirstName+' '+ item?.EM_LastName}
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </section>

            <section className="flex items-center gap-4">
              {/* <div className="Nav-btn">
              <Download size={35} color="#3d025f" weight="fill" />
              <button className="Nav-Sub-btn">IMPORT FILES</button>
            </div> */}

              {/* <button  onClick={openModal} className='flex items-center justify-center mx-auto text-center text-white rounded-lg font-poppins w-36 h-9 bg-purple-950'>
          <PlusCircle size={20} color="#FFFFFF" weight="fill" className="mr-2" /> 
          <span className=" aaa">CREATE TEAM</span>
        </button> */}
              <div>
                
              <button onClick={handleDownload} className="common_btn">
                  <span className="common-btn">Export Employees</span>
                </button>
              </div>
              <div>
                
                <button onClick={openModal} className="Nav-button">
                  <PlusCircle size={20} color="#FFFFFF" weight="fill" />
                  <span className=" aaa">CREATE TEAM</span>
                </button>
              </div>

              {isModalOpen && (
                <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
                  <div className="modalLayout">
                    <div className="modalLayout_contact">
                      <div className="modalLayoutMain_contact">
                        <div className="modalStyling">
                          <div className="flex justify-between">
                            <div className="text-lg font-semibold text-left font-poppins leading-36 text-custom-purple">
                              {" "}
                              Add New team
                            </div>
                            <XCircle
                              onClick={closeModal}
                              size={32}
                              weight="fill"
                              className="text-rgba-243-237-255"
                            />
                          </div>
                          <div className="textfield_container_lookup">
                            <div className="textfile_field_lookup">
                              <div className="Roles_row__label">Team Name*</div>
                              <div>
                                <input
                                  type="text"
                                  placeholder="Enter Team Name"
                                  className="textfield_Modal"
                                  value={teamName}
                                  onChange={handleTeamNameChange}
                                />
                                {error && !teamName && (
                                  <p style={{ color: "red" }}>
                                    Team Name is Required
                                  </p>
                                )}
                              </div>
                            </div>

                            {subTeams.map((subTeam, index) => (
                              <div
                                className="textfile_field_lookup"
                                key={index}
                              >
                                <div className="Roles_row__label">
                                  Sub Team*
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Sub Team Name"
                                    className="textfield_Modal"
                                    value={subTeam}
                                    onChange={(e) =>
                                      handleSubTeamChange(index, e.target.value)
                                    }
                                  />
                                  {error && !subTeam && (
                                    <p style={{ color: "red" }}>
                                      SubTeam Name is Required
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div onClick={addSubTeam} className="textfieldSub">
                              <PlusCircle
                                size={20}
                                color="#0e0344"
                                weight="fill"
                              />
                              ADD NEW SUB TEAM
                            </div>
                            <div className="modal_button_field12">
                              {!loading ? (
                                <button
                                  className="common_btn12"
                                  onClick={handleSubmit}
                                >
                                  SAVE
                                </button>
                              ) : (
                                <CircularProgress style={{ color: "purple" }} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
            {/* <h1>Anand</h1> */}
            {/* <div>
      {groupedData.map((group) => {
        const mainItem = Object.keys(group)[0];
        const subItems = group[mainItem];

        return (
          <div key={mainItem}>
            <MenuItem onClick={(e) => handleClick(e, mainItem)}>{mainItem}</MenuItem>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl && selectedItem === mainItem)}
              onClose={handleClose}
            >
              {subItems.map((subItem) => (
                <MenuItem key={subItem.id} onClick={handleClose}>{subItem.route}</MenuItem>
              ))}
            </Menu>
          </div>
        );
      })}
    </div> */}
          </nav>
        </div>
      </main>
    </>
  );
}
export default HampiNavbarNew;
